@mixin breakpoint($val) {
    @media screen and (min-width: $val) {
        @content;
    }
}

@mixin breakpoint-min($val) {
    @media screen and (min-width: $val) {
        @content;
    }
}

@mixin breakpoint-max($val) {
    @media screen and (max-width: $val) {
        @content;
    }
}

@mixin breakpoint-height-max($val) {
    @media screen and (max-height: $val) {
        @content;
    }
}

@mixin icon-size($val) {
    background-size: $val;

    &::after {
        background-size: $val;
    }
}

@mixin clear-mt {
    &:first-of-type {
        margin-top: 0;
    }
}

@mixin clear-mb {
    &:last-child {
        margin-bottom: 0;
    }
}

@mixin clear-mr {
    &:last-child {
        margin-right: 0;
    }
}

@mixin first-mr($val) {
    &:first-child {
        margin-right: $val;
    }
}

@mixin last-child-left {
    &:last-child {
        margin-left: auto;
    }
}

@mixin only-child-center {
    &:only-child {
        margin: 0 auto;
    }
}

@mixin only-child-left {
    &:only-child {
        margin-left: auto;
    }
}

@mixin only-child-clear-mr {
    &:only-child {
        margin-right: 0;
    }
}

@mixin hide-empty {
    &:empty {
        display: none;
    }
}

@mixin hide-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-full-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin capitalize {
    &::first-letter {
        text-transform: uppercase;
    }
}

@mixin radius-bottom ($val) {
    border-bottom-right-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin radius-top ($val) {
    border-top-left-radius: $val;
    border-top-right-radius: $val;
}

@mixin radius-left ($val) {
    border-top-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin radius-right ($val) {
    border-top-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin shadow-default {
    pointer-events: none;
    position: absolute;
    display: block;
    content: "";
}

@mixin mobile-side-gutter($val) {
    padding-left: calc(#{$val} + var(--safe-area-left));
    padding-right: calc(#{$val} + var(--safe-area-right));
}