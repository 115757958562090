betbooster-widget {
  display: block;

  div.tips-mobile-container div.mobile-controls-wrapper {
    margin: 0 0 20px 0;

    a.left-arrow,
    a.right-arrow,
    .left-side-shade,
    .right-side-shade {
      display: none;
    }
  }

  div.mobile-tips-wrapper {
    overflow: initial;
    flex-flow: column;
  }

  div.mobile-tips-wrapper lib-mobile-tip-container,
  div.mobile-tips-wrapper lib-mobile-tip-container.first:not(.not-visible) {
    margin: 0 0 var(--space-4) 0;
  }

  div.mobile-tips-wrapper lib-mobile-tip-container:last-child {
    margin: 0;
  }

  div.mobile-tips-wrapper lib-mobile-tip-container {
    flex: 0 1 auto;
    max-width: 100%;
  }

  div.mobile-tips-wrapper lib-mobile-tip-container .mobile-tip-content {
    padding: var(--space-3) 0;
    margin-bottom: var(--space-4);
    background: var(--betbooster-bg, transparent);
    border-radius: var(--betbooster-radius, var(--radius-1));
    border: var(--betbooster-border, none);

    .mobile-tip-text,
    .mobile-market-name,
    .mobile-bet-details {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.025em;
    }

    .mobile-market-name {
      padding: 0 var(--space-4);
    }

    .mobile-bet-details {
      margin: 0 var(--space-4);
    }

    .mobile-tip-text {
      position: relative;
      display: block;
      padding: 0 var(--space-3) var(--space-4);
      flex: 0 1 auto;
      color: var(--neutral-light-3);
      line-height: 1.7;
      border-bottom: 1px solid var(--body);

      &::before {
        position: relative;
        top: 3px;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13px;
        height: 13px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: var(--basic-color);
        color: var(--black);
        // background-color: var(--white);
        font-size: 11px;
        font-weight: 800;
        content: 'i';
        display: none;
      }
    }

    .mobile-market-name {
      padding-top: 0;
      color: var(--market-name-color, var(--color-2));
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: auto;
    }

    .mobile-bet-details {
      max-height: 47px;
      height: 47px;
      font-weight: 700;
      font-size: 13px;
      background-color: var(--primary-color);
      border-radius: var(--betbooster-radius, var(--radius-1));
      transition: 0.2s ease;

      .mobile-bet-name,
      .mobile-odd-value {
        transition: 0.2s ease;
      }

      .mobile-odd-value {
        color: var(--white);
      }

      &.selected {
        background-color: var(--basic-color);
      }

      &.selected span {
        color: var(--black);
      }
    }
  }
}
