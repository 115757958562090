@import 'sportsbook/mobile-web/shared/layout/breadcrumbs';

.breadcrumbs {
  --breadcrumb-bg: var(--bg-1);
  --breadcrumb-color: var(--color-2);
  --breadcrumb-border: var(--border-1);
  --breadcrumb-gap: 15px;
  --breadcrumb-radius: var(--radius-1);
  --breadcrumb-counter-color: var(--white);
  --breadcrumb-counter-bg: var(--bg-3);
  --breadcrumb-pb-color: #ff9e40;
  --border-4: 2px solid var(--breadcrumb-pb-color);

  &-entry--favourite.is-active,
  &-entry--twitch.is-active {
    --breadcrumb-counter-color: var(--white);
    --breadcrumb-counter-bg: var(--bg-accent);
    --breadcrumb-border: 1px solid var(--bg-accent);
  }

  &-entry__btn--twitch {
    background-color: transparent;
  }
}
