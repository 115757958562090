.profile .email-verification-info {
  display: flex;
  justify-content: center;
  background-color: var(--state-bg);
  padding: var(--px-2);
  border-radius: var(--radius-2);
  margin-bottom: var(--title-mb, var(--my-1));

  &--content {
    display: flex;
    align-items: center;
    margin-right: 10px;

    bcf-icon {
      margin-right: 15px;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--state-bg);
          stroke: var(--text-color);
        }
      }
    }

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.02em;
      text-align: left;
      color: var(--text-color);
      margin-bottom: 0;
    }
  }

  button {
    width: 100px;
    height: 32px;
    padding: 10px, 12px, 10px, 12px;
    border-radius: 12px;
    gap: 8px;
    background-color: var(--button-bg);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--color-1)
  }
}