.sports {
  display: flex;
  flex-flow: column;

  &__list {
    margin-bottom: 13px;
  }
}

.sports-headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--headline-mb, 12px);
  font-size: 12px;
  letter-spacing: -0.06em;

  &:empty {
    margin: 0;
  }

  &__title {
    color: var(--sport-headline-color, var(--color-2));
    font-weight: 700;
  }

  &__subtitle {
    font-weight: 600;
  }

  span::first-letter {
    text-transform: uppercase;
  }
}

.sports-entry {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 47px;
  padding-right: var(--sport-gx);
  padding-left: calc(var(--sport-gx) * 2 + var(--sport-size));
  margin-bottom: 10px;
  background-color: var(--sport-bg, var(--bg-1));
  background-size: var(--sport-size);
  background-position: var(--sport-gx) 50%;
  font-size: var(--sport-entry-fs, 14px);
  font-weight: 700;
  letter-spacing: var(--ls-1);
  border-radius: var(--sport-radius, var(--radius-1));
  border: none;
  outline: none;

  &.is-hidden {
    display: none;
    margin-bottom: 0;
  }

  &__title {
    overflow: hidden;
    display: box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    padding-right: 8px;
    margin-right: auto;
    color: var(--sport-color, #fff);
  }

  &__count {
    color: var(--count-color, var(--color-2));
    white-space: nowrap;
  }

  .icon-cp {
    position: absolute;
    top: 50%;
    left: var(--sport-gx);
    transform: translateY(-50%);
    display: flex;
    width: var(--sport-size);
  }
}

.sports-group-cp {
  display: block;

  &:last-child .sports-entry {
    margin-bottom: 0;
  }
}
