.live-agent {
  .betslip-btn-container {
    bottom: 50px;
  }
}

.betslip-btn-container {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  position: fixed;
  top: 0;
  top: auto;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background-color: var(--fb-bg);
  border-top: var(--fb-border-t, var(--border-1));
  transition: opacity 0.2s ease-in-out;

  &::before {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 25px;
    background: linear-gradient(to top, var(--fb-bg) 34%, transparent 100%);
    content: '';
  }

  .sb-loader {
    margin-left: 8px;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;

    .betslip-btn {
      pointer-events: initial;
    }
  }

  &.is-fast-bet {
    pointer-events: initial;
    animation: show-fast-bet 0.3s ease-in-out 0.1s forwards;

    @include breakpoint-min(1200px) {
      left: calc(50% - var(--container-max-width) / 2);
      max-width: var(--container-max-width);
    }

    &::before {
      opacity: 1;
    }

    .fast-bet-inner {
      opacity: 1;
    }

    .fast-bet-stake {
      animation: show-fast-bet-stake 0.3s ease-in-out 0.3s forwards;
    }

    .betslip-btn {
      box-shadow: 0 0 0 5px var(--fb-bg);
    }
  }

  &.is-placed {
    .betslip-btn {
      opacity: 0;
      pointer-events: none;
    }

    .fast-bet-stake {
      display: none;
    }

    .fast-bet-core {
      width: 50%;
    }
  }
}

.fast-bet {
  overflow: scroll;
  display: flex;
  width: 100%;
  scroll-snap-type: x mandatory;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    height: 48px;
    width: 32px;
    transform: translateY(-50%);
    background-color: var(--fb-bg);
    content: '';
  }
}

.fast-bet-inner {
  overflow: hidden;
  width: 100%;
  min-width: 100vw;
  padding-left: 8px;
  opacity: 0;
  scroll-snap-align: end;
}

.fast-bet-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: 60px;
  margin-left: 8px;
  background-color: var(--delete-bg, var(--bg-1));
  color: var(--delete-color, var(--red-1));
  font-size: 12px;
  font-weight: 800;
  border-radius: var(--radius-1);
  scroll-snap-align: end;
}

.fast-bet-data {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  margin-right: 5px;

  &__entry {
    overflow: hidden;
    color: var(--fb-color-2);
    font-size: 11px;
    font-weight: 600;
    letter-spacing: -0.05em;

    span {
      @include ellipsis;
    }

    &.has-selection {
      display: flex;
      flex-flow: column;
      color: var(--fb-color-1, var(--color-1));
    }

    &.has-event {
      font-size: 10px;
      font-weight: 400;
      letter-spacing: -0.05em;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__value {
    color: var(--fb-color-2);
  }

  &__name {
    font-size: 13px;
  }
}

.fast-bet-summary {
  position: absolute;
  bottom: -8px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: calc(14px + var(--safe-area-right));
  background-color: var(--fb-bg);
  color: var(--fb-color-2);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  &__data {
    @include ellipsis;

    margin-left: 8px;
  }
}

.fast-bet-core {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  max-width: calc(100% - 22px - var(--safe-area-right));
}

.fast-bet-status {
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  max-width: 50%;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -0.05em;
  text-align: right;
  transition: opacit 0.3s ease;
  animation: show-fast-bet-status 0.3s ease-in-out forwards;

  .sb-icon,
  .sb-loader {
    margin-left: 8px;
  }

  .sb-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  &.is-active {
    opacity: 1;
  }
}

.fast-bet-stake {
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  transform: translateX(100%);
  border-radius: 3px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: -0.05em;
  white-space: nowrap;
  font-weight: 600;

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 80px;
    padding: 0 15px 0 10px;
    background-color: var(--input-bg, var(--fb-bg));
    color: var(--input-color, var(--color-1));
    border: var(--input-border, var(--border-1));
    border-right: initial;
    @include radius-left(var(--radius-1));

    backface-visibility: hidden;

    .virtual-keyboard-input {
      overflow: hidden;
      display: box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: -0.05em;
      line-height: 1.1;
      white-space: initial;
      word-break: break-word;
      border-radius: var(--radius-xs);
    }
  }

  &__place {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-7px);
    width: 100%;
    height: 100%;
    padding-right: 46px;
    padding-left: 10px;
    background-color: var(--place-bg, var(--basic-color));
    color: var(--place-color, var(--black));
    font-weight: 700;
    @include radius-left(var(--radius-primary));
  }
}

.fast-bet-overlay {
  display: none;
  opacity: 0;
  pointer-events: none;
  z-index: 122;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  &.is-active {
    pointer-events: initial;
    display: block;
  }
}

.fast-bet-error {
  display: none;
  opacity: 0;
  position: absolute;
  top: -2px;
  transform: translateY(-100%);
  left: 0;
  right: 0;
  padding: 6px 8px;
  background-color: var(--fb-bg);
  color: var(--error-color, var(--red-1));
  font-weight: 700;
  letter-spacing: -0.05em;
  box-shadow: 0 -7px 14px -1px var(--fb-bg);

  &.is-active {
    display: block;
    animation: show-fast-bet-error 0.3s ease-in-out forwards;
  }
}

.betslip-btn {
  z-index: 111;
  position: fixed;
  right: 10px;
  bottom: calc(100px + var(--safe-area-bottom));
  animation: show-betslip-icon 0.3s ease-in-out forwards;
  @include flex-center;

  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-left: auto;
  background-color: var(--betslip-btn-bg, var(--basic-color));
  color: var(--betslip-btn-color, var(--black));
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.05em;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px rgb(0 0 0 / 50%);

  @include breakpoint-min(1200px) {
    right: calc((100% - 1200px) / 2);
  }

  &.is-small {
    width: 50px;
    height: 50px;
    font-size: 11px;
  }

  &.is-extra-small {
    width: 52px;
    height: 52px;
    font-size: 10px;
  }

  &.is-limited {
    font-size: 11px;
  }

  .sb-icon {
    display: inline-block;
    height: 100%;
    width: 24px;
  }

  &__counter {
    z-index: 2;
    position: absolute;
    top: -2px;
    right: -2px;
    @include flex-center;

    width: 16px;
    height: 16px;
    margin-left: 10px;
    background-color: var(--betslip-counter-bg);
    color: var(--betslip-counter-color);
    font-size: 11px;
    line-height: 0;
    border-radius: 50%;
  }

  &__odds {
    z-index: 2;
    @include ellipsis;

    width: calc(100% - 8px);
    margin: 0 auto;
    text-align: center;
  }
}

.is-fast-bet .betslip-btn {
  position: absolute;
  animation: unset;
  top: 50%;
  right: 8px;
  bottom: auto;
  transform: translateY(-50%);
}
