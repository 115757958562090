div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--tabs__container {
  //Header H2H
  .STATSCOREWidget--headerH2H {
    padding: 15px 0;
    background-color: var(--statscore-bg);
    border-radius: var(--statscore-radius) var(--statscore-radius) 0 0;
    border-bottom: 1px solid var(--statscore-line);

    .STATSCOREWidget--headerH2H__competitionName {
      white-space: nowrap;
    }

    .STATSCOREWidget--headerH2H__middleContainer {
      justify-content: center;
      .STATSCOREWidget--headerH2H__vs {
        font-size: 14px;

        &:only-child {
          margin-bottom: 0;
        }
      }

      .STATSCOREWidget--headerH2H__competitionName {
        color: var(--statscore-grey);
      }
    }
  }

  //GENERAL H2H
  .STATSCOREWidget--generalH2H {
    .STATSCOREWidget--generalH2H__statistics {
      background-color: var(--statscore-bg);
      border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);
      margin-bottom: 10px;

      & + div {
        overflow: hidden;
        background-color: var(--statscore-bg);
        border-radius: var(--statscore-radius);
      }
    }
  }

  .STATSCOREWidget--formH2H {
    &__content {
      padding: 15px 15px;
      border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);
      background: var(--statscore-bg);
      font-size: 12px;
      .STATSCOREWidget--formH2H__form {
        .STATSCOREWidget--formH2H__formInner {
          grid-row-gap: 15px;
        }
      }
    }
  }
}
