.user {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--my-3);
  background-color: var(--user-bg, var(--bg-9));
  border-radius: var(--user-radius, var(--radius-2));

  &-header {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: var(--user-header-border, var(--border-1));

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 45px;
      margin-right: 15px;
      border-radius: var(--radius-2);
      background-color: var(--icon-bg, var(--bg-4));
    }

    &__icon .icon-cp {
      width: 18px;
      height: 25px;

      path {
        fill: var(--user-icon-fill, #fff);
      }
    }

    &__name {
      color: var(--user-color, var(--color-1));
      font-size: 18px;
      font-weight: 800;
      letter-spacing: var(--ls-2);
    }

    &__status {
      height: 21px;
      margin-left: 10px;
      padding: 0 12px;
      font-weight: var(--status-fw, 600);
      border-radius: var(--radius);
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
    }

    &__subtitle {
      color: var(--user-color-2, var(--color-2));
    }
  }

  &-data {
    display: grid;
    grid-gap: 15px;
    padding: 15px;

    &__entry {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }

    &__name {
      color: var(--color-name, var(--color-2));
      font-weight: 600;
    }

    &__value {
      color: var(--color-value, var(--color-1));
      font-weight: 700;
    }
  }
}

.profile-nav {
  &__group {
    margin-bottom: var(--group-mb, var(--my-2));
  }

  &__title {
    margin-bottom: var(--title-mb, var(--my-1));
    color: var(--title-color, var(--color-4));
    font-size: 13px;
    font-weight: 600;
  }

  &-list {
    overflow: hidden;
    display: grid;
    grid-gap: var(--entry-mb, 2px);
    border-radius: var(--list-radius, var(--radius-2));

    &__entry {
      display: flex;
      align-items: center;
      height: var(--entry-height, 50px);
      padding: 0 var(--px-2);
      background-color: var(--entry-bg, var(--bg-9));
      color: var(--entry-color, #fff);
      font-size: 14px;
      font-weight: 700;
    }
  }

  .icon-cp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    margin-right: var(--mx-2);

    &.icon-chevron-right {
      margin-left: auto;
      margin-right: 0;
      width: 7px;
      height: 12px;

      path {
        fill: #595964;
      }
    }
  }
}
