div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--prematchBoard {
  height: auto;
  padding: 15px;
  background-color: var(--statscore-bg);
  border-bottom: 1px solid var(--statscore-line);
  border-radius: 0 0 0 0;

  &__participants {
    display: grid;
    grid-gap: 15px;

    .STATSCOREWidget--prematchBoard__participant {
      align-items: center;
    }
  }

  .STATSCOREWidget--prematchBoard__countdownTimer {
    .STATSCOREWidget--prematchBoard__countdownTimerTime {
      background-color: var(--statscore-bg-primary-light);
      box-shadow: initial;
    }

    .STATSCOREWidget--prematchBoard__countdownTimerLabel {
      font-style: normal;
    }
  }
}

//LIVE BOARD
div.bottom-sheet
  bcf-statscore-widget
  .STATSCOREWidgetContainer
  .STATSCOREWidget--scoreboard.STATSCOREWidget--scoreboard--vertical {
  .STATSCOREWidget--scoreboard__container {
    background-color: var(--statscore-bg);
    border-bottom: 1px solid var(--statscore-line);

    &.STATSCOREWidget--scoreboard__package {
      height: auto;
      min-height: auto;
      max-height: initial;
      border-radius: var(--statscore-radius) var(--statscore-radius) 0 0;

      .STATSCOREWidget--scoreboard__participantsContainer {
        justify-content: flex-end;

        .STATSCOREWidget--scoreboard__participantsBox {
          height: auto;

          .STATSCOREWidget--scoreboard__participantBox {
            height: auto;
            padding: 0;

            &:first-child {
              margin-bottom: 10px;
            }

            .STATSCOREWidget--scoreboard__participantEmblemName {
              align-items: center;
              padding: 0;

              div {
                line-height: initial;
              }
            }
          }
        }
      }
    }

    .STATSCOREWidget--scoreboard__vertical {
      padding: 10px 15px;
      background-color: var(--statscore-bg);

      .STATSCOREWidget--scoreboard__resultScrollController--left {
        background: linear-gradient(90deg, #1f1f23 30%, rgba(31, 31, 35, 0.6) 70%);
      }

      .STATSCOREWidget--scoreboard__resultScrollController--right {
        background: linear-gradient(90deg, rgba(31, 31, 35, 0.6) 70%, #1f1f23 30%);
      }
    }

    .STATSCOREWidget--scoreboard__statusBar {
      height: auto;
      margin-bottom: 15px;
    }

    .STATSCOREWidget--scoreboard__timer {
      width: initial;
      height: 15px;
      padding: 0 6px;
      background-color: #d70000;
      color: var(--white);
      font-size: 11px;
      font-weight: 700;
      line-height: 15px;
      border-radius: 3px;
    }

    .STATSCOREWidget--scoreboard__eventStatus {
      // &.STATSCOREWidget--scoreboard__eventStatus--short {
      //     background-color: var(--red-2);
      //     height: 15px;
      //     padding: 0 6px;
      //     color: var(--white);
      //     font-size: 11px;
      //     font-weight: 700;
      //     line-height: 15px;
      //     border-radius: 3px;
      // }
    }

    .STATSCOREWidget--scoreboard__statsColumn {
      border-color: var(--statscore-bg);
    }

    .STATSCOREWidget--scoreboard__statsColumn,
    .STATSCOREWidget--scoreboard__resultsColumn {
      justify-content: flex-end;

      .STATSCOREWidget--scoreboard__resultsValue,
      .STATSCOREWidget--scoreboard__resultsValueContainer {
        height: auto;
      }

      .STATSCOREWidget--scoreboard__resultsValue {
        font-size: 14px;
        line-height: initial;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .STATSCOREWidget--scoreboard__resultsColumn {
      border-left: initial;
      border-right: initial;

      &.STATSCOREWidget--scoreboard__resultsColumn--main {
        color: var(--statscore-active-color);

        div {
          background-color: transparent;
        }
      }
    }

    .STATSCOREWidget--scoreboard__resultsName {
      margin-bottom: 5px;
    }

    .STATSCOREWidget--scoreboard__participantBox
      .STATSCOREWidget--scoreboard__participantEmblemName
      .STATSCOREWidget--scoreboard__participantName {
      font-size: 14px;
      line-height: initial;
    }
  }
}

div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--competitionInfoBar {
  min-height: 35px;
  max-height: 35px;
  padding: 15px;
  background: var(--statscore-bg);
  color: var(--statscore-grey);
  border-bottom: 1px solid var(--statscore-line);
  border-radius: var(--statscore-radius) var(--statscore-radius) 0 0;

  .STATSCOREWidget--partialFlag {
    margin-right: 8px;
  }
}
