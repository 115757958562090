@import 'sportsbook/mobile-web/shared/layout/footer';

.footer__inner {
  --seo-color: #58585b;
  --reserved-color: #58585b;
  --arrow-fill: #9b9b9b;
}

.footer__bottom {
  margin-bottom: 10px;
}

.footer__license,
.footer__bottom-reserved {
  color: var(--f-grey-22);

  a {
    color: var(--f-grey-22);
  }
}

.icon-pbw-playplus,
.icon-visa,
.icon-mastercard,
.icon-paypal,
.icon-venmo {
  height: 18px;
}

.icon-pbw-playplus {
  width: 36px;
}
.icon-visa {
  width: 52px;
}
.icon-mastercard {
  width: 24px;
}
.icon-paypal {
  width: 68px;
}
.icon-venmo {
  width: 94px;
}
