@import 'sportsbook/mobile-web/shared/abstract/animations';

@keyframes animation-select {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(47px);
  }
}

@keyframes animation-select-2 {
  0% {
    opacity: 0;
    transform: translateY(47px);
  }

  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}

@keyframes animation-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animation-loader-2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

@keyframes animation-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes show-fast-bet-status {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-fast-bet-stake {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes show-fast-bet-content {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes show-fast-bet {
  0% {
    transform: translateY(100%);
  }

  99% {
    transform: translateY(-73px);
    z-index: 10;
  }

  100% {
    transform: translateY(-74px);
    z-index: 11;
  }
}

@keyframes show-betslip-icon {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-fast-bet-error {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse-fast-bet-btn {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes show-payments-loader {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
