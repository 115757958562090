.search-mode {
  overflow: hidden;
  z-index: 150;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-150%);
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 15px 15px 0;
  background-color: var(--search-bg, var(--body));

  &.is-active {
    animation: show-search-mode 0.28s ease-in-out forwards;
  }

  @keyframes show-search-mode {
    0% {
      transform: translateX(-150%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  &::after {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(
      to top,
      var(--search-bg, var(--body)) 0%,
      var(--search-bg, var(--body)) 45%,
      rgb(0 0 0 / 0%)
    );
    content: '';
  }

  .market-select-cp {
    margin-top: 16px;
  }

  .no-matches {
    margin: -30px 0 10px;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
    text-align: center;
    border: var(--error-border, 2px solid #ad0000);
    border-radius: var(--error-radius, var(--radius-1));
  }

  &-container {
    overflow: hidden;
    display: flex;
    flex-flow: column;

    .tab-bar {
      padding-top: 6px;
      overflow-y: initial;
      z-index: 10;
      margin-bottom: 0;
      box-shadow: 0 5px 3px 3px var(--search-bg, var(--body));
    }
  }

  &__games {
    overflow: scroll;
    padding-top: 15px;
  }

  &__content {
    overflow: scroll;
    padding-top: 15px;
  }

  &__bar {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 5px 5px 5px var(--search-bg, var(--body));
  }

  &__bar .btn {
    height: 39px;
    width: 39px;
    flex-shrink: 0;
    margin-left: 14px;
    background-size: 16px;
    border: var(--close-border, var(--border-1));
    border-radius: var(--close-radius, var(--radius-1));
  }

  &__bar .icon-cp {
    display: flex;
    height: 15px;
    fill: var(--close-fill, var(--white));
  }

  &__input {
    width: 100%;
    height: 39px;
    padding: 0 12px;
    color: var(--input-color, var(--white));
    caret-color: var(--input-carret-color, var(--f-grey));
    background: var(--input-bg, #2f2f35);
    font-size: 14px;
    font-weight: var(--input-fw, 800);
    letter-spacing: -0.05em;
    border-radius: var(--input-radius, var(--radius-1));
    border: none;

    &::placeholder {
      color: var(--input-color, var(--white));
    }
  }

  .sports__group {
    overflow: scroll;
    display: flex;
    flex-flow: column;
  }
}
