.profile .pass-valid {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--my-4);

  &__list {
    display: grid;
    grid-gap: var(--mb-pass-list, var(--my-1));
  }

  &__entry {
    display: flex;
    align-items: center;
    color: var(--pass-entry, var(--color-3));
  }

  &__checkbox {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--pass-entry, #39383b);
  }
}
