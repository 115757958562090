.tab-bar {
  overflow-y: scroll;
  position: relative;
  display: flex;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: var(--ls-1);
  text-transform: uppercase;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--tab-line-bg, var(--bg-1));
    content: '';
  }

  &__item {
    position: relative;
    padding: 0 var(--space-4) var(--space-3) 0;
    color: var(--tab-color, var(--neutral-light-3));
    border-bottom: var(--tab-line, var(--border-1));
    outline: none;
    white-space: nowrap;

    &::after {
      opacity: 0;
      z-index: 1;
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      width: calc(100% - var(--space-4));
      height: 3px;
      background-color: var(--tab-active, var(--basic-color));
      border-radius: 1px 1px 0 0;
      content: '';
    }

    &.is-active {
      color: var(--tab-color-active, var(--white));
    }

    &.is-active::after {
      opacity: 1;
    }
  }
}

.navbar-slider {
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  &__entry {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    margin-right: 7px;
    background-color: var(--promo-btn-bg, var(--bg-1));
    color: var(--promo-btn-color, var(--color-1));
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.05em;
    border-radius: var(--promo-btn-radius, 20px);
    white-space: nowrap;

    &--recommended {
      background-color: var(--promo-btn-bg-main, var(--primary-green));
      color: var(--promo-btn-color-main, #000);
    }
  }

  .icon-cp {
    display: flex;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .icon-cp .cls-1-recommended-star {
    fill: var(--promo-icon-fill, #00472d);
  }
}

.header-hamburger {
  z-index: 12;
  position: relative;
  height: 38px;
  width: 40px;

  &::after {
    top: 50%;
    display: block;
    position: absolute;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: var(--white);
    transition: 0.25s ease;
    content: '';
  }

  &__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: var(--white);
    transition: 0.25s ease;

    &--top {
      transform: translateY(-5px);
    }

    &--bottom {
      left: 7px;
      transform: translateY(5px);
      width: 18px;
    }
  }

  &.is-active {
    &::after {
      transform: rotate(-45deg);
    }

    .header-hamburger__line--top,
    .header-hamburger__line--bottom {
      transform: translateY(0);
      opacity: 0;
    }

    .header-hamburger__line--middle {
      transform: rotate(45deg);
    }
  }
}

.navbar {
  display: flex;
  background-color: var(--body);
  padding: 0 10px;
}

.navbar-entry {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 2px;
  color: var(--f-grey);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.06em;
  outline: none;

  .icon-cp {
    @include absolute-center;

    width: 18px;
    height: 18px;
    fill: var(--white);
  }

  .icon-heart {
    width: 16px;
    height: 16px;
  }

  &.has-counter {
    overflow: initial;
  }

  &__name {
    display: block;
    margin-top: 4px;
    padding-bottom: 2px;
    line-height: 1;
    text-align: center;
    outline: none;
    @include capitalize;
  }

  .btn {
    backface-visibility: hidden;
    background-position: center;
    background-size: 18px;
    background-repeat: no-repeat;

    &--bg-primary {
      --bg-active: var(--primary-color);
    }

    &--border-primary {
      &.is-active {
        border-color: var(--border-color-active, var(--white));
      }
    }
  }

  .default-counter {
    position: absolute;
    top: -5px;
    right: -5px;
    outline: none;
  }
}

.navbar-list {
  display: flex;

  &::after {
    display: block;
    height: 100%;
    width: 10px;
    flex-shrink: 0;
    content: '';
  }

  &--primary {
    overflow-y: scroll;
    padding-right: 12px;
  }

  &--secondary {
    position: relative;
    margin-left: auto;

    .navbar-entry:last-of-type {
      margin: 0;
    }

    &::after {
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(to left, var(--body) 0%, var(--body) 40%, rgb(0 0 0 / 0%));
    }
  }

  .navbar-entry {
    overflow: hidden;
    width: 47px;
    padding: 0;

    &__name {
      overflow: hidden;
      display: block;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.has-counter {
      overflow: initial;
      margin: 0 0 0 13px;
    }

    &.is-active .btn {
      border-color: var(--white);
    }
  }

  &-promo {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 5px 9px;
    margin-bottom: 3px;
    color: var(--f-grey);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &.is-active {
      color: var(--basic-color);
      border-color: var(--basic-color);
    }

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--line-grey);
      content: '';
    }

    a {
      color: inherit;
    }
  }
}

.match-vizu-filter-cp {
  display: flex;

  .navbar-entry:last-child {
    margin-right: 0;
  }

  .btn {
    transition: 0.2s ease;

    &.is-active {
      border-color: var(--white);
    }
  }
}

.slider.slider-main {
  margin-top: 0;
  margin-bottom: 15px;

  .slide-main {
    &__subtitle {
      text-decoration: none;
    }
  }
}

footer {
  .seo {
    padding: 0 6px;
    margin: 20px 0;

    @include breakpoint-min(1200px) {
      max-width: 1200px;
      margin: 25px auto;
    }

    h2 {
      padding: 10px 0;
      color: var(--f-grey-2);
      font-size: 14px;
    }

    p {
      color: var(--f-grey-2);
    }

    p a {
      color: var(--f-grey-12);
      font-weight: 700;
    }

    .row {
      @include breakpoint-min(1200px) {
        margin: 0;
      }
    }

    .col-d-6 {
      padding: 0 14px;
      padding-left: calc(14px + var(--safe-area-left));
      padding-right: calc(14px + var(--safe-area-right));
    }

    ul {
      margin-top: 12px;

      li {
        margin-bottom: 12px;
        color: var(--f-grey-2);
      }
    }
  }
}

//display header scenic button only on landscape mode
@media screen and (min-width: 320px) and (orientation: landscape) {
  .header .btn-header-scenic {
    display: flex;
  }
}

body[class*='iPhone'] {
  .header {
    padding-top: calc(var(--top-space-height) + env(safe-area-inset-top));
  }

  .main-nav {
    padding: 0;
    height: calc(66px + env(safe-area-inset-bottom));
  }
}
