div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer {
  .STATSCOREWidget--lastMatchesH2H,
  .STATSCOREWidget--generalH2H {
    .STATSCOREWidget--partialHeader {
      @include partial-header;
    }

    .STATSCOREWidget--partialEventHorizontalH2H {
      background-color: var(--statscore-bg);
    }

    .STATSCOREWidget--partialEventHorizontalH2H__event {
      padding: 10px;
      border-bottom: 1px solid var(--statscore-line);

      .STATSCOREWidget--partialEventHorizontalH2H__eventHeader {
        margin: 0 0 10px 0;
        color: #b9b9bd;
        font-size: 9px;
      }
    }

    .STATSCOREWidget--partialEventHorizontalH2H__eventContainer {
      margin: 0;
    }

    .STATSCOREWidget--partialEventHorizontalH2H__eventParticipantContainer div {
      line-height: initial;
    }

    .STATSCOREWidget--partialEventHorizontalH2H__eventResult {
      width: 55px;
      min-width: 55px;
      height: 22px;
      background-color: var(--statscore-bg-primary);
      color: var(--white);
      line-height: 22px;
    }

    .STATSCOREWidget--partialEventHorizontalH2H__eventContainer--lost
      .STATSCOREWidget--partialEventHorizontalH2H__eventResult {
      color: #fff;
      background-color: #911a13;
    }

    .STATSCOREWidget--partialEventHorizontalH2H__eventContainer--win
      .STATSCOREWidget--partialEventHorizontalH2H__eventResult {
      background-color: #116b4c;
    }
  }

  .STATSCOREWidget--lastMatchesH2H {
    border-radius: var(--statscore-radius);
  }

  .STATSCOREWidget--generalH2H {
    .STATSCOREWidget--partialHorizontalMultiBar__chartTitle {
      color: var(--statscore-grey);
      font-size: 10px;
      font-weight: 600;
    }

    .STATSCOREWidget--partialHorizontalMultiBar__chartElement {
      &:nth-child(2) {
        background-color: var(--statscore-grey-2);
        &::before {
          background-color: var(--statscore-grey-2);
        }
      }

      &:nth-child(3) {
        background-color: var(--white);
        &::before {
          background-color: var(--white);
        }
      }
    }

    .STATSCOREWidget--partialHorizontalMultiBar__labelElement {
      font-weight: 700;

      &:nth-child(2) {
        color: var(--statscore-grey-2);
      }

      &:nth-child(3) {
        color: var(--white);
      }
    }
  }
}
