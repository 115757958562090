.icon-cp {
  display: inline-block;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    transform: translate(0, 0);

    /* Safari and Chrome */
  }

  //TV
  .cls-1-tv {
    fill: #b9b9bd;
  }
}

.icon-user-vip {
  width: 16px;
  height: 7px;
}

.vip-user-frame {
  .icon-cp {
    display: flex;
  }
}

.icon-state-toggle.is-active {
  //SPORTS
  .cls-1-sports {
    fill: #fff;
  }

  .cls-2-sports {
    fill: #50c47b;
  }

  //LIVE
  .cls-1-live {
    fill: #f10;
  }

  .cls-2-live {
    fill: #fff;
  }

  //ESPORT
  .cls-1-esport {
    fill: #fff;
  }

  .cls-2-esport {
    fill: #01ff85;
  }

  //GAME CARDS
  .cls-1-gamecards {
    fill: #fff;
  }

  .cls-2-gamecards {
    fill: #f10;
  }

  //VIRTUAL SPORTS
  .cls-1-virtualsports {
    fill: #fff;
  }

  .cls-2-virtualsports {
    fill: #96ebff;
  }

  //MY BETS
  .cls-1-mybets {
    fill: #f4c601;
  }

  //AZ
  .cls-1-az {
    fill: #313137;
  }

  //TV
  .cls-1-tv {
    fill: #fff;
  }

  //TV
  .cls-2-tv {
    fill: #456e75;
  }

  //TV
  .cls-1-twitch {
    fill: #6441a5;
  }

  //Twitch
  .cls-2-twitch {
    fill: #fff;
  }

  //Vizu
  .cls-1-vizu {
    fill: #00b372;
  }

  //User
  .cls-1-user {
    fill: #fff;
  }

  .cls-2-user {
    fill: #fbcb00;
  }

  //Settings
  .cls-1-settings {
    fill: #fff;
  }

  .cls-2-settings {
    fill: #456e75;
  }

  //Bonus
  .cls-1-bonus {
    fill: #fed796;
  }

  .cls-2-bonus {
    fill: #f4c601;
  }

  //My documents
  .cls-1-mydocuments {
    fill: #456e75;
  }

  .cls-2-mydocuments {
    fill: #fff;
  }

  //Mobile app
  .cls-1-mobileapp {
    fill: #ccc;
  }

  .cls-2-mobileapp {
    fill: #f5bc8f;
  }

  //Cashout
  .cls-1-cashout {
    fill: #50c47b;
  }

  .cls-2-cashout {
    fill: #96ebff;
  }

  //Vip
  .cls-1-vip {
    fill: #f4c601;
  }

  .cls-2-vip {
    fill: #b9b9bd;
  }

  //chat
  .cls-1-chat {
    fill: #456e75;
  }

  .cls-2-chat {
    fill: #96ebff;
  }

  //vizu
  .cls-1-vizu {
    fill: #00b372;
  }

  //vizu
  .cls-1-vizu {
    fill: #00b372;
  }

  //vizu
  .cls-1-favorites {
    fill: #a90101;
    stroke: #a90101;
  }

  //info
  .cls-1-info {
    fill: var(--basic-color);
  }

  //pin
  .cls-1-pin {
    fill: var(--basic-color);
  }

  .cls-2-pin {
    fill: var(--basic-color);
  }
}


.side-menu,
.right-menu {
  .icon-state-toggle.is-active {
    //SPORTS
    .cls-1-sports {
      fill: #3A5BA3;
    }

    .cls-2-sports {
      fill: #6896FA;
    }

    //LIVE
    .cls-1-live {
      fill: #3A5BA3;
    }

    .cls-2-live {
      fill: #6896FA;
    }

    //ESPORT
    .cls-1-esport {
      fill: #3A5BA3;
    }

    .cls-2-esport {
      fill: #6896FA;
    }

    //GAME CARDS
    .cls-1-gamecards {
      fill: #3A5BA3;
    }

    .cls-2-gamecards {
      fill: #6896FA;
    }

    //VIRTUAL SPORTS
    .cls-1-virtualsports {
      fill: #3A5BA3;
    }

    .cls-2-virtualsports {
      fill: #6896FA;
    }

    //MY BETS
    .cls-1-mybets {
      fill: #3A5BA3;
    }

    //User
    .cls-1-user {
      fill: #3A5BA3;
    }

    .cls-2-user {
      fill: #6896FA;
    }

    .icon-promo-codes,
    .icon-esport,
    .icon-virtual-sports {
      path {
        fill: #3A5BA3;
      }
      path:nth-child(1) {
        fill: #6896FA;
      }
      path:nth-child(2) {
        fill: #6896FA;
      }
    }

    .cls-1-casino {
      fill: #3A5BA3;
    }

    .cls-2-casino {
      fill: #6896FA;
    }

    .cls-1-inbox {
      fill: #3A5BA3;
    }

    .cls-2-inbox {
      fill: #6896FA;
    }

    //Settings
    .cls-1-settings {
      fill: #3A5BA3;
    }

    .cls-1-favoritescircle {
      fill: #3A5BA3;
    }


    .cls-2-settings {
      fill: #6896FA;
    }

    .cls-1-jackpots {
      fill: #3A5BA3;
    }

    .cls-2-jackpots {
      fill: #6896FA;
    }

    //Bonus
    .cls-1-bonus {
      fill: #3A5BA3;
    }

    .cls-2-bonus {
      fill: #6896FA;
    }

    //My documents
    .cls-1-mydocuments {
      fill: #3A5BA3;
    }

    .cls-2-mydocuments {
      fill: #6896FA;
    }

    //Mobile app
    .cls-1-mobileapp {
      fill: #3A5BA3;
    }

    .cls-2-mobileapp {
      fill: #6896FA;
    }

    //Cashout
    .cls-1-cashout {
      fill: #3A5BA3;
    }

    .cls-2-cashout {
      fill: #6896FA;
    }

    //Vip
    .cls-1-vip {
      fill: #3A5BA3;
    }

    .cls-2-vip {
      fill: #6896FA;
    }

    //chat
    .cls-1-chat {
      fill: #456e75;
    }

    .cls-2-chat {
      fill: #96ebff;
    }

    //pin
    .cls-1-pin {
      fill: var(--basic-color);
    }

    .cls-2-pin {
      fill: var(--basic-color);
    }

    //promotions
    .icon-promotions path {
      fill: #6896FA;
    }

    .icon-bet-builder path {
      fill: var(--basic-color);
    }

    .cls-1-boa {
      fill: var(--basic-color);
    }

    .cls-2-boa {
      fill: #fff3be;
    }
  }
}

.icon-heart {
  max-height: 18px;
}

.icon-heart path {
  stroke: #b7b7c2;
}

.promotion,
.promotions {
  .icon-cp {
    width: 27px;
  }
}

.icon-state-toggle.is-active .icon-heart,
.icon-heart.is-active {
  path {
    stroke: #cd1818;
    fill: #cd1818;
  }
}

//NEW APPROACH

.icon-az {
  --icon-fill-1: var(--white);
  --icon-fill-2: var(--body);
}

.icon-vizu {
  --icon-fill-1: #cbcbd2;
  --icon-fill-2: #79798b;
}

.icon-tv,
.icon-twitch {
  --icon-fill-1: #79798b;
  --icon-fill-2: #cbcbd2;
}

.icon-state-toggle.is-active {
  .icon-vizu {
    --icon-fill-1: var(--white);
    --icon-fill-2: var(--white);
  }

  .icon-tv {
    --icon-fill-1: var(--white);
    --icon-fill-2: var(--white);
  }

  .icon-twitch {
    --icon-fill-1: var(--white);
    --icon-fill-2: var(--white);
  }

  .icon-info,
  .icon-stats {
    --icon-fill-1: var(--white);
  }

  .icon-star {
    --icon-fill-1: var(--basic-color);
    --icon-stroke-1: var(--basic-color);
  }
}

.icon-state-toggle.is-active .icon-bookmark {
  --icon-fill-1: var(--white);
  --icon-stroke-1: var(--white);
}

.icon-bookmark.is-active {
  --icon-fill-1: var(--white);
  --icon-stroke-1: var(--white);
}

.icon-star.is-active {
  --icon-fill-1: var(--basic-color);
  --icon-stroke-1: var(--basic-color);
}
