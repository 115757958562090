.odds-table {
  --odds-gap: 5px;
  --odds-data-width: 190px;

  .has-american-odds {
    --teams-gap: var(--space-3);
  }

  .no-odds {
    --teams-gap: 0;
  }
}

.single-game-odds {
  &__row {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    &--home {
      margin-bottom: var(--odds-gap, 8px);
    }
  }
}

.has-reversed-odds [class*='--away'] {
  order: -1;
}
