@import 'sportsbook/mobile-web/shared/layout/side-menu';

.side-menu {
  --link-item-color: #58585b;

  &__pwa .btn {
    --bg: var(--bg-accent);
    --color: var(--white);
    --border-radius: var(--radius-1);
  }

  &__message {
    --message-bg: #313137;
  }
}

.right-menu {
  .btn {
    --border: var(--border-1);
  }

  .btn-green {
    --bg: var(--bg-accent);
  }

  &__cash {
    --bg-green: var(--bg-accent);
    --cash-label-color: #8d8d92;
  }

  &__link {
    &--secondary {
      --link-color-2: #58585b;
    }
  }
}

.side-menu {
  &__content {
    margin-top: 25px;
  }

  &__pwa .icon-cp {
    display: flex;
    align-items: flex-end;
  }

  &__pwa .btn {
    font-weight: 700;
  }

  &__link-item {
    color: var(--color-2);
  }

  .dropdown.is-active .dropdown__list {
    animation: animation-select-2 0.2s linear 0s forwards;
  }

  &__message-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  &__message-tag{
    position: absolute;
    bottom: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    height: 20px;
    color: var(--text-basic-inverted, #020203);
    background: var(--background-state-brand, #FBCD00);
    border-radius: var(--Radius-sm, 8px);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.0125em;
  }

  &::after {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    width: 240px;
    background: linear-gradient(to bottom, #000 0%, #000 65%, rgb(0 0 0 / 0%) 100%);
    pointer-events: none;
    content: '';
  }
}
