button.odds,
a.odds {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  color: var(--odds-color, #fff);
  background-color: var(--odds-bg, var(--bg-1));
  font-size: var(--odds-fs, 13px);
  font-weight: 700;
  border-radius: var(--odds-radius, 8px);

  @include breakpoint-min(768px) {
    width: 100%;
  }

  &::after,
  &::before {
    opacity: 0;
    pointer-events: none;
    @include absolute-full-size;
    border: var(--border-1);
    border-radius: var(--odds-radius, 8px);
    box-sizing: border-box;
    content: '';
  }

  &::after {
    border-color: var(--odds-increase);
  }

  &::before {
    border-color: var(--odds-decrease);
  }

  .odds__name {
    color: var(--odds-name-color, var(--neutral-light-2));
    line-height: 1.1;
    letter-spacing: -0.05em;
    text-align: initial;
  }

  .odds__value {
    color: var(--odds-value-color, var(--white));
    font-size: 14px;
  }

  .icon-lock {
    width: 12px;
    height: 15px;
  }

  &.no-odds {
    width: 100%;
  }

  &.no-draw {
    display: none;
    background-color: var(--empty-bg, var(--body));
    text-transform: uppercase;
    border: var(--empty-border, var(--border-1));
  }

  &.has-line {
    pointer-events: none;
    background-color: var(--odds-line-bg);
  }

  &.is-increased::after,
  &.is-decreased::before {
    animation: rate-button-flash 4s linear forwards;
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.single-game-odds .odds {
  overflow: hidden;
  display: flex;
  flex-flow: column;

  &__indicator {
    width: 100%;
    min-width: 43px;
    color: var(--odds-value-color, var(--white));
    font-size: 11px;
    font-weight: 600;
    letter-spacing: var(--ls-2);
    white-space: nowrap;
  }
}
