.game-history {
  margin-bottom: var(--game-history-m, var(--my-4));

  .game-entry {
    padding: var(--py-2);
    margin-bottom: var(--my-2);
    border: var(--game-entry-border, var(--border-2));
    border-radius: var(--radius);

    &__content {
      margin-bottom: var(--my-3);
    }

    &__details {
      display: flex;
    }

    &__img {
      flex-shrink: 0;
      width: 55px;
      height: 55px;
      margin-right: var(--mx-2);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: var(--default-bonus-path);
      border-radius: var(--radius-2);
    }

    &__info {
      margin-bottom: 5px;
      color: var(--info-color, var(--color-4));
      font-size: 11px;
      font-weight: 600;
      letter-spacing: var(--ls-1);
    }

    &__title {
      margin-bottom: 2px;
      color: var(--title-color, var(--white));
      font-size: 14px;
      font-weight: 700;
      letter-spacing: var(--ls-2);
    }

    &__date {
      color: var(--date-color, var(--color-4));
      font-size: 11px;
      font-weight: 600;
      letter-spacing: var(--ls-1);
    }
  }

  .game-entry-results {
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--item-mb, var(--my-1));
      color: var(--item-total-color, var(--color-1));
      font-size: 13px;
      font-weight: 700;
      letter-spacing: var(--ls-1);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__price {
      color: var(--item-price-color, var(--active));
    }
  }
}
