.esport .single-game-bar {
  margin-bottom: 8px;
}

.sb-iframe {
  position: relative;
  margin-bottom: 27px;

  .btn-scenic {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 38px;
    min-width: 138px;
    color: var(--white);
    background-color: var(--basic-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05em;
    border-radius: var(--radius-primary);
    text-transform: initial;
  }

  &__content {
    width: 100%;
    height: 230px;
  }
}

iframe {
  width: 100%;
  height: calc(100% - 43px);
  border-radius: var(--radius-primary);
}

.esport-games {
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  display: flex;
  margin-top: 6px;

  &-entry {
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    width: 100%;
    flex-grow: 1;
    margin-right: 10px;
    scroll-snap-align: center;
  }

  .sb-iframe__content {
    border: 2px solid transparent;
    transition: 0.2s ease;

    &:empty {
      border: 2px solid var(--line-grey);
      border-radius: var(--radius-primary);
    }
  }

  iframe {
    animation: default-opacity-show 0.2s linear 0.1s forwards;
  }

  .odds {
    margin-right: 10px;

    &.no-odds {
      margin-right: 0;
    }
  }

  .odds-table__entry {
    margin-bottom: 2px;
  }

  .rate-btn-cp:last-child .odds {
    margin-right: 0;
  }
}

//etournamets
.game-markets-eseries {
  display: block;
  margin-top: 8px;

  .game-markets__core {
    position: relative;
    display: block;
  }

  .single-game-bar,
  .game-markets__title {
    max-width: calc(100% - 66px);
  }

  .single-game-bar,
  .single-game-bar__col {
    overflow: hidden;
  }

  .game-info-data.sb-icon {
    overflow: hidden;
    padding-left: 10px;
    text-align: right;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .odds-count {
    position: absolute;
    top: -7px;
    right: 0;
    transform: translateY(-50%);
    width: 56px;
    justify-content: center;
  }
}

//NEW
.e-streams {
  display: block;
  margin-bottom: var(--space-4);

  .game-broadcast {
    width: 100%;
    height: 0;
    background-color: transparent;
    padding-bottom: 56.25%;
    transition: 0.2s ease;
  }

  &-slider {
    display: block;

    &__content {
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      display: flex;
      margin-top: 6px;
    }
  }

  &-entry {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    width: 100%;
    margin-right: 10px;
    scroll-snap-align: center;

    &::after {
      pointer-events: none;
      opacity: 0;
      display: block;
      position: absolute;
      top: 90px;
      right: 6px;
      width: 50px;
      height: 50px;
      background: var(--basic-color)
        url('https://lvbet-static.com/images/bcf/mobile/img/new-version/icons/white/swipe-white.svg') no-repeat
        center/27px;
      border-radius: 50%;
      transition: opacity 0.28s ease-in-out;
      content: '';
    }

    &.has-icon::after {
      opacity: 1;
    }

    &:only-child::after {
      opacity: 1;
    }

    &__game {
      padding: 10px;
      background: var(--neutral-90);
      margin-top: 2px;
      border-radius: 0 0 var(--radius-xs) var(--radius-xs);
    }
  }

  &-iframe {
    position: relative;

    iframe {
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100% !important;
      //min-height: calc(100% + 5px);
      border-radius: 8px 8px 0 0;
      animation: default-opacity-show 0.2s linear 0.1s forwards;
    }

    .btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      height: 38px;
      min-width: 138px;
      background-color: var(--body-color);
      color: var(--white);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.05em;
      border: 2px solid var(--line-grey);
      border-radius: var(--radius-primary);
      text-transform: initial;
    }

    &__content {
      width: 100%;
      height: 0;
      transition: 0.2s ease;
      padding-bottom: 56.25%;

      &:empty {
        border: 2px solid var(--line-grey);
        border-radius: var(--radius-primary);
      }
    }
  }
}
