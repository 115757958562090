@import 'sportsbook/mobile-web/shared/components/keyboard';

.betslip-keyboard {
  --keyboard-bg: #000;
  --keyboard-grey-color: #636363;

  &__close {
    --btn-close-border: var(--border-2);
    --btn-close-fill: #2b6af7;
  }

  &__input {
    --input-bg: #fff;
    --input-color: #000;
  }
}

.betslip-keyboard .btn {
  --btn-bg: #7e7e7e;
  --btn-color: #fff;
  --btn-border: 1px solid #313137;
  --icon-ctrl-fill: #7e7e7e;
  --keyboard-btn-bg: #2b6af7;
  --keyboard-btn-color: var(--white);
}

.betslip-keyboard-error {
  --error-color: #ad0000;
}
