:root {
  //COLOR BASIC
  --ds-white: #fff;
  --ds-black: #000;

  //COLOR NEUTRAL
  --ds-neutral-10: #e6e6ea;
  --ds-neutral-20: #cbcbd2;
  --ds-neutral-30: #b0b0ba;
  --ds-neutral-40: #9595a2;
  --ds-neutral-50: #79798b;
  --ds-neutral-60: #616170;
  --ds-neutral-70: #4a4a55;
  --ds-neutral-80: #323239;
  --ds-neutral-85: #232329;
  --ds-neutral-90: #1b1b1f;
  --ds-neutral-95: #16161a;
  --ds-neutral-100: #0e0e10;

  //COLOR TRANSPARENT
  --ds-transparent-5: hsl(0deg, 0%, 100%, 0.05);
  --ds-transparent-10: hsl(0deg, 0%, 100%, 0.9);
  --ds-transparent-20: hsl(0deg, 0%, 100%, 0.8);
  --ds-transparent-30: hsl(0deg, 0%, 100%, 0.7);
  --ds-transparent-40: hsl(0deg, 0%, 100%, 0.6);
  --ds-transparent-50: hsl(0deg, 0%, 100%, 0.5);
  --ds-transparent-60: hsl(0deg, 0%, 100%, 0.4);
  --ds-transparent-70: hsl(0deg, 0%, 100%, 0.3);
  --ds-transparent-80: hsl(0deg, 0%, 100%, 0.2);
  --ds-transparent-90: hsl(0deg, 0%, 100%, 0.1);
  --ds-transparent-95: hsl(0deg, 0%, 100%, 0.05);
  --ds-transparent-100: hsl(0deg, 0%, 100%, 0);

  //COLOR BRAND
  --ds-brand-10: #fffefa;
  --ds-brand-20: #fff4c7;
  --ds-brand-30: #ffeb94;
  --ds-brand-40: #ffe161;
  --ds-brand-50: #3a76f8;
  --ds-brand-60: #2b6af7;
  --ds-brand-70: #c7a100;
  --ds-brand-80: #947800;
  --ds-brand-90: #614e00;
  --ds-brand-100: #2b6af726;

  //COLOR SUCCESS
  --ds-success-10: #e5fff6;
  --ds-success-20: #b3ffe3;
  --ds-success-30: #80ffd1;
  --ds-success-40: #4dffbe;
  --ds-success-50: #1affac;
  --ds-success-60: #00e592;
  --ds-success-70: #00b372;
  --ds-success-80: #008051;
  --ds-success-90: #004d31;
  --ds-success-100: hsl(158.25deg, 100%, 44.9%, 0.15);

  //COLOR INFO
  --ds-info-10: #ccebff;
  --ds-info-20: #99d7ff;
  --ds-info-30: #66c4ff;
  --ds-info-40: #33b0ff;
  --ds-info-50: #009cff;
  --ds-info-60: #007dcc;
  --ds-info-70: #005e99;
  --ds-info-80: #003e66;
  --ds-info-90: #001f33;
  --ds-info-100: hsla(203, 100%, 40%, 0.15);

  //COLOR WARNING
  --ds-warning-10: #ffeacc;
  --ds-warning-20: #ffd699;
  --ds-warning-30: #ffc166;
  --ds-warning-40: #ffad33;
  --ds-warning-50: #ff9800;
  --ds-warning-60: #cc7a00;
  --ds-warning-70: #995b00;
  --ds-warning-80: #663d00;
  --ds-warning-90: #331f00;
  --ds-warning-100: hsla(36, 100%, 40%, 0.15);

  //COLOR ERROR
  --ds-error-10: #fff0f0;
  --ds-error-20: #ffbdbd;
  --ds-error-30: #ff8a8a;
  --ds-error-40: #ff5757;
  --ds-error-50: #ff2424;
  --ds-error-60: #f00000;
  --ds-error-70: #bd0000;
  --ds-error-80: #8a0000;
  --ds-error-90: #570000;
  --ds-error-100: hsla(0, 100%, 47%, 0.15);

  //COLOR ESPORT
  --ds-esport-10: #ccffe7;
  --ds-esport-20: #99ffce;
  --ds-esport-30: #66ffb6;
  --ds-esport-40: #33ff9d;
  --ds-esport-50: #01ff85;
  --ds-esport-60: #00cc6a;
  --ds-esport-70: #009950;
  --ds-esport-80: #006635;
  --ds-esport-90: #00331b;
  --ds-esport-100: hsla(151, 100%, 40%, 0.15);

  //RADIUSES
  --ds-radius-xs: 8px;
  --ds-radius-s: 5px;
  --ds-radius-m: 16px;
  --ds-radius-l: 24px;
  --ds-radius-xl: 32px;

  //SPACING
  --ds-space-1: 4px;
  --ds-space-2: 8px;
  --ds-space-3: 12px;
  --ds-space-4: 16px;
  --ds-space-5: 20px;
  --ds-space-6: 24px;
  --ds-space-7: 32px;
  --ds-space-8: 40px;
  --ds-space-9: 48px;
  --ds-space-10: 56px;
  --ds-space-11: 64px;
  --ds-space-12: 80px;
  --ds-space-13: 96px;
  --ds-space-14: 128px;
  --ds-space-15: 160px;
  --ds-space-16: 192px;
  --ds-space-17: 224px;
  --ds-space-18: 256px;
  --ds-space-19: 512px;

  //IOS
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);
}
