html {
  background-color: var(--body);
}

body {
  min-height: 100vh;
}

.icon-eye,
.icon-eye-slashed {
  path {
    fill: var(--neutral-60);
  }
}


body.disable-scroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

* {
  outline: none;
  box-sizing: border-box;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: var(--white);
}

.is-hidden {
  display: none !important;
}

.is-highlighted {
  color: var(--basic-color);
}

.app-container {
  overflow: hidden;
  display: block;
  max-width: var(--container-max-width);
  margin: 0 auto;
  @include mobile-side-gutter(10px);
}

bcf-my-bets-container-page,
bcf-my-bets-system-page {
  .app-container {
    padding: 0;
  }

  .header::after {
    display: none;
  }
}

.home-page .app-container {
  min-height: calc(100vh - (125px + var(--top-space-height) + var(--safe-area-top)) - (66px + var(--safe-area-bottom)));
}

.breadcrumbs-active .app-container {
  min-height: calc(100vh - (165px + var(--top-space-height) + var(--safe-area-top)) - (66px + var(--safe-area-bottom)));
}

.sb-open-game {
  display: block;
  min-height: calc(100vh - (95px + var(--top-space-height) + var(--safe-area-top)) - (66px + var(--safe-area-bottom)));
  margin-top: -12px;
}

.no-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 25px;

  &__description {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: var(--empty-color, var(--white));
    font-size: 12px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    text-transform: uppercase;
    text-align: center;
  }

  .icon-cp {
    display: flex;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    fill: var(--empty-fill, var(--basic-color));
  }
}

.choice-separator,
.sb-choice-separator {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  background-color: var(--choice-bg, var(--body));
  color: var(--choice-color, var(--black));
  font-size: 13px;
  font-weight: 700;
  letter-spacing: var(--ls-2);
  border-radius: 50%;
}

//desing system
.ds-main-tabs {
  margin-bottom: var(--ds-space-3);
}

.ds-esport-disciplines {
  margin-bottom: var(--ds-space-3);
}

.ds-live-disciplines {
  overflow: auto;
}

.ds-live-markets-tabs .default {
  margin: var(--ds-space-4) 0 var(--ds-space-1) 0;
}

.ds-live-markets-tabs {
  z-index: 2;
  overflow: hidden;
  position: sticky;
  top: calc(80px + var(--top-space-height) + var(--safe-area-top));
  background-color: var(--body);
  transition: top 0.3s ease;
  box-shadow: 0px 0px 5px 10px var(--body);
}

.has-scroll-top .ds-live-markets-tabs {
  top: calc(145px + var(--top-space-height) + var(--safe-area-top));
}
