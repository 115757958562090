$payments-methods-path: 'https://lvbet-static.com/images/other/payments-v4';

:root {
  --method-logo-path: url('#{$payments-methods-path}/payments-default.png');
}

.astropay,
.astropay-card {
  --method-logo-path: url('#{$payments-methods-path}/astropay.png');
}

.apple-pay {
  --method-logo-path: url('#{$payments-methods-path}/applepay.png');
}

.bank-transfer,
.bank-transfer-2,
.in-pay,
.inpay,
.finnish-bank-buttons,
.bank-transfer-payout,
.switch-bank-transfer-payout {
  --method-logo-path: url('#{$payments-methods-path}/bank-transfer.png');
}

.bitbay-pay,
.bit-pay {
  --method-logo-path: url('#{$payments-methods-path}/bitpay.png');
}

.blu-or-bank {
  --method-logo-path: url('#{$payments-methods-path}/blu-or-bank.png');
}

.blik {
  --method-logo-path: url('#{$payments-methods-path}/blik.png');
}

.visa,
.dotpay-cards,
.payment-trust,
.mastercards,
.truevo,
.truevo-widget,
.credit-debit-card,
.wirecard,
.worldpay,
.royalpay-bank,
.hexopay-cards,
.visa-mastercard {
  --method-logo-path: url('#{$payments-methods-path}/visa-mastercard.png');
}

.cashtocode {
  --method-logo-path: url('#{$payments-methods-path}/cashtocode.png');
}

.cards-jcb {
  --method-logo-path: url('#{$payments-methods-path}/cards-jcb.png');
}

.credit-debit-card-2 {
  --method-logo-path: url('#{$payments-methods-path}/visa-mastercard-apple-google.png');
}

.dotpay {
  --method-logo-path: url('#{$payments-methods-path}/dotpay.png');
}

.ecopayz {
  --method-logo-path: url('#{$payments-methods-path}/ecopayz.png');
}

.eps {
  --method-logo-path: url('#{$payments-methods-path}/eps.png');
}

.ecovoucher {
  --method-logo-path: url('#{$payments-methods-path}/ecovoucher.png');
}

.euteller {
  --method-logo-path: url('#{$payments-methods-path}/euteller.png');
}

.emerchantpay {
  --method-logo-path: url('#{$payments-methods-path}/emerchantpay.png');
}

.fastpay {
  --method-logo-path: url('#{$payments-methods-path}/fastpay.png');
}

.giropay {
  --method-logo-path: url('#{$payments-methods-path}/giropay.png');
}

.gpay {
  --method-logo-path: url('#{$payments-methods-path}/google-pay.png');
}

.ideal,
.sofort-giropay {
  --method-logo-path: url('#{$payments-methods-path}/ideal.png');
}

.interac-etransfer {
  --method-logo-path: url('#{$payments-methods-path}/interac-etransfer.png');
}

.interac-express-connect-payout {
  --method-logo-path: url('#{$payments-methods-path}/interac-expressconnect.png');
}

.interac-online-payout,
.interac-online-pay {
  --method-logo-path: url('#{$payments-methods-path}/interac-online.png');
}

.interkassa,
.interkassa-mastercard {
  --method-logo-path: url('#{$payments-methods-path}/mastercard.png');
}

.interkassa-visa {
  --method-logo-path: url('#{$payments-methods-path}/visa.png');
}

.jeton-wallet,
.jeton-direct,
.jeton-qr,
.jeton-go {
  --method-logo-path: url('#{$payments-methods-path}/jeton.png');
}

.kryptowaluty {
  --method-logo-path: url('#{$payments-methods-path}/kryptowaluty.png');
}

.letsbetmd-play {
  --method-logo-path: url('#{$payments-methods-path}/playplus.jpg');
}

.muchbetter {
  --method-logo-path: url('#{$payments-methods-path}/muchbetter.png');
}

.neosurf {
  --method-logo-path: url('#{$payments-methods-path}/neosurf.png');
}

.neteller {
  --method-logo-path: url('#{$payments-methods-path}/neteller.png');
}

.netbanking {
  --method-logo-path: url('#{$payments-methods-path}/netbanking.png');
}

.otopay {
  --method-logo-path: url('#{$payments-methods-path}/otopay.png');
}

.paysafecard {
  --method-logo-path: url('#{$payments-methods-path}/paysafecard.png');
}

.paypal {
  --method-logo-path: url('#{$payments-methods-path}/paypal.png');
}

.paysera {
  --method-logo-path: url('#{$payments-methods-path}/paysera.png');
}

.paykasa {
  --method-logo-path: url('#{$payments-methods-path}/paykasa.png');
}

.paykwik {
  --method-logo-path: url('#{$payments-methods-path}/paykwik.png');
}

.paymenticon {
  --method-logo-path: url('#{$payments-methods-path}/paymenticon.png');
}

.paypoint {
  --method-logo-path: url('#{$payments-methods-path}/paypoint.png');
}

.plix {
  --method-logo-path: url('#{$payments-methods-path}/plix-payment.png');
}

.przelewy24 {
  --method-logo-path: url('#{$payments-methods-path}/przelewy24.png');
}

.pay4fun {
  --method-logo-path: url('#{$payments-methods-path}/pay4fun.png');
}

.poli {
  --method-logo-path: url('#{$payments-methods-path}/poli.png');
}

.skrill {
  --method-logo-path: url('#{$payments-methods-path}/skrill.png');
}

.skrill-1-tap {
  --method-logo-path: url('#{$payments-methods-path}/skrill-1-tap.png');
}

.skrill-rapid-transfer {
  --method-logo-path: url('#{$payments-methods-path}/skrill-rapid-transfer.png');
}

.sofort {
  --method-logo-path: url('#{$payments-methods-path}/sofort.png');
}

.switch-lotericas {
  --method-logo-path: url('#{$payments-methods-path}/switch-lotericas.png');
}

.switch-cards {
  --method-logo-path: url('#{$payments-methods-path}/switch-cards.png');
}

.switch-boleto,
.switch-boletoflash {
  --method-logo-path: url('#{$payments-methods-path}/switch-boletoflash.png');
}

.switch-bancos-local {
  --method-logo-path: url('#{$payments-methods-path}/switch-bancos-local.png');
}

.switch-pix,
.switch-pix-payins,
.switch-pix-payout {
  --method-logo-path: url('#{$payments-methods-path}/switch-pix.png');
}

.switch-picpay {
  --method-logo-path: url('#{$payments-methods-path}/switch-picpay.png');
}

.siirto {
  --method-logo-path: url('#{$payments-methods-path}/siirto.png');
}

.trustly {
  --method-logo-path: url('#{$payments-methods-path}/trustly.png');
}

.trustly-latvia {
  --method-logo-path: url('#{$payments-methods-path}/trustly-latvia.png');
}

.trustly-tuv {
  --method-logo-path: url('#{$payments-methods-path}/trustly-tuv.png');
}

.trustpay {
  --method-logo-path: url('#{$payments-methods-path}/trustpay.png');
}

.tpay {
  --method-logo-path: url('#{$payments-methods-path}/tpay.png');
}

.uberwisen {
  --method-logo-path: url('#{$payments-methods-path}/uberwisen.png');
}

.upi {
  --method-logo-path: url('#{$payments-methods-path}/upi.png');
}

.ticketsurf {
  --method-logo-path: url('#{$payments-methods-path}/visa-voucher.png');
}

.venmo {
  --method-logo-path: url('#{$payments-methods-path}/venmo.png');
}

.visa-master-card {
  --method-logo-path: url('#{$payments-methods-path}/visa-master-card.png');
}

.visa-voucher {
  --method-logo-path: url('#{$payments-methods-path}/visa-voucher.png');
}

.volt {
  --method-logo-path: url('#{$payments-methods-path}/volt.png');
}

.volt-de {
  --method-logo-path: url('#{$payments-methods-path}/volt-de.png');
}

.webmoney {
  --method-logo-path: url('#{$payments-methods-path}/webmoney.png');
}

.zimpler {
  --method-logo-path: url('#{$payments-methods-path}/zimpler.png');
}

:lang(de),
[data-country='de'] {
  .visa,
  .credit-debit-card,
  .credit-debit-card-2,
  .ticketsurf,
  .truevo,
  .truevo-widget .worldpay,
  .wirecard {
    --method-logo-path: url('#{$payments-methods-path}/mastercard.png');
  }

  .trustly {
    --method-logo-path: url('#{$payments-methods-path}/trustly-tuv.png');
  }
}

[data-country='at'] {
  .trustly {
    --method-logo-path: url('#{$payments-methods-path}/trustly-tuv.png');
  }
}
