.profile .limits {
  margin-bottom: var(--my-5);

  &__group {
    margin-bottom: var(--my-5);
  }

  &__title {
    margin-bottom: var(--my-2);
    font-size: var(--title-fs, 14px);
    font-weight: 700;
  }
}

.profile .limits-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--my-2);
  padding: var(--limit-entry-py, var(--py-3)) var(--limit-entry-px, var(--px-3));
  background-color: var(--limit-bg, var(--bg-1));
  border-radius: var(--radius);

  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: var(--mx-2);
    background-color: var(--progress-bg, var(--bg-5));
    font-size: 14px;
    font-weight: 700;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__data {
    display: flex;
    flex-flow: column;
  }

  &__title {
    margin-bottom: var(--my-1);
    color: var(--limit-color, var(--color-1));
    font-size: 14px;
    font-weight: 700;
  }

  &__subtitle {
    color: var(--limit-color-2, var(--color-2));
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--edit-icon-width, 45px);
    height: var(--edit-icon-height, 43px);
    margin-left: var(--edit-icon-ml, 5px);
    background-color: var(--edit-icon-bg, var(--bg-4));
    border-radius: var(--edit-icon-radius, var(--radius-2));

    .icon-cp {
      width: 15px;
      height: 15px;
      fill: var(--edit-icon-fill, #fff);
    }
  }
}

.conditions {
  &-entry {
    cursor: pointer;
    margin-bottom: var(--my-2);
    padding: var(--py-3) var(--px-3);
    border: var(--border-2);
    border-radius: var(--radius);

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 700;
    }

    &__icon {
      flex-shrink: 0;
      margin-left: 10px;
    }

    &__icon .icon-cp {
      width: 15px;
      height: 19px;
      transition: transform 0.45s ease;
    }

    &__description {
      margin-bottom: 0;
      line-height: 24px;

      &:last-child {
        margin: 0;
      }

      a {
        color: var(--p-color, var(--color-3));
      }
    }

    &__content {
      display: none;
      margin-top: var(--my-2);
    }

    &.is-active div {
      display: flex;
    }

    &.is-active .icon-cp {
      transform: rotateX(180deg);
    }
  }
}

.profile .circle-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--progress-size, 100%);
  height: var(--progress-size, 100%);
  background: conic-gradient(var(--progress-bg-active) var(--deg, 0deg), var(--progress-bg, var(--bg-5)) 0deg);
  border-radius: 50%;

  svg {
    width: 20px;
    margin-top: 2px;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(--progress-border-size, 10px));
    height: calc(100% - var(--progress-border-size, 10px));
    background-color: var(--progress-bg-inside, var(--bg-1));
    border-radius: 50%;
    content: '';
  }

  &__value {
    z-index: 1;
    position: relative;
    font-size: var(--progress-value-fs, 12px);
    font-weight: var(--progress-value-fw, 600);
  }
}
