@import 'sportsbook/mobile-web/shared/layout/bottom-nav';

:root .main-nav {
  --nav-bg: #000;
  --nav-color: #838383;
  --nav-color-active: var(--white);
  --nav-height: 66px;
}

.main-nav__container {
  box-shadow: none;
  border-top: 1px solid var(--grey-primary-dark-1);
}

.main-nav-entry__counter {
  --counter-bg: var(--bg-3);
}
