.is-hidden {
  display: none;
}

.game-info {
  display: flex;

  &-data {
    color: var(--data-color);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    white-space: nowrap;

    &.sb-icon {
      padding-right: 18px;
      background-size: 13px;
      background-position: 100% 50%;
    }
  }
}

.indicator {
  margin-right: var(--space-2);
  font-size: 10px;
  font-weight: 800;
  letter-spacing: -0.05em;
  border-radius: var(--live-radius, 3px);

  &-live {
    display: flex;
    align-items: center;
    padding: 0 6px;
    background-color: var(--live-bg, #d70000);
    color: var(--live-color, #fff);
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
  }

  &-tv {
    padding: 0 8px;
    background-color: var(--tv-bg, var(--bg-1));
  }
}

.sport-selector-container {
  overflow: hidden;
}

.sport-selector {
  overflow: auto;
  display: flex;
  margin-bottom: var(--sport-selector-mb, 21px);

  &__entry {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 55px;
    padding: 0 var(--space-1);
    margin-right: var(--space-2);
    flex-shrink: 0;
    background-color: var(--selector-bg, var(--bg-1));
    border-radius: var(--selector-radius, var(--radius-1));
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;

    &.is-active {
      background-color: var(--selector-bg-active, var(--basic-color));

      .sport-selector__name {
        color: var(--selector-color-active, var(--basic-color));
      }
      .icon-az {
        --icon-fill-1: var(--basic-color);
        --icon-fill-2: var(--basic-color-dark);
      }
    }
  }

  &__name {
    overflow: hidden;
    display: block;
    width: 100%;
    margin-top: 6px;
    color: var(--selector-color, var(--color-2));
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.06em;
    line-height: 1;
    text-align: center;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon-cp {
    display: flex;
    width: 18px;
    height: 18px;
  }
}

.favourite-team {
  display: flex;
  align-items: center;
  height: 47px;
  margin-bottom: var(--space-2);
  padding: 0 var(--space-3);
  background-color: var(--fav-bg, var(--bg-1));
  font-size: 14px;
  font-weight: 700;
  letter-spacing: var(--ls-1);
  border-radius: var(--radius-1);

  &.is-selected {
    margin-bottom: var(--space-4);
  }

  .icon-cp {
    flex-shrink: 0;
  }

  &__sport {
    width: 21px;
    height: 21px;
    margin-right: var(--space-3);
  }

  &__title {
    overflow: hidden;
    padding-right: var(--space-3);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__fav {
    display: flex;
    width: 20px;
    margin-left: auto;
  }
}

.player-bets-league-widget-iframe {
  display: flex;
  flex-flow: column;
  min-height: 500px;

  iframe {
    display: block;
    flex-grow: 1;
  }
}

bcf-dst-player-bets.player-bets {
  display: block;
  height: calc(100vh - (177px + var(--top-space-height) + var(--safe-area-top)) - (66px + var(--safe-area-bottom)));
}

bcf-bet-on-action-main-page {
  .header {
    &::after {
      height: 13px;
      background: var(--header-bg, var(--bg-body));
    }
  }
}
