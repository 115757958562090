div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--standings,
div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--standingsTennis {
  &__content {
    background-color: var(--statscore-outside-app-bg);
  }

  .STATSCOREWidget--standing {
    background-color: var(--statscore-bg);
    border-radius: var(--statscore-radius);
  }

  .STATSCOREWidget--standing table {
    table-layout: auto !important;

    tbody tr {
      font-size: 11px;
      border-bottom: 1px solid var(--statscore-line);
    }

    thead tr th {
      background-color: transparent;
    }

    tbody tr td {
      height: 35px;

      &.STATSCOREWidget--standing__logo {
        display: flex;
        align-items: center;

        min-width: 26px;
      }
    }
  }

  .STATSCOREWidget--standing .STATSCOREWidget--partialHeader {
    @include partial-header;

    &:first-child {
      border-bottom: 1px solid var(--statscore-line);
    }
  }

  .STATSCOREWidget--standing__showMore {
    align-self: center;
    height: 25px;
    margin: 5px 0;
    padding: 4px 12px;
    color: var(--white);
    background: var(--statscore-bg-primary-light);
    font-size: 11px;
    border-radius: var(--statscore-radius);
  }
}
