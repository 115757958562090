.profile {
  display: block;

  .profile-filters {
    --filter-output-border: none;
    --filter-output-color: var(--active);
    --filter-output-bg: #342b0e;
    --filter-output-highlighted-color: var(--active);

    &__output-button {
      background: transparent;
    }
  }

  p {
    margin-bottom: var(--mb-paragraph, var(--my-4));
    color: var(--p-color, var(--color-4));
    font-size: 13px;
    font-weight: 600;
    letter-spacing: var(--ls-2);
    line-height: 2;

    span {
      color: var(--color-1);
      font-weight: 700;
    }
  }
  .profile-message {
    a {
      color: var(--white);
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &-breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: var(--breadcrumb-mb, var(--my-4));
    color: var(--breadcrumb-color, var(--color-1));
    font-size: 18px;
    font-weight: 800;
    letter-spacing: var(--ls-1);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--back-width, 47px);
      height: var(--back-height, 47px);
      margin-right: 15px;
      border: var(--breadcrumb-border, var(--border-2));
      border-radius: var(--breadcrumb-raidus, var(--radius));
    }
  }

  .bonuses-entry__info-link {
    color: var(--info-color, var(--color-4));
  }

  &-container {
    margin-bottom: 25px;

    &--limit-add {
      padding-bottom: 50px;
    }
  }

  &-message {
    padding: 10px var(--px-3);
    background-color: var(--message-bg);
    color: var(--message-color, var(--color-1));
    font-size: 12px;
    font-weight: 600;
    letter-spacing: var(--ls-1);
    line-height: 1.6;
    border-radius: var(--radius);

    &--mb-2 {
      margin-bottom: var(--my-2);
    }

    &--mb-3 {
      margin-bottom: var(--my-3);
    }

    &--user {
      padding: var(--py-1) var(--px-1);
      line-height: initial;
      text-align: center;
      border-radius: 0 0 var(--radius) var(--radius);
    }
  }

  &-error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: var(--el-height-1);
    padding: 10px var(--px-3);
    color: var(--error);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: var(--ls-1);
    border-radius: var(--radius);
    border-color: var(--error);
    border-style: solid;
    border-width: var(--error-border-bottom-width)
  }

  &-headline {
    margin-bottom: var(--headline-my, var(--my-2));
    font-size: 14px;
    font-weight: 700;
  }

  &-title {
    color: var(--title-color, var(--color-1));
    margin-bottom: var(--headline-my, var(--my-2));
    font-size: 14px;
    font-weight: 700;
  }

  &-empty {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--my-3);
    padding: var(--py-4) var(--px-1);
    color: var(--empty-color, var(--color-3));
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border: var(--empty-border, var(--border-5));
    border-radius: var(--radius);

    span {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
  
  &-form__group-datapicker {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 55px;
    padding: var(--space-2) var(--space-5) var(--space-2);
    background-color: var(--input-bg, var(--bg-1));
    color: var(--input-color, var(--color-1));
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    letter-spacing: var(--ls-1);
    outline: none;
    border: none;
    border-radius: var(--radius);
    transition: border 0.25s ease;
    &-title {
      color: #7e7e7e;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.profile .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--el-height-1);
  color: var(--btn-confirm-color, #000);
  background-color: var(--btn-confirm, var(--btn-bg-1));
  font-size: var(--btn-fs, 14px);
  font-weight: var(--btn-fw, 700);
  letter-spacing: var(--btn-ls, var(--ls-1));
  border-radius: var(--radius);
  border: 2px solid transparent;

  &:hover:enabled {
    color: var(--bg-brand);
    background-color: var(--bg-body);
    border: var(--border-7);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.45;
  }
}

.profile-state {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--my-1);
  height: 20px;
  padding: 0 10px;
  background-color: var(--state-bg);
  color: var(--state-color);
  font-size: 12px;
  font-weight: 600;
  border-radius: var(--state-radius, var(--radius));
  white-space: nowrap;
  margin-right: 10px;
}

.profile-filters {
  &__btn {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: var(--my-3);
    padding: var(--py-1) var(--px-2);
    color: var(--filter-btn-color, var(--color-1));
    background-color: var(--filter-btn-bg, var(--bg-1));
    font-size: 13px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    border-radius: var(--filter-radius, var(--radius));
  }

  &__output {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    margin-bottom: var(--my-3);
    padding: var(--py-1) var(--px-2);
    color: var(--filter-output-color, var(--color-4));
    background-color: var(--filter-output-bg, transparent);
    font-size: 13px;
    font-weight: 600;
    border: var(--filter-output-border, 2px solid var(--active));
    border-radius: var(--filter-radius, var(--radius));

    &-button {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      path {
        fill: var(--filter-output-highlighted-color, var(--bg-body));
      }
    }

    span {
      padding: 0 5px;
      color: var(--filter-output-highlighted-color, var(--bg-body));
      font-weight: 700;
      &:last-of-type {
        cursor: pointer;
      }
    }
  }
}