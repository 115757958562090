.profile .verification {
  margin-bottom: var(--my-5);

  &__group {
    margin-bottom: var(--my-5);
  }

  &__entries {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--my-2);
  }
}

.profile .verification-entry {
  padding: var(--py-3) var(--px-3);
  background-color: var(--verify-bg, var(--bg-1));
  border-radius: var(--radius);

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: calc(var(--my-1) / 2);
    color: var(--title-color, var(--color-1));
    font-size: 18px;
    font-weight: 700;
    letter-spacing: var(--ls-2);
  }

  &__state {
    height: 25px;
    padding: 0 var(--px-1);
    margin: 0 var(--mx-2);
  }

  &__subtitle {
    color: var(--title-color, var(--color-2));
    font-size: 12px;
    font-weight: 600;
    letter-spacing: var(--ls-1);

    span {
      font-weight: 900;
    }
  }
}
