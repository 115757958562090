div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer {
  .STATSCOREWidget--tabs__menu {
    min-height: initial;
    height: 50px;
    max-height: initial;
    margin-bottom: 12px;
    background-color: var(--statscore-bg);
    border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);

    @include breakpoint-min(1200px) {
      height: 47px;
    }

    //TOP TAB SLIDER
    .STATSCOREWidget--tabs__menuSlider {
      display: flex;
      height: 100%;

      //TOP TAB SLIDE
      .STATSCOREWidget--tabs__menuItem {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 10px;
        color: var(--neutral-light-3);
        background-color: transparent;
        border-bottom: 2px solid var(--statscore-bg);

        &.STATSCOREWidget--tabs__menuItem--active {
          color: var(--white);
          background-color: transparent;
          border-color: var(--basic-color);

          &::after {
            opacity: 1;
          }
        }

        .STATSCOREWidget--icon:before {
          font-size: 20px;

          @include breakpoint-min(1200px) {
            font-size: 24px;
          }
        }
      }

      //TOP TAB SLIDE TEXT
      .STATSCOREWidget--tabs__menuItemText {
        margin: 0;
        font-weight: 700;
        letter-spacing: -0.015em;
        line-height: initial;
      }
    }

    //TOP TAB SLIDER ARROW
    .STATSCOREWidget--tabs__sliderArrow.STATSCOREWidget--tabs__sliderArrow--previous,
    .STATSCOREWidget--tabs__sliderArrow.STATSCOREWidget--tabs__sliderArrow--next {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 30px;
      padding: 0;
      color: var(--white);
      background: var(--statscore-bg);
      font-size: 16px;
      bottom: 0px;
      box-shadow: 0px 0px 7px 3px var(--statscore-bg);
    }
  }
}

div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--tabs__container {
  //submenu list
  .STATSCOREWidget--submenu__sliderItemsContainer {
    transform: initial !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-bottom: 12px;
    @include breakpoint-min(1200px) {
      height: 47px;
    }
  }

  .STATSCOREWidget--submenu__labels,
  .STATSCOREWidget--submenu__sliderItemsContainer {
    background-color: var(--body);
  }

  //submenu item
  .STATSCOREWidget--submenu__sliderItemsContainer div.STATSCOREWidget--submenu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: fit-content;
    flex: 0 1 auto;
    overflow-x: scroll;
    margin-right: 8px;
    padding: 0 12px;
    border: initial;
    color: var(--white);
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    line-height: initial;
    background: transparent;
    border: 1px solid #313137;
    border-radius: var(--statscore-radius);

    @include breakpoint-min(1200px) {
      height: 35px;
      padding: 0 15px;
      font-size: 13px;
    }

    &--active {
      background: #747483;
      border-color: #747483;
      font-weight: 700;
    }
  }
}

// background: repeating-linear-gradient(to right, #236a34, #236a34 25px, #195f30 25px, #195f30 50px);

div.bottom-sheet-prematch {
  div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer {
    margin-top: 15px;
  }
}
