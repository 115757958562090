.sb-modal--keyboard {
  animation: none !important;
}

.betslip-keyboard {
  background-color: var(--keyboard-bg, var(--black));

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    text-transform: uppercase;

    .btn-close {
      margin-left: auto;
    }

    .betslip-keyboard__close {
      width: 45px;
      height: 30px;
      margin-left: auto;
      border: var(--btn-close-border, var(--border-2));
      border-radius: var(--radius-1);

      .icon-cp {
        width: 14px;
        height: 14px;

        svg {
          width: 100%;
          height: 100%;
          fill: var(--btn-close-fill, var(--basic-color));
        }
      }
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.05em;

    @include breakpoint-min(360px) {
      font-size: 15px;
    }

    span {
      display: inline-block;
      color: var(--basic-color);
    }
  }

  &__subtitle {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--keyboard-grey-color);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.025em;

    &::after {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 13px;
      height: 13px;
      margin-left: 5px;
      font-size: 8px;
      line-height: 0;
      border: 1px solid;
      border-radius: 50%;
      content: '?';
    }

    &.is-active span {
      opacity: 1;
    }
  }

  &__place {
    position: relative;
    display: flex;
    margin-bottom: 20px;

    button.btn {
      height: auto;
      padding: 0 15px;
      background-color: var(--keyboard-btn-bg, var(--basic-color));
      color: var(--keyboard-btn-color, var(--black));
      line-height: 1.1;

      @include breakpoint-min(360px) {
        min-width: 131px;
      }
    }
  }

  &__input-box {
    flex-grow: 1;
    margin-right: 10px;

    .betslip-keyboard-error {
      overflow: hidden;
      position: absolute;
      top: -5px;
      left: 0;
      transform: translateY(-100%);
      width: 100%;
      color: var(--error-color);
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.075em;
      line-height: 0.89;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  &__input {
    opacity: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    color: var(--input-color);
    background-color: var(--input-bg, var(--white));
    font-size: 14px;
    font-weight: 800;
    letter-spacing: -0.05em;
    text-align: left;
    border-radius: var(--radius-1);

    &:disabled {
      opacity: 1;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 800;
      letter-spacing: -0.05em;
      text-align: left;
    }

    @include breakpoint-min(360px) {
      height: 60px;
    }
  }

  &__currency {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: var(--black);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__stake {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    margin-bottom: 12px;

    .btn {
      height: 37px;
      background-color: var(--btn-bg);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.05em;
      line-height: 1;

      @include breakpoint-min(360px) {
        font-size: 13px;
      }
    }
  }

  &__core {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 10px;

    .btn {
      &-ctrl {
        border: var(--btn-border, var(--border-1));
        color: var(--btn-color, var(--color-1));

        .icon-cp {
          width: 27px;
          height: 27px;
          fill: var(--icon-ctrl-fill, var(--color-1));

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-remove {
        background-size: 27px;
      }

      &--bg-transparent {
        border: var(--btn-border, var(--border-1));
      }
    }
  }
}
