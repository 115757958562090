@import 'sportsbook/mobile-web/shared/components/odds';
@import 'sportsbook/mobile-web/shared/components/odds-table';
@import 'sportsbook/mobile-web/shared/brands-us/odds-table';

.odds {
  --odds-line-bg: #1f1f23;
  --odds-indicator-color: var(--neutral-light-3);
  --odds-radius: 5px;
  --odds-value-color: #b7b7c2;
}

.single-game {
  --odds-value-color: var(--white);
}

.odds.is-active {
  --odds-bg: var(--basic-color);
  --odds-color: var(--black);
  --odds-value-color: var(--black);
  --odds-value: var(--black);
  --odds-name-color: var(--black);
  --odds-indicator-color: var(--black);
}

.odds-table {
  --score-border: 1px solid var(--bg-1);
  --odds-table-gy: 25px;
  --odds-game: #18181b;
  --markets-color: var(--neutral-dark-4);
}

.headline-sport {
  --sport-color: var(--neutral-light-1);
  --league-color: var(--neutral-light-3);
}

.section-headline {
  --border-color-active: var(--bg-accent);
}

.pb-trigger {
  --pb-trigger-btn-bg: var(--bg-2);
}

.markets-select {
  --select-entry-border-color: var(--bg-primary);
  --select-entry-radius: var(--radius-1);
  --select-entry-color-active: var(--white);
  --select-entry-bg: transparent;
  --select-entry-bg-active: var(--bg-3);
}

.markets-select .icon-cp {
  display: none; //temp
}

.odds-table__markets {
  z-index: 2;
  position: sticky;
  padding-right: 10px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: var(--ls-1);
  line-height: 1;
  box-shadow: 0px 0px 5px 10px var(--body);
  background-color: var(--body);

  &--eu {
    transition: top 0.3s ease;
    grid-template-columns: 1fr;
  }

  &::before {
    display: block;
    content: '';
  }

  .markets-indicators {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    text-align: center;
  }
}

.markets-indicators__entry {
  display: flex;
  justify-content: center;
  color: var(--markets-color, #fff);

  &:last-of-type {
    grid-column: span 1/-1;
  }
}

.app-container.has-odds-table {
  overflow: initial;
}

.breadcrumbs-active .odds-table__markets {
  top: calc(165px + var(--top-space-height) + var(--safe-area-top));
}

.home-page .odds-table__markets {
  top: calc(125px + var(--top-space-height) + var(--safe-area-top));
}

.subpage-games--live .odds-table__markets {
  top: calc(152px + var(--top-space-height) + var(--safe-area-top));
}

.app-container .odds-table__markets--eu {
  top: calc(110px + var(--top-space-height) + var(--safe-area-top));
}

.breadcrumbs-active .has-scroll-top .odds-table__markets--eu {
  top: calc(165px + var(--top-space-height) + var(--safe-area-top));
}

.breadcrumbs-active .has-scroll-top .subpage-games--live .odds-table__markets--eu {
  top: calc(152px + var(--top-space-height) + var(--safe-area-top));
}

.button.odds,
a.odds {
  padding: 0;
}
