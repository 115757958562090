:root {
  /* stylelint-disable -- need this to top header works */
  --top-space-height: 0px;
  --bottom-space-height: 0px;
  /* stylelint-enable */

  //Ios safe area
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);
  --container-side-gutter: 10px;
  --container-side-padding: 10px;
  --container-max-width: 800px;

  //Dynamin variables
  --body-side-gutter: 14px;
  --basic-color: #2b6af7;
  --basic-color-always: #ffbd08;
  --basic-color-darker: rgba(251, 205, 0, 0.15);
  --basic-color-disabled: #7e6600;
  --bg-primary: #313137;
  --body: #000;
  --body-bg: #000;
  --body-rgb: 0, 0, 0;
  --white: #fff;
  --white-darker: #f8f8ff;
  --black: #000;
  --black-2: #110f11;
  --primary-color: #29292e;
  --color-1: #fff;
  --color-2: #b7b7c2;
  --color-3: #c1c1c1;
  --color-4: #a3a3a3;
  --color-accent-lighter: #6896fa;
  --bg-1: #29292e;
  --bg-2: #313137;
  --bg-green: #00b372;
  --bg-red-2: #f00000;
  --f-grey-4: #58585b;
  --f-grey-19: #1b1b1f;
  --f-grey-20: #222227;
  --f-grey-21: #b0b0ba;
  --f-grey-22: #9595a2;
  --f-grey-23: #a7a7a7;
  --f-grey-24: #58585b;
  --f-grey-25: #585860;
  --f-grey-26: #313137;

  --f-white-2: #e6e6ea;

  //active link color
  --active-color: #112a63;
  --active-dropdown-button: #fff;
  --active-dropdown-button-bg: #2b6af7;

  // --bg-3: #595964;
  // --bg-3: #6c6c72;
  --bg-3: #747483;
  --bg-accent: #2b6af7;
  --bg-accent-darker: #112a63;
  --red-1: #f10;
  --fill-1: #fff;
  --fill-2: #313137;
  --fill-3: #b7b7c2;
  --fill-4: #000;
  --fill-brand: var(--basic-color);
  --fill-active-1: #000;
  --border-1: 1px solid #313137;
  --border-2: 1px solid var(--basic-color);
  --radius-1: 5px;
  --radius-circle: 50%;
  --ls-1: -0.025em;
  --ls-2: -0.05em;
  --odds-increase: #00b372;
  --odds-decrease: #f10;
  --bg-brand: #2b6af7;
  --bg-install: #ffbd08;
  --install-color: #000;
  --brand-100: rgb(104 150 250 / 20%);
  --neutral-60: #616170;

  /*Desing system */
  --neutral-light-1: #e6e6ea;
  --neutral-light-2: #cbcbd2;
  --neutral-light-3: #b0b0ba;
  --neutral-light-4: #79798b;

  --neutral-dark-1: #1b1b1f;
  --neutral-dark-2: #323239;
  --neutral-dark-3: #4a4a55;
  --neutral-dark-4: #616170;

  --grey-primary: #313137;
  --grey-primary-dark-1: #222227;

  /*Desing system spacing*/

  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;

  //RADIUSES
  --radius-xs: 8px;
  --radius-s: 12px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  // body.esport {
  //   --basic-color: #3fc;
  // }

  // body.esport .betslip {
  //   --basic-color: #ffbd08;
  // }

  .side-menu {
    //radiuses
    --radius-1: 5px;
  }

  .right-menu {
    //radiuses
    --radius-1: 5px;
    --radius-2: 20px;
    --radius-3: 4px;
  }
}
