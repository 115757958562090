@import 'shared/mobile-web/lvbet/breadcrumbs/breadcrumbs';

.breadcrumbs {
  --sport-selector-mb: 0;
  --breadcrumb-pb-color: #ff9e40;
  --border-4: 2px solid var(--breadcrumb-pb-color);
}

.breadcrumbs {
  padding: 5px 10px;
}

bcf-statics-main-container {
  .breadcrumbs-entry {
    box-shadow: none;
  }
}

.breadcrumbs-entry {
  &--back {
    box-shadow: 3px 0 4px 5px var(--body-bg);
  }

  &--favourite {
    box-shadow: -3px 0 4px 5px var(--body-bg);
  }

  &--item div {
    border: none;
  }

  &__btn {
    background-position: center;
    background-size: 18px;
    background-repeat: no-repeat;
  }

  &__counter {
    width: 17px;
    height: 17px;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
  }

  &__name {
    @include capitalize;
  }

  .icon-cp {
    display: flex;
    width: 18px;
    height: 18px;

    &.icon-arrow-back {
      fill: var(--arrow-back-fill, #fff);
    }
  }
}

.breadcrumbs .sport-selector-container {
  flex-grow: 1;

  .sport-selector {
    padding-right: var(--space-2);

    &__name {
      margin-top: 6px;
      line-height: initial;
    }
  }
}

.breadcrumbs-entry__btn--pb-instruction {
  color: var(--white);
  border: var(--pb-instruction-border, var(--border-4));
  letter-spacing: -0.025em;
  text-align: center;
}
