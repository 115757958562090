:root {
  --statscore-radius: 5px;
  --statscore-grey: var(--neutral-light-3);
  --statscore-grey-2: var(--neutral-light-4);
  --statscore-bg: #18181b;
  --statscore-bg-primary: #313137;
  --statscore-bg-primary-light: #3e3e46;
  --statscore-bg-primary-dark: #29292e;
  --statscore-bg-nav: #18181b;
  --statscore-active-color: #fbcb00;
  --statscore-outside-app-bg: #110f11;
  --statscore-line: #110f11;
}

@mixin partial-header {
  height: 28px;
  line-height: 28px;
  background-color: var(--statscore-bg);
  color: var(--basic-color);
  font-size: 11px;
  font-weight: 700;
  border-bottom: 1px solid var(--statscore-line);

  @include breakpoint-min(1200px) {
    font-size: 14px;
  }
}
