@import 'shared/common/lvbet/bottom-sheet/bottom-sheet';
@import 'shared/mobile-web/lvbet/bottom-sheet/bottom-sheet';

:root {
  --game-bg: #1b1b1f;
  --iframe-bc-height: 250px;
  --iframe-bc-width: calc(var(--iframe-bc-height) / 0.679);
  --open-game-radius: 8px;

  .sb-open-game {
    --teams-gap: var(--space-3);
  }

  .tennis {
    --iframe-bc-width: calc(var(--iframe-bc-height) / 0.689);
  }

  .basketball {
    --iframe-bc-width: calc(var(--iframe-bc-height) / 0.743);
  }
}

.icon-bookmark {
  display: flex;
  width: 11px;
}

.scorebox-game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: var(--space-3);
  margin-bottom: var(--space-5);
  background-color: var(--game-bg);
  border-radius: var(--open-game-radius);

  &__col {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: var(--ls-1);

    &--score {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      max-width: calc(100% - 180px);
    }

    &--teams {
      overflow: hidden;
      margin-right: var(--space-4);
    }
  }

  &__live {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-3);
  }

  &__prematch {
    margin-bottom: auto;
    color: var(--date-color, var(--neutral-light-3));
    font-size: 11px;
  }
}

.sb-open-game-live .scorebox-game {
  overflow: hidden;

  .single-game-score__show-more {
    margin-top: 42px;
  }

  &::after {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 41px;
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--body);
    content: '';
  }
}

.scorebox-bar {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-3);
}

.scorebox-widgets {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.scorebox-teams {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--teams-gap);
  padding-top: var(--space-3);

  &__entry {
    overflow: hidden;
    position: relative;
    padding-left: 29px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:only-child {
      text-overflow: initial;
      white-space: initial;
    }
  }

  &__fav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    width: 18px;
  }
}

.sb-open-game-pre .scorebox-teams {
  padding-top: 0;
}

.scorebox-game .single-game-score {
  padding-right: 0;

  &__col {
    min-width: 14px;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 17px;
    line-height: 17px;
    margin-bottom: var(--space-3);
  }

  &__icon {
    display: flex;
    width: 12px;
  }

  &__card {
    &::before {
      display: block;
      height: 10px;
      width: 7px;
      background-color: var(--card-color, #b7991a);
      border-radius: 1px;
      content: '';
    }

    &--red {
      --card-color: #982022;
    }
  }

  &__results {
    padding-top: var(--space-3);
  }
}

.widget-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 35px;
  margin-left: var(--space-2);
  background-color: var(--widget-bg, var(--neutral-dark-1));
  border: 1px solid var(--grey-primary-dark-1);
  border-radius: var(--open-game-radius);

  &.is-active {
    border-color: var(--bg-active);
    background-color: var(--bg-active);
  }
}

.widget-btn .icon-cp {
  display: flex;
  height: 13px;
}

.icon-info .cls-1 {
  fill: var(--white);
}

.sport-headline {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: var(--space-4);

  &__region {
    height: 18px;
    width: 18px;
    margin-right: var(--space-2);
    flex-shrink: 0;
    background-size: cover;
    border-radius: 50%;
  }

  &__details {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    margin-right: var(--space-4);
    font-weight: 700;
    letter-spacing: var(--ls-1);
    white-space: nowrap;
  }

  &__details span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    width: 8px;
  }

  &__sport {
    color: var(--league-color, var(--neutral-light-3));
    font-size: 10px;
    text-transform: uppercase;
  }

  &__league {
    margin-top: -3px;
    color: var(--sport-color, var(--white));
    font-size: 12px;
  }
}

.scorebox-info {
  display: flex;
  justify-content: space-between;
  padding: var(--space-3);
  margin-bottom: var(--space-5);
  background-color: var(--scorebox-info-bg, var(--neutral-dark-1));
  color: var(--scorebox-info-color, var(--neutral-light-3));
  font-size: 11px;
  font-weight: 600;
  border: 1px solid var(--grey-primary-dark-1);
  border-radius: var(--open-game-radius);

  &__close {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
    margin: 3px 0 0 var(--space-3);
    fill: var(--white);
  }
}

.game-broadcast {
  position: relative;
  display: block;
  background-color: var(--body-bg);
  margin-bottom: var(--space-5);

  video {
    border-radius: var(--radius-2);
  }

  & > * {
    display: block;
  }

  &--bc {
    height: var(--iframe-bc-height);
    margin-bottom: var(--space-5);
    background-color: var(--game-bg);
    border-radius: var(--open-game-radius);
  }

  &--bc iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: var(--iframe-bc-width);
  }

  .STATSCOREWidgetContainer {
    border-radius: var(--open-game-radius);
  }

  button {
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    min-width: 125px;
    padding: 0 var(--space-3);
    margin: -20px auto 0;
    background-color: var(--game-bg);
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: var(--ls-2);
    border: 1px solid var(--primary-color);
    border-radius: var(--radius-1);
    text-transform: initial;
  }
}

.sb-open-game .tab-bar {
  margin: 0;
  font-size: 12px;

  &.is-active {
    margin: 0 0 var(--space-5) 0;
  }
}

.markets-selection {
  z-index: 2;
  overflow: hidden;
  position: sticky;
  top: calc(85px + var(--top-space-height) + var(--safe-area-top));
  display: flex;
  margin-bottom: var(--space-5);
  box-shadow: 0px 0px 5px 10px var(--body);
  background-color: var(--body);

  &__entry {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: var(--space-3);
    height: 30px;
    padding: 0 var(--space-3);
    background-color: var(--btn-market-bg, var(--neutral-dark-1));
    font-size: 11px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    white-space: nowrap;
    border: 1px solid var(--btn-border-color, var(--grey-primary-dark-1));
    border-radius: var(--btn-market-radius, var(--open-game-radius));

    &--bookmark {
      width: 33px;
      padding: 0;
    }

    &.is-active {
      color: var(--color-active);
      background-color: var(--bg-active);
      border-color: var(--bg-active);
    }
  }

  &__list {
    display: flex;
    overflow: auto;
  }
}

.markets-entry {
  padding: var(--space-3);
  margin-bottom: var(--space-3);
  background-color: var(--game-bg);
  border-radius: var(--markets-entry-radius, var(--open-game-radius));

  .odds-cp {
    display: block;
    margin-bottom: var(--space-2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .odds {
    justify-content: space-between;
    height: 40px;
    padding: 0 var(--space-3);
  }

  .icon-arrow-down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 25px;
    background-color: var(--chevron-down-bg, var(--bg-1));
    border-radius: var(--chevron-down-radius, var(--open-game-radius));

    svg {
      width: 11px;
      transition: transform 0.33s linear;
      transform: rotateX(180deg);
    }

    path {
      fill: var(--chevron-down-fill, #fff);
    }

    &.is-active svg {
      transform: rotateX(0deg);
    }
  }

  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-4);
    color: var(--market-title-color, var(--neutral-light-2));
    font-size: 12px;
    font-weight: bold;
  }

  &__panel {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__icon {
    flex-shrink: 0;
    margin-left: 20px;
  }

  &__all {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: var(--space-2) 0 var(--space-3);
    margin-bottom: var(--space-3);
    color: var(--neutral-light-2);
    font-size: 11px;
    font-weight: 700;
    border: 1px solid var(--grey-primary);
    border-radius: var(--all-radius, var(--open-game-radius));
  }

  &.is-collapsed .markets-entry__headline {
    margin: 0;
  }
}

.bottom-sheet .selection {
  overflow: auto;
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: var(--bottom-sheet-bg, var(--body));
  box-shadow: 0px 7px 8px 5px var(--bottom-sheet-bg, var(--body));

  &-entry {
    display: flex;
    align-items: center;
    height: 33px;
    margin-right: var(--space-3);
    flex-shrink: 0;
    padding: 0 var(--space-3);
    font-size: 11px;
    font-weight: 700;
    white-space: nowrap;
    border: var(--selection-border, var(--border-1));
    border-radius: var(--radius-1);
    letter-spacing: var(--ls-1);

    &__icon {
      display: block;
      height: 15px;
      width: 15px;
      margin-right: var(--space-2);
      background-size: cover;
    }

    &.is-active {
      background-color: var(--bg-active);
      color: var(--color-active);
      border-color: var(--bg-active);
    }
  }
}

.bottom-sheet {
  &__layout {
    height: calc(100% - var(--open-game-gap, 270px) - var(--top-space-height) - var(--safe-area-top));
  }

  .odds-table__block {
    margin-top: var(--space-5);
  }

  .game-entries__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-3);
    color: var(--neutral-light-3);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: var(--ls-1);

    .icon-cp {
      width: 18px;
      height: 18px;
    }
  }

  .game-entries__group {
    margin: 0;
  }
}

.bottom-sheet--live .bottom-sheet-header__title {
  display: flex;
  align-items: center;

  &::before {
    display: block;
    width: 9px;
    height: 9px;
    margin-right: var(--space-3);
    background-color: #d70000;
    border-radius: 50%;
    content: '';
  }
}

.bottom-sheet bcf-abios-widget {
  iframe {
    margin-bottom: var(--space-4);
  }
}

//TEMP
.bottom-sheet--games {
  --open-game-gap: 80px;
}

.sb-open-game-pre {
  .bottom-sheet--games,
  .bottom-sheet--betbooster,
  .bottom-sheet--statscore,
  .bottom-sheet--abios {
    --open-game-gap: 230px;
  }
}

.slider-range {
  padding: var(--space-2) 0;

  &__data {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 20px);
    margin-top: var(--space-1);
    color: var(--neutral-light-3);
    font-size: 11px;
    font-weight: 600;
    letter-spacing: var(--ls-1);
  }

  &__value--current {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: var(--range, 50%);
    transform: translate(0, -50%);
    width: 20px;
    background-color: var(--game-bg);
    color: var(--white);
    font-weight: 700;
    text-align: center;
    transition: 0.33s cubic-bezier(0, 0, 0.2, 1);

    &::after {
      pointer-events: none;
      position: absolute;
      top: -1px;
      left: 0px;
      transform: translateY(-100%);
      width: 20px;
      height: 20px;
      background-color: var(--white);
      border-radius: 50%;
      content: '';
    }
  }

  &__value--end {
    transform: translateX(20px);
  }
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  margin: 0;
  background: var(--black);
  background-repeat: no-repeat;
  background-size: var(--range, 0%) 100%;
  background-image: linear-gradient(var(--neutral-light-3), var(--neutral-light-3));
  border-radius: var(--open-game-radius);
  transition: 0.33s cubic-bezier(0, 0, 0.2, 1);
}

input[type='range']::-webkit-slider-thumb {
  opacity: 0;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: var(--white);
  border-radius: 50%;
  cursor: ew-resize;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  width: 100%;
}
