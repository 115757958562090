@import 'scheme';
@import 'submenu';
@import 'form';
@import 'stats';
@import 'last-match';
@import 'incidents';
@import 'standings';
@import 'prematch-score';
@import 'hints';
@import 'event-stats';

//LIVE
div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer {
  z-index: 1;
  margin-bottom: 15px;
  //scrollable container
  .STATSCOREWidget--tabs__container {
    border-radius: var(--statscore-radius);
  }

  //vertical scoreboard
  .STATSCOREWidget--scoreboard--xs.STATSCOREWidget--scoreboard.STATSCOREWidget--scoreboard--vertical
    .STATSCOREWidget--timeline {
    background-color: var(--statscore-bg-nav);
    border-top: 1px solid var(--statscore-outside-app-bg);

    .STATSCOREWidget--timeline__scaleLine {
      border-color: var(--statscore-bg);
    }

    & ~ div.STATSCOREWidget--tabs--scroll {
      border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);
    }
  }
}

//scrollable content
div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--tabs--scroll {
  .STATSCOREWidget--tabs__container {
    min-height: initial !important;
    height: initial !important;
    max-height: initial !important;
    padding-top: 0 !important;

    .STATSCOREWidget--live,
    .STATSCOREWidget--prematchSummary {
      min-height: 230px;
      height: 230px;
      border-radius: var(--statscore-radius);
    }

    .STATSCOREWidget--partialNoData {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
      background-color: var(--statscore-bg);
      font-weight: 600;
      border-radius: var(--statscore-radius);

      &::before {
        display: block;
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('/img/new-version/icons/basic/box-empty.svg');
        content: '';
      }
    }

    .STATSCOREWidget--formH2H .STATSCOREWidget--partialNoData {
      border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);
    }

    .STATSCOREWidget--partialHorizontalBar__inactiveChartBar {
      background-color: var(--statscore-bg-primary-dark);
    }

    .STATSCOREWidget--H2H,
    .STATSCOREWidget--standings {
      & > div {
        transform: initial !important;
      }
    }
  }

  &::before {
    display: none;
  }
}
