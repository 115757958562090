/****************************************************************************
1. ABSTRACT
****************************************************************************/
@import 'abstract/reset';
@import 'abstract/animations';
@import 'abstract/mixins';
/****************************************************************************
1. ENVIROMENTS
****************************************************************************/
@import 'env-variables';

/****************************************************************************
2. BASIC
****************************************************************************/
@import 'basic/typography';

/****************************************************************************
4. IMAGES
****************************************************************************/
@import 'images/icons';
@import 'images/icons-inline';

/****************************************************************************
4.  COMPONENTS
****************************************************************************/
@import 'components/buttons';
@import 'components/basic';
@import 'components/betslip';
@import 'components/open-game';
@import 'components/fast-bet';
@import 'components/odds-table';
@import 'components/upcomming';
@import 'components/datepicker';
@import 'components/sportsbook';
@import 'components/forms/forms';
@import 'components/keyboard';

/****************************************************************************
4.PAGES
****************************************************************************/

@import 'pages/profile';
// @import 'pages/static';
@import 'pages/promotions';
@import 'pages/modals';
@import 'pages/esport';
@import 'pages/search';

/****************************************************************************
5. LAYOUT
****************************************************************************/
@import 'layout/general';
@import 'layout/header';
@import 'layout/breadcrumbs';
@import 'layout/bottom-nav';
@import 'layout/side-menu';
@import 'layout/footer';

/****************************************************************************
6. WIDGETS
****************************************************************************/
@import 'widgets/widgets';

/****************************************************************************
7. THEME
****************************************************************************/
@import 'theme/variables';
