:root .pagination {
  //pagination
  --pagination-bg: #29292e;
  --pagination-radius: 10px;

  //pagination dimension
  --pagination-content-mx: 10px;

  //pagination btn color
  --pagination-btn-bg: transparent;
  --pagination-btn-bg-active: #595964;
  --pagination-btn-radius: 8px;

  --pagination-color: #fff;
  --pagination-color-hover: #fbcb00;
  --pagination-fill-hover: #fbcb00;

  //pagination btn dimensions
  --pagination-btn-height: 28px;
  --pagination-btn-width: 30px;
  --pagination-btn-mx: 5px;

  --pagination-icon-width: 34px;

  //pagination range
  --range-shadow-prev: 2px 0px 8px 0px #110f11;
  --range-shadow-next: -2px 0px 8px 0px #110f11;

  --range-px: 12px;

  .is-active {
    --pagination-color-active: #fff;
  }
}

.pagination {
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: var(--ls-1);

  &__content {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: var(--pagination-content-mx, 10px);
    background-color: var(--pagination-bg);
    border-radius: var(--pagination-radius);
  }

  &__icon {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--pagination-icon-width);
    height: 100%;
    flex-shrink: 0;
    cursor: pointer;

    &--prev {
      box-shadow: var(--range-shadow-prev);
    }

    &--next {
      box-shadow: var(--range-shadow-next);
    }
  }

  &__icon .icon-cp {
    width: 14px;
    height: 14px;
    path {
      fill: var(--white);
    }

    &:hover path {
      fill: var(--pagination-fill-hover);
    }
  }

  &__list {
    overflow: auto;
    display: flex;
    width: 100%;
    padding: var(--pagination-list-p, 5px 10px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--pagination-btn-width);
    height: var(--pagination-btn-height);
    margin-right: var(--pagination-btn-mx);
    flex-shrink: 0;
    border-radius: var(--pagination-btn-radius, var(--radius-1));
    transition: 0.2s ease;
    cursor: pointer;

    &:hover {
      color: var(--pagination-color-hover);
    }

    &.is-active {
      background-color: var(--pagination-btn-bg-active);
    }
  }

  &__range {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--range-px);
    flex-shrink: 0;
    background-color: var(--range-bg, var(--pagination-bg));
    border-radius: var(--range-radius, var(--pagination-radius));
  }
}
