.slider {
  position: relative;

  &__inner {
    display: flex;
    overflow-x: auto;
  }
}

.slider-main {
  display: block;
  margin: 20px 0px;
  @include breakpoint(800px) {
    margin: 20px -10px;
  }
  @include breakpoint(440px) {
    margin: 20px 0;
  }

  &.is-empty {
    display: none;
  }
}

.slide-main {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 32px);
  max-width: 420px;
  height: 130px;
  flex-shrink: 0;
  margin-right: var(--space-3);
  padding: 12px 12px 6px;
  border-radius: var(--slide-radius, var(--radius-1));
  border: var(--slide-border, var(--border-1));

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-right: var(--space-3);
  }

  @include breakpoint(440px) {
    width: 100%;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--slide-img-bg, var(--body-bg));
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
  }

  &__content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    height: 100%;
    width: 100%;
  }

  &__title {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: -0.07em;
    color: var(--slide-title-color, var(--color-1));
    max-width: calc(100% - 135px);
  }

  &__subtitle {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.07em;
    text-decoration: underline;
    color: var(--slide-subtitle-color, var(--basic-color));
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &__buttons {
    align-self: flex-start;
  }

  &__terms {
    margin-top: auto;

    span,
    a {
      position: relative;
      display: block;
      font-size: 9px;
      font-weight: 400;
      line-height: 1;
      color: var(--terms-color, var(--f-grey-5));

      &::after {
        content: '|';
        margin: 0 0.5px 0 1px;
      }

      &:last-child::after {
        content: none;
      }
    }
  }
}
