@import 'sportsbook/mobile-web/shared/components/sportsbook';

.game-info-data {
  --data-color: var(--neutral-light-4);
}

.indicator-live {
  --live-bg: #112a63;
  --live-color: #6896fa;
}

.sport-selector {
  --selector-bg: #1b1b1f;
  --selector-bg-active: var(--bg-3);
  --selector-color: var(--neutral-light-3);
  --selector-color-active: var(--white);
}

.tab-bar {
  --tab-color: var(--color-2);
  --tab-color-active: var(--white);
}

.section-headline .is-active {
  --filter-bg: var(--bg-3);
  --filter-border-color-active: var(--bg-3);
}
