.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: var(--color, #fff);
  background-color: var(--bg, transparent);
  border-radius: var(--border-radius, var(--radius-1));
  border: var(--border, initial);

  &.is-active {
    color: var(--color-active, var(--color));
    background-color: var(--bg-active, var(--bg));
  }

  &-action {
    --color-active: var(--basic-color);
    --border: var(--border-1);

    height: 35px;

    &.is-active {
      border-color: var(--bg-accent);
    }
  }

  &-tv {
    padding: 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &-scenic {
    --color-active: var(--basic-color);

    width: auto;
    padding: 0 17px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05em;
    border-color: var(--primary-color);
    text-transform: initial;

    &.is-active {
      border-color: var(--basic-color);
    }
  }

  &-betslip,
  &-my-bets {
    text-transform: uppercase;
  }

  &--xsmall {
    width: 36px;
    height: 36px;
  }

  &--small {
    width: 47px;
    height: 47px;
  }

  &--bg-primary {
    --bg: var(--primary-color);
    --bg-active: var(--basic-color);
    --color-active: var(--black);
  }

  &--bg-basic {
    --color: var(--black);
    --bg: var(--basic-color);
  }

  &--bg-green {
    --color: var(--white);
    --bg: var(--primary-green);
  }

  &--border {
    border-width: var(--border-width, 1px);
    border-style: (var(--border-style), solid);
    border-color: var(--border-color, initial);

    &.is-active {
      border-color: var(--border-color-active, initial);
    }
  }

  &--border-primary {
    --border: 1px solid var(--primary-color);
  }

  &--border-green-primary {
    --border: 1px solid var(--primary-green);
  }

  &--border-white {
    --border: 1px solid var(--white);

    &.is-active {
      border-color: var(--basic-color);
    }
  }

  &--active-green {
    &.is-active {
      border-color: var(--primary-green);
    }
  }
}
