.inbox {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--inbox-m, var(--my-2));
  font-size: 13px;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: var(--my-2);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
  }

  &__empty {
    margin-bottom: 0;
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-left: var(--inbox-counter-margin, var(--mx-1));
    background-color: var(--inbox-counter-bg, #fff);
    color: var(--inbox-counter-color, #000);
    font-size: 11px;
    font-weight: 800;
    letter-spacing: var(--inbox-conunter-ls, var(--ls-1));
    border-radius: 50%;
  }

  &__group {
    margin-bottom: var(--inbox-group-m, var(--my-4));
  }
}

.inbox-toolbox {
  display: flex;
  margin-bottom: var(--toolbox-m, var(--my-4));

  &__btn {
    margin-left: 0;
    height: 40px;
    padding: 0 var(--toolbox-button-padding, var(--px-2));
    font-size: 13px;
    font-weight: 700;
    color: var(--white);
    background-color: var(--toolbox-button-bg, var(--bg-1));
    border-radius: var(--inbox-radius, var(--radius));
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--toolbox-icon-width, 40px);
    height: var(--toolbox-icon-height, 40px);
    margin-left: var(--mx-1);
    background: transparent;
    border: var(--breadcrumb-border, var(--border-2));
    border-radius: var(--breadcrumb-raidus, var(--radius));

    &--checkbox {
      position: relative;

      .inbox-entry__checkbox {
        left: calc(0% + 9px);
      }
    }
  }

  .icon-cp {
    width: 15px;
    height: 15px;

    svg {
      fill: var(--white);
    }
  }
}

.inbox-entry {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: var(--inbox-entry-m, var(--my-2));
  min-height: var(--el-height-1);
  padding: 0 var(--px-3) 0 calc(var(--px-3) + var(--py-4) + var(--py-2));
  color: var(--inbox-entry-color, var(--color-1));
  background-color: var(--inbox-entry-bg, var(--bg-1));
  border-radius: var(--radius);

  &:last-child {
    margin-bottom: 0;
  }

  &__checkbox {
    position: absolute;
    top: 50%;
    left: var(--px-3);
    transform: translateY(-50%);
    .profile-checkbox__input {
      border: 2px solid var(--bg-4);
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }
  }

  &__title {
    flex: 1 0 0;
    color: var(--inbox-title-color, var(--color-3));
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__date {
    flex: 0;
    color: var(--inbox-date, var(--color-3));
    font-weight: 600;
  }

  &__label {
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 0 var(--px-1);
    margin-right: var(--mx-1);
    background-color: var(--label-bg);
    color: var(--label-color);
    font-weight: 600;
    letter-spacing: var(--ls-2);
    border-radius: var(--radius);
  }
}

p.inbox-empty {
  margin-bottom: 0;
}
