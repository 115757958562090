.wallet-main-container {
  &-toggle {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 25px;
    padding: 5px;
    background-color: var(--profile-toggle-bg, var(--bg-2));
    border-radius: var(--profile-toggle-radius, var(--radius));

    &__item {
      flex: 0.5;
      display: block;
      padding: var(--profile-toggle-item-padding, 6px 12px);
      background-color: transparent;
      border-radius: var(--profile-toggle-item-radius, var(--radius-2));
      color: var(--profile-toggle-item-color, var(--white));
      font-size: 14px;
      line-height: 17px;
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:first-letter {
        text-transform: uppercase;
      }

      &.is-active {
        background-color: var(--profile-toggle-item-active-bg, var(--white));
        color: var(--profile-toggle-item-active-color, var(--bg-2));
      }
    }
  }
}

.wallet {
  &__group {
    margin-bottom: var(--my-4);
    &:last-child,
    &:last-child p {
      margin-bottom: 0;
    }
  }

  &-entry {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--my-2);
    padding: var(--px-2) var(--px-2);
    background-color: var(--wallet-bg, var(--bg-1));
    font-size: 14px;
    font-weight: 700;
    border-radius: var(--wallet-radius, var(--radius));

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 70px;
      height: 55px;
      margin-right: var(--mx-2);
      background-image: var(--method-logo-path);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: var(--wallet-icon-bg, var(--bg-5));
      font-size: 14px;
      font-weight: 700;
      border-radius: var(--wallet-icon-radius, var(--radius-2));
    }

    &__data {
      display: flex;
      flex-flow: column;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: var(--wallet-color, var(--color-1));
      font-size: 14px;
      font-weight: 700;

      span {
        margin-right: var(--mx-1);
      }
    }

    &__status {
      padding: 2px 8px;
      font-size: 11px;
      font-weight: 600;
      border-radius: var(--wallet-status-radius, var(--radius-3));

      &.is-canceled {
        color: #ff9800;
        background-color: #ff980033;
      }

      &.is-failed {
        color: #ff2424;
        background-color: #ff242433;
      }

      &.is-in-progress {
        color: #009cff;
        background-color: #009cff33;
      }

      &.is-success,
      &.is-completed {
        color: #1afaac;
        background-color: #1afaac33;
      }

      &.is-created {
        color: #fff;
        background-color: #ffffff33;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      color: var(--wallet-info-color, var(--color-2));
      font-size: 12px;
      font-weight: 600;
    }
  }

  .wallet-entry__col {
    &--details {
      display: flex;
      align-items: center;
    }

    &--amount {
      letter-spacing: -0.025em;
      @include ellipsis;

      &.is-deposit {
        color: rgb(0 179 114 / 100%);
      }

      &.is-withdraw {
        color: rgb(242 0 52 / 100%);
      }
    }
  }
}
