.privacy-condition {
  &__title {
    margin: var(--my-6) 0;
  }
}

.privacy-condition .profile-checkbox {
  margin-bottom: var(--my-4);
  opacity: 0.45;
  cursor: initial;

  &:first-child {
    cursor: pointer;
    opacity: 1;
  }

  &:first-child.is-active ~ .profile-checkbox {
    cursor: pointer;
    opacity: 1;
  }
}
