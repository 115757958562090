.shop-orders {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: var(--my-2);
  margin: 0 0 20px;
}

.shop-entry {
  padding: var(--px-2) var(--py-2);
  border: var(--entry-border, var(--border-2));
  border-radius: var(--entry-radius, var(--radius));

  &__date {
    display: flex;
    align-items: center;
    margin-bottom: var(--my-3);
    font-size: 12px;
    color: var(--entry-date-color, var(--color-8));
    font-weight: 600;
    letter-spacing: var(--ls-2);
  }

  &__order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 13px;
    letter-spacing: var(--ls-2);

    &--title {
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--entry-title-color, var(--white));
      font-weight: 600;
      white-space: nowrap;
    }

    &--value {
      color: var(--entry-value-color, var(--white));
      font-weight: 700;
      white-space: nowrap;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--my-3);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: var(--ls-2);

    &--total {
      color: var(--summary-total-color, var(--color-8));
    }

    &--value {
      color: var(--summary-value-color, var(--active));
    }
  }
}
