.profile .responsible {
  &__group {
    margin-bottom: var(--my-5);
  }

  &__title {
    margin-bottom: var(--my-2);
    font-size: 14px;
    font-weight: 700;
  }

  &__form {
    grid-gap: var(--my-4);
  }
}
