@import 'shared/mobile-web/lvbet/bottom-nav/bottom-nav';

.main-nav {
  &__container {
    display: flex;
    align-items: center;
    height: var(--nav-height);
  }
}

.live-agent {
  .main-nav__container {
    padding-top: var(--space-3);
    height: 117px;
    align-items: flex-start;
  }
}

.main-nav .icon-cp {
  display: block;
  width: 25px;
  height: 25px;
}

.main-nav .icon-star {
  --icon-stroke-1: #585860;
  --icon-fill-1: #585860;
}

.main-nav-entry {
  overflow: hidden;
  padding: 0 4px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;

  &__counter {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(calc(-50% + 22px));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background: var(--counter-bg, #da1616);
    color: var(--counter-color, #fff);
    font-weight: 700;
    font-size: 10px;
    letter-spacing: -0.05em;
    line-height: 15px;
    border-radius: 50%;
  }
}
