.basic-form {
  &__row {
    display: grid;
    row-gap: 15px;
    column-gap: 10px;
    margin-bottom: 15px;

    @include clear-mb;

    &.has-double {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  &__btns {
    display: grid;
    row-gap: 10px;
  }

  &-entry {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;

    .icon-cp {
      position: absolute;
      right: 15px;
      width: 15px;
      height: 15px;
      fill: var(--f-grey);
    }

    .btn {
      flex-basis: 47px;
      margin-top: 21px;
    }
  }

  &-label {
    color: var(--f-grey);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.05em;
    @include capitalize;
  }

  &-input,
  &-select {
    height: 47px;
    border-radius: var(--radius-primary);
  }

  &-input {
    padding: 0 10px;
    background-color: var(--primary-color);
    border: initial;
  }

  &-input,
  &-input::placeholder,
  &-select {
    color: var(--white-darker);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.025em;
  }

  &-select {
    text-transform: uppercase;
    background-size: 10px;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    background-image: url('/img/new-version/icons/white/chevron-down.svg');
    background-color: var(--primary-color);
    appearance: none;
    @include ellipsis;
  }

  &-error {
    margin-top: 5px;
    color: var(--red);
    font-size: 11px;
  }
}

.hint-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--input-bg, var(--bg-1));
  border-radius: var(--radius);
  background-size: 19px;

  &__overlay {
    display: none;
    z-index: 13;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 55%);
  }

  &__selected {
    padding-left: 20px;
    background-position: 0 50%;
    background-size: 14px;
    background-repeat: no-repeat;
    color: var(--white-darker);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.025em;
    @include ellipsis;
  }


  &__content {
    display: none;
    z-index: 14;
    position: absolute;
    @include breakpoint-max(800px) {
      position: fixed;
      top: calc((100vh - 267px) / 2);
      max-width: 100%;
    }
    top: calc(0% + 70px);
    left: 0;
    flex-flow: column;
    width: 100%;
    max-width: 220px;
    padding-left: calc(13px + var(--safe-area-left));
    padding-right: calc(13px + var(--safe-area-right));
    border-radius: 3px;

    &.is-active {
      display: flex;

      & + div {
        display: block;
      }
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 0;
    padding: 15px;
    font-size: 12px;
    font-weight: 700;
    background: var(--white) url(/img/new-version/icons/grey/search.svg) no-repeat right 15px center/17px;
    border: initial;
    border-bottom: 1px solid #e0e0e0;
    @include radius-top(var(--radius-primary));

    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);
  }

  &-error {
    display: none;
    padding: 9px 8px;
    background-color: #a90101;
    color: var(--white);
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    @include radius-bottom(var(--radius-primary));

    &.is-active {
      display: block;
    }
  }

  &-list {
    overflow: auto;
    display: block;
    height: 100%;
    max-height: 220px;
    flex-grow: 1;
    background-color: var(--white-darker);
    color: var(--black);
    @include radius-bottom(var(--radius-primary));

    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);

    &__entry {
      padding: 9px 10px 9px 35px;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: left 8px center;
      background-color: #fafafa;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: -0.025em;
      text-transform: initial;
      @include ellipsis;

      cursor: pointer;
    }
  }
}

.basic-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.05em;

  &__state {
    position: relative;
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background-color: transparent;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.05em;
    border: 2px solid var(--basic-color);
    border-radius: var(--radius-primary);

    &::after {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      border-radius: var(--radius-primary);
      transition: opacity 0.2s ease-in-out;
      content: '';
    }

    &.is-active::after {
      opacity: 1;
    }
  }

  &__title {
    color: var(--f-black-2);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.05em;
  }
}
