:root .profile {
  //Letter spacing
  --ls-1: -2.5%;
  --ls-2: -5%;

  //Margin vertical
  --my-1: 10px;
  --my-2: 15px;
  --my-3: 20px;
  --my-4: 25px;
  --my-5: 30px;
  --my-6: 35px;

  //Margin horizontal
  --mx-1: 10px;
  --mx-2: 15px;
  --mx-3: 20px;

  //Padding
  --px-1: 10px;
  --px-2: 15px;
  --px-3: 20px;
  --py-1: 10px;
  --py-2: 15px;
  --py-3: 20px;
  --py-4: 25px;

  //Height element
  --el-height-1: 55px;

  //Status
  --active: #fbcb00;
  --verified-bg: #225849;
  --verified-color: #00b372;

  //Font colors
  --color-1: #fff;
  --color-2: #abaab2;
  --color-3: #84838a;
  --color-4: #e1e1e1;
  --color-5: #38373e;
  --color-6: #f8f8ff;
  --color-7: #ccc;
  --color-8: #8d8d92;
  --color-9: #ccebff;
  --color-10: #7e7e7e;
  --neutral-60: #616170;


  //Radiuses
  --radius: 20px;
  --radius-2: 15px;
  --radius-3: 10px;

  //Backgrounds
  --bg-1: #313137;
  --bg-2: #29292e;
  --bg-3: #1f1e22;
  --bg-4: #595964;
  --bg-5: #494952;
  --bg-6: #f8f8ff;
  --bg-7: #99999d;
  --bg-9: #1b1b1f;
  --bg-10: #232327;

  //hover menu color
  --hover-nav-element: #595964;

  //Borders
  --border-1: 2px solid #110f11;
  --border-2: 2px solid #313137;
  --border-4: 2px solid #595964;
  --border-5: 1px dashed #454347;
  --border-6: 2px solid #4b4b55;
  --border-7: 2px solid #fbcb00;

  //Form errors
  --error: #e11002;
  --error-border-width: 2px 0 0 0;
  --error-border-bottom-width: 0 0 2px 0;

  //Form elements

  select {
    --option-color: #000;
  }

  label {
    --label-color: #fff;
  }

  .is-date label {
    --label-color: #7e7e7e;
  }

  .profile-input ~ label,
  .profile-select ~ label {
    --label-color: #7e7e7e;
  }

  .has-error .profile-input ~ label,
  .has-error .profile-select ~ label {
    --label-color: var(--error);
  }

  .hint-dropdown {
    --hint-input-px: 15px;
    --hint-input-py: 15px;
    --hint-input-h: 55px;
    --hint-close-size: 15px;
  }

  .btn {
    --btn-bg-1: #fbcb00;
  }

  .profile-message {
    --message-bg: #228df5;
  }

  .pass-valid li.is-active {
    --pass-entry: var(--active);
  }

  .profile-checkbox {
    --checkbox-active: var(--active);
  }

  //Inbox
  .inbox {
    --inbox-title-color: #fff;

    .is-outdated {
      --inbox-title-color: #84838a;
    }

    .has-system {
      --label-color: #228df5;
      --label-bg: #2d4866;
    }

    .has-warning {
      --label-color: #ff9500;
      --label-bg: #5a452c;
    }
  }

  .right-menu__registration,
  .email-verification-info {
    --text-color: #ff9800;
    --state-bg: #cc7a0026;
    --button-bg: #ffffff1a;
  }

  .verification {
    --state-bg: #595964;
    --state-color: #fff;

    .is-confirmed {
      --state-bg: #225849;
      --state-color: #00b372;
    }

    .is-rejected {
      --state-bg: #572736;
      --state-color: #f20034;
    }
  }

  .limits {
    --limit-entry-px: 15px;
  }

  .circle-progress {
    &.low {
      --progress-bg-active: #00b372;
    }

    &.medium {
      --progress-bg-active: #ff9500;
    }

    &.heigh {
      --progress-bg-active: #494952;
    }
  }

  .profile-state {
    &.is-completed {
      --state-color: #00e592;
      --state-bg: #073c28;
    }
    &.is-created,
    &.is-verified {
      --state-color: #fff;
      --state-bg: #494952;
    }

    &.is-failed {
      --state-color: #ff2424;
      --state-bg: #572736;
    }

    &.is-preparing,
    &.is-confirmed,
    &.is-executed,
    &.is-pending {
      --state-color: #009cff;
      --state-bg: #032e59;
    }
    &.is-expired,
    &.is-cancelled,
    &.is-required {
      --state-color: #ff9800;
      --state-bg: #61380f;
    }
  }
  //paths

  --default-bonus-path: url('https://lvbet-static.com/images/headless/bg/default-bonus.svg');
}
