@import './fonts'; 

button {
    font-family: inherit;
}

.text-basic {
    font-size: 13px;
    letter-spacing: -0.05em;
}

.text-m-size {
    font-size: 15px;
    letter-spacing: -0.05em;
}

.text-grey {
    color: var(--grey);
}

.text-white {
    color: var(--white);
}

.text-grey,
.text-white {
    span,
    a {
        color: var(--basic-color);
    }
}

.success-state {
    color: var(--primary-green);
}

.error-state {
    color: var(--primary-red);
}