@import 'sportsbook/mobile-web/shared/layout/general';
@import 'sportsbook/mobile-web/shared/layout/slider';

:root {
  .navbar-slider {
    --promo-btn-radius: var(--radius-1);
    --promo-btn-color-main: #fff;
    --promo-btn-bg-main: var(--bg-1);
    --promo-icon-fill: var(--basic-color);
  }

  .navbar-entry {
    --border-color-active: var(--bg-accent);
  }

  .slider-main {
    --slider-m: 10px -10px;
  }

  .slide-main__terms {
    --terms-color: var(--neutral-light-2);
  }
}

.navbar-slider__entry {
  border: var(--border-1);
}

.slide-main__buttons .btn-slide-main {
  height: 33px;
  padding: 0 12px;
  margin-top: 8px;
  background: var(--bg-1);
  font-weight: 700;
}

.kumulos {
  &-banner-header {
    h1 {
      color: var(--white) !important;
      font-size: 16px !important;
      font-weight: 800 !important;
      line-height: 150% !important;
      letter-spacing: -0.32px !important;
    }
  }
  
  &-banner-body {
    color: var(--ds-neutral-40) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    letter-spacing: -0.28px !important;
  }

  &-banner-container {
    background-color: var(--ds-neutral-100) !important;
  }

  &-action-button {
    background-color: var(--ds-brand-60) !important;
    text-transform: none !important;
    &-cancel {
      color: var(--white) !important;
      background-color: var(--ds-neutral-80) !important;
      text-transform: none !important;
    }
  }
  
  &-banner-icon {
    align-self: center;
    background-color: rgba(56, 136, 255, 0.15) !important;
  }

  body & {
    &-banner {
      &-icon {
        &::after {
          background-repeat: no-repeat;
        }
      }
    }
  }
}
