@import 'shared/mobile-web/lvbet/header/header';

.header {
  --header-logo-size-s: 70px;
  .btn-register {
    font-weight: 700;
    letter-spacing: var(--ls-2);
  }
}

.header-logo .icon-logo {
  display: flex;
}

.header-nav {
  .btn-icon {
    height: 38px;
    width: 40px;
  }
}

.header__menu .icon-arrow-back {
  z-index: 1;
  position: relative;
  width: 25px;
  background-color: var(--body);
  fill: var(--white);
}

.cash-info__button .icon-cp {
  display: flex;
}
