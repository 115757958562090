div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--tabs__container {
  .STATSCOREWidget--hintsList__hintRow,
  .STATSCOREWidget--hintsList__hintRow:first-of-type {
    padding: 0;
  }

  .STATSCOREWidget--hintsList__list {
    overflow: hidden;
    border-radius: var(--statscore-radius);
  }

  .STATSCOREWidget--hintsList__hintRow {
    .STATSCOREWidget--hintsList__hint {
      overflow: hidden;
      position: relative;
      padding: 15px 15px 15px 50px;
      background: var(--statscore-bg);
      font-size: 12px;
      border-radius: 0;
      border-bottom: 1px solid var(--statscore-line);

      .STATSCOREWidget--hintsList__hintMessage {
        line-height: initial;

        &::before {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          background: #193d33;
          color: #00b372;
          font-weight: 600;
          font-size: 13px;
          line-height: 0;
          border-radius: 50%;
          content: '?';
        }
      }
    }
  }
}
