div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--eventIncidents {
  .STATSCOREWidget--eventIncidents__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    background: var(--statscore-bg);
    border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);

    .STATSCOREWidget--eventIncidents__incidentsList {
      width: 100%;
    }
  }

  .STATSCOREWidget--eventIncidents__filtersBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    background: var(--statscore-bg);
    border-bottom: 1px solid var(--statscore-line);

    .STATSCOREWidget--eventIncidents__toggleSwitch {
      background: var(--statscore-bg-primary-light);
      border: initial;
      &.STATSCOREWidget--eventIncidents__toggleSwitch--on {
        background-color: var(--basic-color);
      }
    }

    .STATSCOREWidget--eventIncidents__selectCurrentOption {
      height: 25px;
      padding: 0 10px;
      background: var(--statscore-bg-primary-light);
      border-radius: 10px;

      i {
        margin-right: 8px;
      }
    }

    .STATSCOREWidget--eventIncidents__selectOptions {
      top: 40px;
      left: 0;
      right: initial;
      min-width: 100px;
      background: var(--white);
      color: var(--black);
      border-radius: var(--statscore-radius);
      box-shadow: 0px 0px 10px 10px var(--statscore-bg-nav);
    }

    .STATSCOREWidget--eventIncidents__selectOption {
      background-color: transparent;
      font-weight: 600;
      border-bottom: 1px solid #cbcbcb;

      &:last-child {
        border-bottom: initial;
      }
    }
  }

  .STATSCOREWidget--eventIncidents__statusBar,
  .STATSCOREWidget--eventIncidents__statusBarStatus {
    color: var(--basic-color);
    background-color: var(--statscore-bg);
  }

  .STATSCOREWidget--eventIncidents__statusBar .STATSCOREWidget--eventIncidents__statusBarStatus {
    justify-content: center;
    height: 25px;
    border-bottom: 1px solid var(--statscore-line);
  }

  .STATSCOREWidget--eventIncidents__showMore {
    display: inline-flex;
    margin: 5px auto;
    min-height: 30px;
    color: var(--white);
    background: var(--statscore-bg-primary-light);
    border-radius: var(--statscore-radius);
  }

  .STATSCOREWidget--eventIncidents__incidentRowStatus,
  .STATSCOREWidget--eventIncidents__incidentRow {
    background-color: var(--statscore-bg);
    border-bottom: 1px solid var(--statscore-outside-app-bg);
  }
}
