@import 'sportsbook/mobile-web/shared/components/betslip';

body.bets-header-index {
  overflow: hidden;
}

.betslip-cp {
  --betslip-bg: #000;
  --bet-bg-trans: rgb(49 49 55 / 95%);
  --boost-color: var(--basic-color);
  --won: #289b00;
  --failure: #c90000;
  --return: #e48800;
  --success: var(--basic-color);
  --error: #da1616;
  --betslip-radius: 5px;

  .sb-checkbox {
    --checkbox-border: 1px solid var(--bg-accent);
    --tick-fill: var(--white);

    &.is-disabled {
      pointer-events: none;
      // opacity: 0.4;
    }

    &.is-active {
      --checkbox-bg: var(--bg-accent);
    }
  }
}

.betslip-entry-my-bets {
  --bet-bg: #26262a;
}

.betslip-bet .vr-keyboard {
  --vk-bg: #16161a;
  --vk-color: var(--white);
  --vk-border: #3e3e46;
  --currency-color: var(--white);
}

.sb-bet-status {
  --status-bg: #110f11;
  --share-border-color: var(--body);

  &.is-won {
    --status-color: var(--won);
  }

  &.is-return {
    --status-color: var(--return);
  }

  &.is-lost {
    --status-color: var(--failure);
  }

  &.cashout-available {
    --status-bg: var(--won);
  }

  &.see-more {
    --status-bg: #747483;
  }

  &.cashout-not-available {
    --status-color: #3f3e3f;
  }
}

.sb-sticky-info {
  &--cashout {
    --bg: var(--red);
  }

  &--bonus {
    --bg: #289b00;
  }
}

.betslip-checkbox {
  --toggle-bg: #4b4b55;
  --toggle-bg-inside: #fff;
}

.betslip-bet .betslip-checkbox {
  --toggle-bg-active: var(--bg-accent);
}

.sb-bet-details {
  --color: #717171;
  --name-color: #9b9b9b;
}

.sb-icon-state {
  --border: 1px solid var(--basic-color);
}

.betslip-heading .btn {
  --border-color: var(--primary-color);
  --border: 1px solid var(--border-color);
  --fill: var(--basic-color);
  --close-fill: #fff;
}

//SETTINGS
.basic-checkbox {
  --checkbox-color-active: var(--basic-color);
  --checkbox-bg-active: var(--bg-accent);
  --tick-fill: var(--white);
}

.betslip-settings .basic-checkbox {
  &__state {
    border-color: var(--primary-color);
    border-radius: var(--radius-1);
    &.is-active {
      border-color: var(--checkbox-bg-active);
    }
  }
}

//NAV
.betslip-nav {
  .btn.is-active {
    --bg-active: var(--bg-accent);
    --color-active: var(--white);
  }
}

.sb-counter {
  --counter-bg: #727272;

  &.cashout-available {
    --counter-bg: var(--primary-green);
  }
}

.betslip-bet {
  --bet-separator: var(--color-2);

  .has-score {
    --bet-separator: var(--basic-color);
  }
}

.betslip-place {
  --place-color-2: #636363;
  --terms-color: #636363;
  --btn-place-disabled: rgba(56, 136, 255, 0.15);

  .btn {
    &--bg-basic {
      color: var(--white);
    }

    &.is-disabled {
      color: rgba(255, 255, 255, 0.2);
    }
  }
}

.betslip-entry {
  --close-fill: #707070;
  --bet-odds-radius: 4px;
  --banker-bg: var(--body);
  --tax-color: #636363;
}

.betslip-scroll-content--my-bets {
  overflow: auto;
}

.betslip-bottom-bar {
  bottom: 8px;

  .btn {
    background-color: #313137;
  }
}

.betslip-bet {
  &__info {
    display: none;
  }
}

.indicator-live {
  --live-radius: 5px;
}

.betslip-error--outside {
  border-width: 1px;
}

.betslip-bet-selection {
  &__separator {
    padding: 0 2px;
  }
}

.betslip-entry-my-bets {
  padding: 12px;
  .sb-game-info {
    margin-bottom: 12px;
  }
}

.betslip-cp .sb-bet-status__box--small {
  border: 2px solid #100e11;
}

.betslip-place .betslip-terms {
  justify-content: flex-end;

  &__data {
    &:first-child {
      margin-right: 0;
    }
  }
}

.vr-keyboard {
  &__currency {
    font-size: 12px;
  }
}

.betslip-cp .indicator-live {
  text-transform: uppercase;
}

.logged-only {
  &__title {
    font-style: normal;
    text-transform: initial;
  }

  .icon-coupon-alt {
    fill: var(--bg-accent);
  }

  .btn--bg-basic {
    color: var(--white);
    background-color: var(--bg-accent);
    text-transform: uppercase;
  }

  .sb-choice-separator {
    color: var(---white);
  }
}

.betslip-place {
  border-top: var(--border-1);
}

.betslip {
  .modal-container {
    transform: initial;

    .sb-modal.is-visible {
      animation-delay: 0ms !important;
    }
  }
}

//TEMP
.betslip .betslip-heading .btn-bb {
  display: none;
}

.betslip-system {
  .betslip-system-entry__title,
  .betslip-system-entry__multiplier {
    font-weight: 600;
  }
  .is-disabled {
    .betslip-checkbox-small__state {
      opacity: 0.35;
    }

    .betslip-system-entry__title {
      color: #79798b;
    }

    .betslip-system-entry__input {
      opacity: 0.65;
    }
  }
}

bcf-betslip-round-robin-combined {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.btn-trashcan {
  padding: 0 12px;

  .icon-cp {
    order: -1;
    margin: 0 8px 0 0;
    fill: var(--white);
  }
}

.betslip-summary {
  &-odds {
    margin-left: 8px;
  }

  &__value {
    --value-color: #fbcd00;
  }
}

.betslip-cp .vr-keyboard.has-winnings {
  border-width: 1px;
  background-color: #16161a;
  font-size: 15px;
}
