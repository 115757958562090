@import 'sportsbook/mobile-web/shared/layout/header';

:root {
  .header-container {
    --header-bg: var(--body);
    --header-border-bottom: 1px solid var(--bg-1);
    --hamburger-bg: #fff;
    --logo-title-color: #fff;
    --cash-border: 1px solid var(--bg-accent);
    --cash-radius: var(--radius-1);
    --cash-btn-bg: var(--bg-accent);
    --cash-btn-radius: var(--radius-1);
    --cash-bonus-color: var(--color-2);
  }

  .btn-register {
    --bg: var(--bg-accent);
    --color: var(--white);
  }

  .header-bar {
    --btn-bg: var(--bg-accent);
    --radius-primary: 5px;
  }

  .header-bar--geocomply {
    --btn-bg: transparent;
    --header-bar-bg: var(--bg-accent);
  }
}

.header .btn-register {
  margin: 0;
  border-radius: var(--radius-1);
  letter-spacing: var(--ls-1);
}

.header .cash-info {
  width: 130px;
  margin: 0;

  &__button {
    position: relative;

    .icon-cp {
      display: none;
    }

    &::after {
      position: absolute;
      color: var(--white);
      font-size: 21px;
      font-weight: 600;
      line-height: initial;
      content: '+';
    }
  }
}

.header-logo .icon-logo {
  display: flex;
}

.header-nav {
  .btn-icon {
    height: 38px;
    width: 38px;
    margin-left: 10px;
    border: var(--border-1);
  }

  .icon-search {
    width: 15px;
    height: 16px;
  }

  .icon-user {
    width: 13px;
    height: 16px;
  }
}

.header-bar--geocomply {
  height: 68px;
  max-height: 68px;

  @include breakpoint(400px) {
    padding: 10px;
  }

  .header-bar__icon {
    --icon-bg: transparent;
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }

  .header-bar__title {
    --title-color: var(--white);
    line-height: 1;
    letter-spacing: -0.05em;

    @include breakpoint(360px) {
      font-size: 11px;
    }

    @include breakpoint-min(400px) {
      font-size: 14px;
    }
  }

  .header-bar__subtitle {
    --subtitle-color: var(--color-2);

    @include breakpoint-min(360px) {
      font-size: 11px;
    }

    @include breakpoint-min(400px) {
      font-size: 12px;
    }
  }

  .header-bar__info {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .header-bar__btn {
    flex-grow: 0;

    .btn {
      max-width: unset;
      padding: 0;
      margin: 0;
      letter-spacing: -0.05em;

      @include breakpoint-min(400px) {
        font-size: 14px;
      }
    }
  }
}

.deposit-bar {
  --deposti-bar-bg: var(--bg-accent);
  --slide-subtitle-color: var(--white);
  --btn-bg: var(--white);
  --btn-color: var(--bg-accent);
  --btn-radius: 8px;

  .icon-cp {
    svg {
      fill: var(--white);
    }
  }
}

.deposit-bar {
  &__title {
    font-size: 12px;
  }

  &__subtitle {
    font-size: 12px;
  }
}
.header {
  .inbox-indicator {
    &:after {
      top: calc(0% - 4px);
      right: calc(0% - 4px);
    }
  }
}
