@import 'sportsbook/mobile-web/letsbetmd/theme/variables';
@import 'sportsbook/mobile-web/shared/pages/profile';

:root .profile {
  --active: var(--basic-color);
  --bg-1: #29292e;
  --border-1: 1px solid #313137;
  --border-2: 1px solid #313137;
  --radius: var(--radius-1);
  --radius-2: var(--radius-1);
  --radius-3: var(--radius-1);

  .btn {
    --btn-fw: 700;
    --btn-ls: var(--ls-1);
    --btn-bg-1: var(--basic-color);
  }

  .user {
    --user-header-border: 1px solid var(--body);
  }

  .profile-breadcrumb {
    --breadcrumb-border: var(--border-1);
    --back-width: 38px;
    --back-height: 38px;
  }

  .profile-message {
    --message-color: #6c99f9;
    --message-bg: #112a63;
  }

  .limits {
    --title-fs: 13px;
    --limit-entry-px: 15px;
  }
}
