body.betslip-opened {
  overflow: hidden;
}

.betslip-cp {
  bcf-datepicker {
    display: block;
  }

  .vr-keyboard {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    width: 100%;
    max-width: 195px;
    padding-left: 12px;
    padding-right: 42px;
    background-color: var(--vk-bg, #0e0e10);
    color: var(--vk-color, #79798b);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.02em;
    border-radius: var(--betslip-radius);
    border: 1px solid var(--vk-border, #323239);
    outline: none;

    &__currency {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: var(--currency-color, #79798b);
      text-transform: uppercase;
    }

    &__value {
      overflow: hidden;
      @include ellipsis;
      width: 100%;
      color: var(--value-color, #fff);
    }

    &.has-stake {
      max-width: 100%;
      width: 100%;
      height: 48px;
      font-size: 15px;
      font-weight: 800;
    }
  }

  .is-outdated {
    text-decoration: line-through;
  }

  .sb-bet-status {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 47px;
    column-gap: 15px;
    min-height: 35px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;

    &--multi {
      margin: 0;
    }

    .icon-share {
      display: inline-flex;
      width: 20px;
      fill: var(--share-fill, var(--fill-3));
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--radius-1);

      &--big {
        padding: 0 12px;
        background-color: var(--status-bg);
      }

      &--small {
        justify-content: center;
        max-height: 40px;
        border: var(--border-1);
        border-color: var(--share-border-color);
      }
    }

    &__value {
      color: var(--status-color, var(--color-1));
      text-align: right;
      line-height: 1.1;
    }

    &.cashout-not-available {
      color: var(--status-color);
    }
  }

  .betslip-bottom-bar .sb-bet-status {
    grid-template-columns: 1fr;
  }

  .sb-game-info {
    display: flex;
    align-items: center;
    gap: 4px;
    letter-spacing: -0.02em;
    flex-shrink: 0;

    &__data {
      color: var(--info-color, #79798b);
      font-size: 12px;
      font-weight: 400;
      @include ellipsis;
    }

    .countdown-timer {
      display: inline-block;
      padding-right: 4px;
    }
  }

  .indicator-live {
    height: 20px;
    padding: 8px;
    margin: 0;
    font-size: 11px;
    font-weight: 600;
  }
}

.live-agent {
  .betslip { 
    padding-bottom: var(--space-9);
  }
  .betslip-btn-container {
    border-top: none;
  }
}

.betslip {
  overflow: auto;
  z-index: 150;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: var(--safe-area-top);
  padding-bottom: var(--safe-area-bottom);
  padding-left: var(--safe-area-left);
  padding-right: var(--safe-area-right);
  background-color: var(--betslip-bg);

  @include breakpoint-min(1200px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
  }

  &::after {
    pointer-events: none;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--betslip-bg);
    content: '';
  }

  &-nav,
  &-scroll-content {
    padding: 0 6px 12px;

    @include breakpoint-min(360px) {
      padding: 0 12px 12px;
    }

    @include breakpoint-min(400px) {
      padding: 0 14px 12px;
    }
  }

  .btn-redirect {
    z-index: 1;
    position: relative;
    height: 30px;
    margin: 25px 12px 0;
    color: #838383;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: -0.6px;
    border-radius: var(--radius-1);

    &::after {
      content: '';
      z-index: 1;
      pointer-events: none;
      position: absolute;
      left: 0;
      bottom: 0.5px;
      transform: translateY(100%);
      height: 25px;
      width: 100%;
      background: linear-gradient(to bottom, var(--body) 20%, rgb(0 0 0 / 0%));
      transition: opacity 0.3s ease;
    }
  }

  .sb-icon-state {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    border: var(--border);
    border-radius: 50%;

    &-failure {
      border-color: var(--failure);
      background-color: var(--failure);
      background-image: url('/img/new-version/icons/black/failure-empty.svg');
    }

    &-success {
      background-color: var(--success);
      background-image: url('/img/new-version/icons/black/tick-empty.svg');
    }
  }

  .sb-icon-lock {
    height: 32px;
    width: 32px;
    margin-right: 15px;
    background-color: var(--lock-bg, #da1616);
    background-size: 15px;
    border-radius: 3px;
  }

  .betslip-buttons {
    display: flex;
  }

  .betslip-top-sticky {
    z-index: 10;
    position: sticky;
    top: 0;
    background-color: var(--betslip-bg);
  }

  .betslip-heading {
    z-index: 13;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 12px;

    &__icon {
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      &--right {
        right: 12px;
      }

      &--left {
        left: 12px;
      }

      .icon-cp {
        display: flex;
        width: 16px;
      }
    }

    &__logo {
      display: flex;
      align-items: center;
      height: 40px;
    }

    .icon-logo {
      height: 20px;
    }

    .btn {
      position: relative;
      width: 46px;
      height: 33px;
      min-width: auto;
      margin-right: 0;
      flex-grow: initial;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.025em;

      .icon-cp {
        display: flex;
      }

      .icon-close {
        fill: var(--close-fill, var(--fill-brand));
        width: 13px;
      }

      .icon-settings {
        width: 17px;
      }

      &-close {
        border-color: var(--close-border, var(--border-color));
      }

      &-my-bets {
        width: auto;
        padding: 0 15px;
      }
    }

    &__col {
      &.has-buttons {
        display: grid;
        justify-content: flex-end;
        grid-auto-flow: column;
        column-gap: 10px;
        flex-grow: 1;
      }

      &.has-logo {
        display: flex;
        flex-flow: column;
      }

      .icon-logo {
        width: 85px;
      }
    }

    &__title {
      margin-top: 3px;
      font-size: 11px;
      font-style: italic;
      font-weight: 700;
      letter-spacing: 0.5em;
      text-transform: uppercase;
    }
  }

  .sb-heading {
    z-index: 13;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 12px 6px 18px;
    margin: 0;
    background-color: var(--heading-bg, #000);

    @include breakpoint-min(360px) {
      padding: 12px 12px 18px;
    }

    @include breakpoint-min(400px) {
      padding: 12px 14px 18px;
    }

    .btn {
      position: relative;
      width: 46px;
      height: 33px;
      margin-left: 10px;
      min-width: auto;
      margin-right: 0;
      flex-grow: initial;
      border: var(--btn-border, var(--border-1));
      border-radius: var(--radius-1);

      &-close {
        border-color: var(--btn-border, var(--basic-color));
      }

      &-my-bets {
        width: auto;

        @include breakpoint-min(360px) {
          min-width: 85px;
        }

        @include breakpoint-min(400px) {
          min-width: 120px;
        }
      }

      &.is-active {
        border-color: var(--btn-border-active, var(--basic-color));
      }
    }

    &__title {
      font-weight: 800;
      letter-spacing: -0.05em;
      text-transform: uppercase;

      @include breakpoint(359px) {
        font-size: 16px;
      }
    }
  }
}

.betslip-cp .sb-bet-details {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  column-gap: 15px;
  margin-top: 15px;
  color: var(--color);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  span {
    margin-right: 2px;
    color: var(--name-color, var(--color));
  }
}

/* === BETSLIP SETTINGS  === */
.betslip-settings {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 12;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  padding: calc(76px + var(--safe-area-top)) 12px 12px;
  background-color: var(--settings-bg, var(--betslip-bg));
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  &.is-active {
    transform: translateX(0);
    transition: transform 0.15s linear;
  }

  .btn {
    position: sticky;
    bottom: 0;
    flex-shrink: 0;
    margin-top: auto;
  }

  .basic-checkbox {
    margin-bottom: 20px;

    &__state {
      overflow: hidden;
      justify-content: center;
      width: 33px;
      height: 33px;
      margin: 0;
      border: var(--border-2);
      appearance: none;
      border-radius: var(--checkbox-state-radius, var(--radius-1));
      transition: background-color 0.2s ease;

      &--circle {
        border-radius: 50%;
      }

      .icon-cp {
        z-index: 1;
        opacity: 0;
        display: block;
        width: 15px;
        height: 15px;
        transition: opacity 0.2s ease;

        path {
          fill: var(--tick-fill, var(--body));
        }
      }

      &.is-active {
        background-color: var(--checkbox-bg-active, var(--basic-color));
      }

      &.is-active .icon-cp {
        opacity: 1;
      }
    }

    &__title {
      margin-left: 10px;
      color: var(--checkbox-color, var(--color-1));
    }

    &__title span {
      color: var(--checkbox-color-active, var(--color-1));
    }
  }

  &__form {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  &__form .btn {
    height: 47px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__form .profile-headline-secondary {
    margin-bottom: 20px;
    color: var(--headline-color, var(--color-2));
    font-weight: 600;
    line-height: 1;
  }

  &__entry {
    margin-bottom: 30px;
  }
}

.betslip-nav {
  z-index: 4;
  position: relative;
  display: flex;
  gap: 8px;
  min-height: 40px;
  padding-bottom: 0;
  background-color: var(--nav-bg, var(--betslip-bg));
  box-shadow: 0 8px 4px 2px var(--nav-bg, var(--betslip-bg));

  .btn {
    z-index: 3;
    flex: 1;
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    --color: var(--ds-neutral-20, #cbcbd2);
    --bg: var(--ds-neutral-90, #1b1b1f);
  }

  .sb-counter {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    color: var(--counter-color, var(--color-1));
    background-color: var(--counter-bg);
    border-radius: 50%;
  }
}

.betslip-scroll-content {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--scroll-bg, var(--betslip-bg));

  &--my-bets {
    max-height: calc(100vh - 110px);
    padding-bottom: 50px;
  }
}

.betslip-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .btn {
    height: 40px;
    padding: 0 12px;
    color: var(--btn-color, #cbcbd2);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.02em;

    &:last-child {
      margin-left: auto;
    }
  }

  .icon-bin {
    display: flex;
    width: 12px;
    height: 12px;
    margin-left: 8px;
    fill: var(--bin-fill, #cbcbd2);
  }

  &__info {
    width: 100%;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }
}

.betslip-place {
  position: relative;
  margin-top: auto;
  padding: 12px;
  border-top: 1px solid var(--betslip-footer-border, #0e0e10);

  .betslip-terms {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .betslip-checkbox {
      margin-left: 15px;
    }

    &__data {
      display: flex;
      width: 50%;
      color: var(--terms-color, var(--place-color-2));
      font-size: 10px;
      font-weight: 600;
      letter-spacing: -0.025em;
      text-transform: uppercase;

      &:empty {
        width: 0;
      }

      &:empty + div {
        white-space: nowrap;
      }

      &:empty + div .betslip-checkbox {
        margin-left: 25px;
      }

      &:first-child {
        margin-right: 17px;
      }

      &:last-child {
        justify-content: flex-end;
      }

      &:only-child {
        margin: 0 0 0 auto;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      line-height: 1;
    }
  }

  .btn-ecoupon {
    margin-right: 10px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;

    &:disabled {
      opacity: 0.5;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .betslip-place-bet-button-cp,
  .betslip-e-betslip-cp {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;

    .btn {
      position: relative;
      width: 100%;
      height: 47px;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 14px;

      &--bg-success {
        background-color: var(--btn-success-bg, var(--bg-accent));
      }

      &--bg-error {
        margin-left: 10px;
        background-color: var(--btn-error-bg, var(--bg-1));
      }
    }

    .btn:disabled {
      opacity: 1;
      background-color: var(--btn-place-disabled, var(--basic-color-disabled));
      cursor: initial;

      &::after {
        opacity: 1;
      }
    }
  }

  .betslip-e-betslip-cp {
    min-width: fit-content;
    flex: 1;

    .btn:disabled {
      background-color: var(--ds-neutral-90);
      color: var(--ds-neutral-70);
    }
  }
}

/* ===  BETSLIP PLACE SUMMARY === */
.betslip-summary {
  display: flex;
  flex-flow: column;

  &__row {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__col {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:first-child) {
      margin-left: auto;
    }

    .vr-keyboard {
      margin-left: auto;
      max-width: 100%;
    }

    &--winnings-title {
      flex: 0 0 auto;
      margin-right: 16px;
    }

    &--winnings-value {
      flex-flow: row;
      flex: initial;
    }
  }

  &__badge {
    height: 20px;
    padding: 0 8px;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;

    &--success {
      background-color: var(--ds-success-100);
      color: var(--ds-success-50);
    }
  }

  &__title {
    color: var(--title-color, #fff);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--subtitle-color, var(--place-color-2));
    font-size: 10px;
    letter-spacing: -0.025em;
  }

  &__value {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    color: var(--value-color, var(--basic-color));
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.05em;
    white-space: nowrap;
  }

  &__tax {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    margin-left: 5px;
    font-size: 8px;
    line-height: 0;
    border: 1px solid;
    border-radius: 50%;

    &-info {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  &__outdated {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .is-outdated {
    overflow: hidden;
    flex-shrink: 0;
    color: var(--ds-neutral-50, #79798b);
    font-size: 13px;
    font-weight: 700;
    text-overflow: ellipsis;
  }
}

.betslip-summary-odds {
  position: relative;
  height: 24px;
  padding: 0 8px;
  margin-left: auto;
  background-color: var(--bet-odds, #323239);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 24px;
  text-align: center;
  border-radius: var(--bet-odds-radius, 8px);
}

.betslip-badges {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.betslip-boost-info {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  margin-bottom: var(--ds-space-1);
  background-color: var(--ds-success-100);
  color: var(--ds-success-50);
  font-size: 12px;
  letter-spacing: -0.02em;
  border-radius: var(--betslip-radius);
}

.bonus-config-cp {
  display: flex;
  flex-flow: column;
}

.betslip-bonus-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  color: var(--ds-neutral-50, #79798b);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.betslip-bonus-switch {
  display: flex;
  align-items: stretch;
  height: 40px;
  padding: 2px;
  margin-bottom: 12px;
  border: 1px solid var(--switch-border, #323239);
  border-radius: var(--betslip-radius);

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: var(--ds-neutral-20, #cbcbd2);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.02em;
    border-radius: 10px;

    &.is-active {
      color: var(--white);
      background-color: var(--ds-netural-80, #323239);
    }
  }
}

/* === BETSLIP BONUS  === */
.betslip-bonus {
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: -0.6px;
  border-top: 1px solid var(--basic-color);

  &__title,
  &__action {
    color: var(--primary-green);
  }

  &__value {
    display: inline-block;
    margin-left: 5px;
    color: var(--white);
  }
}

/* === BETSLIP OPTIONS  === */
.betslip-options {
  padding: 13px 0;

  .betslip-buttons {
    margin-bottom: 15px;
  }

  .betslip-summary__data {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    flex: 1 1 100%;

    &:first-child {
      margin-right: 10px;
    }
  }
}

/* === BETSLIP FILTER  === */
.betslip-bottom-bar {
  z-index: 1;
  position: absolute;
  bottom: 20px;
  left: 12px;
  right: 12px;

  .sb-bet-status {
    margin: 0;
  }

  .btn {
    min-height: 35px;
    width: 100%;
    background-color: var(--body);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
    border: 2px solid var(--line-grey);
    border-radius: var(--radius-1);
    box-shadow: 0 0 25px 13px rgb(16 14 17 / 100%);

    span {
      display: inline-block;
      padding: 0 1px;
      color: var(--basic-color);
    }
  }
}

/* === BETSLIP BET WRAPP - WPAPS BETS FROM SAME EVENT === */
.betslip-entry {
  position: relative;

  &.is-empty p {
    margin: 8px 0;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-align: center;
  }

  &:first-child .betslip-error--outside {
    margin-top: 0;
  }
}

.betslip-bet {
  position: relative;
  margin-bottom: 4px;

  .vr-keyboard {
    width: 50%;
    min-width: 180px;
  }

  &__content {
    position: relative;
    display: flex;
    flex-flow: column;
    gap: 12px;
    background-color: var(--bet-bg, #232329);
    padding: 12px;
    border-radius: var(--radius-1);

    &::after {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--bet-cover, var(--betslip-bg));
      border-radius: var(--radius-1);
      transition: opacity 0.25s ease;
      content: '';
    }
  }

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    &:first-child {
      position: static;
    }

    &.has-keyboard {
      position: relative;
      padding-top: 12px;
      &::before {
        position: absolute;
        top: 0;
        left: -12px;
        right: -12px;
        height: 1px;
        background-color: var(--betslip-bg);
        content: '';
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
  }

  &__odds {
    position: relative;
    height: 24px;
    padding: 0 8px;
    margin-left: auto;
    background-color: var(--bet-odds, #323239);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 24px;
    text-align: center;
    border-radius: var(--bet-odds-radius);

    &::after,
    &::before {
      opacity: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--odds-increase);
      pointer-events: none;
      border-radius: var(--bet-odds-radius);
      box-sizing: border-box;
    }

    &::before {
      border-color: var(--odds-decrease);
    }
  }

  &__banker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: var(--ds-neutral-90, #1b1b1f);
    border-radius: 8px;

    &.is-active {
      background-color: var(--ds-brand-100);
      .icon-cp path {
        fill: var(--ds-brand-60);
      }
    }

    .icon-cp {
      width: 10px;
      height: 10px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .sb-icon-state {
      margin-left: 14px;
    }
  }
}
.betslip-event-entries.is-conflict,
.betslip-entry.is-suspended {
  overflow: hidden;
  opacity: 1;
  border-radius: var(--betslip-radius);
  .betslip-bet {
    margin-bottom: 1px;

    &__content {
      border-radius: 0;
    }
  }

  .has-keyboard {
    display: none;
  }
}

.betslip-entry.is-suspended:last-of-type,
.betslip-event-entries.is-conflict .betslip-entry:last-of-type {
  .betslip-bet {
    margin-bottom: 0;
  }
}

.betslip-bet.is-excluded,
.betslip-entry.is-suspended,
.is-conflict {
  .betslip-bet__content::after {
    pointer-events: initial;
  }

  .betslip-selection__market,
  .betslip-selection__name,
  .betslip-bet__col--teams,
  .betslip-bet__separator.has-score,
  .betslip-bet__odds,
  .vr-keyboard__value,
  .vr-keyboard__currency,
  .sb-game-info__data {
    color: var(--ds-neutral-70, #4a4a55);
    transition: color 0.2s ease;
  }

  .vr-keyboard {
    background-color: var(--ds-netural-90);
  }

  .indicator-live {
    opacity: 0.3;
  }
}

.betslip-bet {
  &__row {
    &--selection {
      align-items: flex-start;
      margin-bottom: 4px;
    }
  }

  &__col {
    display: flex;
    letter-spacing: -0.02em;

    &--selection {
      align-items: flex-start;
    }

    &--action {
      align-items: center;
      gap: 13px;
    }

    &--teams {
      flex-wrap: wrap;
      color: var(--ds-neutral-50, #79798b);
      font-size: 12px;
    }
  }

  &__separator {
    padding: 0 4px;

    &.has-score {
      color: var(--score-color, #fff);
    }
  }

  .ds-icon-xmark {
    z-index: 3;
    position: relative;
  }
}

.betslip-selection {
  display: flex;
  flex-flow: column;
  gap: 2px;

  &__name {
    font-size: 14px;
    font-weight: 600;
  }

  &__market {
    color: var(--ds-neutral-50, #79798b);
    font-size: 12px;
    font-weight: 400;
  }
}

.betslip-bet-selection {
  display: grid;
  row-gap: 4px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.02em;

  &__entry {
    color: var(--bet-color-1, var(--color-1));

    &.has-result {
      display: flex;
      flex-flow: column;
      font-size: 12px;
      color: var(--bet-color-selection, #79798b);
    }
  }

  &__title::first-letter {
    text-transform: uppercase;
  }

  &__separator {
    display: inline-block;
    padding: 0 2px;
  }
}

.betslip-odds {
  padding: 7px 8.5px;
  background-color: var(--body);
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.05em;
  border-radius: var(--radius-1);
}

.sb-betslip-system-list,
.betslip-system-bets {
  display: block;

  .sb-selection__list {
    padding-right: 45px;
  }
}

.betslip-system {
  display: flex;
  flex-flow: column;
  gap: 12px;
  padding: 12px;
  background-color: var(--system-bg, #232329);
  border-radius: var(--betslip-radius);

  .vr-keyboard {
    width: 140px;
  }
}

.betslip-system-entry {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__col {
    display: flex;
    align-items: center;

    &--input {
      gap: 8px;
    }
  }

  &__title,
  &__multiplier {
    font-size: 14px;
    font-weight: 600;
  }

  &__subtitle {
    color: #79798b;
  }

  &__number {
    font-size: 12px;
    font-weight: 400;
  }
}

.betslip-checkbox-small {
  z-index: 3;
  position: relative;
  display: flex;
  align-items: flex-start;
  letter-spacing: -0.02em;

  &__state {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    background-color: var(--checkbox-bg, #0e0e10);
    border: var(--checkbox-border, 1px solid #323239);
    border-radius: var(--checkbox-radius, 4px);
  }

  &__info {
    display: flex;
    flex-flow: column;
  }

  .icon-cp {
    opacity: 0;
    display: flex;
    width: 10px;

    path {
      fill: var(--tick-fill, #fff);
    }
  }

  &.is-active .icon-cp {
    opacity: 1;
  }
}

.betslip-checkbox {
  z-index: 3;
  position: relative;
  width: 43px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 14px;
  border-radius: 9px;
  background-color: var(--toggle-bg);
  transition: background-color 200ms ease-in-out;

  &::after,
  &__label {
    position: absolute;
    top: 50%;
    transform: translate(2px, -50%);
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: var(--toggle-bg-inside);
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
    content: '';
  }

  &.is-active {
    background-color: var(--toggle-bg-active, var(--basic-color));

    &::after {
      transform: translate(23px, -50%);
    }
  }
}

.betslip-warning {
  padding: 0 12px 12px;
  margin-bottom: 4px;
  background-color: var(--bet-bg, #232329);
  border-radius: 0 0 var(--betslip-radius) var(--betslip-radius);

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    padding: 8px 12px;
    background-color: var(--ds-warning-100, hsla(36, 100%, 40%, 0.15));
    color: var(--ds-warning-50, #ff9800);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: center;
    border-radius: var(--betslip-radius);
  }
}

.betslip-error {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
  padding: 8px 12px;
  min-height: 40px;
  color: var(--error-color, #ff2424);
  background-color: var(--error-bg, rgba(240, 0, 0, 0.1));
  border-radius: var(--betslip-radius);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-align: center;

  &--general {
    margin: 0;
  }
}

/**  GENERAL BETSLIP ERROR** NOT RELATED TO INDIVIDUAL BET  **/
.betslip-errors-list {
  display: block;

  .betslip-error {
    margin-top: 0;
  }
}

.betslip-bet.is-increased {
  .betslip-bet__odds::after {
    animation: rate-button-flash 4s linear forwards;
  }
}

.betslip-bet.is-decreased {
  .betslip-bet__odds::before {
    animation: rate-button-flash 4s linear forwards;
  }
}

.betslip-event-entries {
  position: relative;

  &.is-conflict .betslip-error--conflict {
    opacity: 1;
  }
}

/* === BETSLIP LIVE INFO - TRIGGER ON CLICK === */
.betslip-bet-live-info {
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px 15px;
  background: var(--bet-bg-trans);
  font-size: 13px;
  letter-spacing: -0.025em;
  border-radius: var(--radius-1);
  transition: opacity 0.25s ease-in-out;

  .sb-icon-exclamation {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 5px;
    background-color: var(--basic-color);
    background-size: 15px;
    border-radius: 3px;
  }

  &__content {
    opacity: 0;
    display: flex;
    align-items: flex-start;
    transition: opacity 0.22s ease 0.3s;
  }

  &__title {
    display: inline-block;
    margin-right: 3px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

/* === BETSLIP LIVE INFO - ACTIVE STATE === */
.betslip-bet.active-info {
  .betslip-bet-live-info,
  .betslip-bet-live-info__content {
    opacity: 1;
  }

  .betslip-bet__info {
    color: var(--inf-color-active, var(--basic-color));
  }
}

.betslip-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  span {
    color: #acacac;
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.05em;
  }
}

.betslip-entry-my-bets {
  display: block;
  padding: 12px;
  margin-bottom: 5px;
  background-color: var(--bet-bg, var(--bg-1));
  border-radius: var(--radius-1);

  .betslip-bet {
    margin-bottom: 0;
  }

  .betslip-bet:last-child {
    border: initial;
  }
}

.betslip-dropdown {
  .betslip-my-bets-dropdown-count-cp {
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
  }

  .betslip-bet {
    padding: 15px 0;
    border-bottom: var(--dropdown-border, var(--border-1));

    &:last-child {
      border: initial;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__status {
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    width: 100%;
    margin-right: 10px;

    .sb-icon-state {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: #c1c1c1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
    text-transform: uppercase;

    .icon-arrow-down {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-left: 8px;
      transition: transform 0.33s linear;
      path {
        fill: #c1c1c1;
      }
    }
  }

  &__core {
    display: none;
  }

  &.is-open {
    .betslip-dropdown__core {
      display: block;
    }

    .icon-arrow-down {
      transform: rotateX(180deg);
    }
  }
}

.betslip-my-bets-wrapper {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  max-height: 100%;
}

.betslip-cp .logged-only {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 14px;
  padding-top: calc(63px + env(safe-area-inset-top));
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  .btn {
    flex-grow: 1;
    width: 50%;
    height: 47px;
    font-weight: 700;

    &:first-child {
      margin-right: 20px;
    }
  }

  .icon-cp {
    display: inline-block;
    margin-top: auto;
    width: 80px;
    margin-bottom: var(--space-5);
    background-position: bottom;
  }

  &__title {
    max-width: 350px;
    margin: 0 auto 15px;
    color: var(---white);
    font-size: 18px;
    font-weight: 900;
    letter-spacing: -0.05em;
    text-align: center;
  }

  &__buttons {
    position: relative;
    display: flex;
    margin-top: auto;
    width: 100%;
  }
}

.betslip-my-bets-list {
  .sb-empty {
    margin-top: 25px;

    &__title {
      color: var(--basic-color);
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: -0.05em;
    }
  }

  .betslip-summary {
    margin-top: 12px;
    text-transform: capitalize;

    &__title {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__value {
      text-transform: uppercase;
    }
  }
}

.betslip-my-bets-systems {
  overflow: hidden;
  z-index: 14;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background: var(--system-bg, #000);

  &::before,
  &::after {
    left: 0;
    width: 100%;
    content: '';
  }

  &::before {
    position: fixed;
    top: 0;
    height: 100%;
    background: var(--system-bg, #000);
  }

  &::after {
    z-index: 14;
    position: absolute;
    bottom: 0;
    height: 30px;
    background: linear-gradient(to top, var(--system-bg, #000) 35%, rgb(16 14 17 / 0%));
    content: '';
  }

  .sb-heading {
    padding: 12px 15px 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      height: 20px;
      width: 100%;
      background: linear-gradient(to bottom, var(--system-bg, #000) 35%, rgb(16 14 17 / 0%));
      content: '';
    }

    &__title {
      flex-wrap: wrap;
      line-height: 1.2;

      span {
        margin-left: 3px;
        color: var(--id-color, var(--basic-color));
      }
    }

    .icon-close {
      fill: var(--close-fill, var(--fill-brand));
      width: 13px;
    }
  }

  .betslip-headline {
    text-transform: uppercase;
  }

  .betslip-bet {
    padding-top: 15px;
  }

  .betslip-my-bets-list {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    z-index: 1;
    position: relative;
    padding: 18px 15px 30px;
  }
}

/* === BETSLIP FILTER ON HALF-SIZE MODAL  === */
.betslip-filter {
  color: var(--white);
  letter-spacing: -0.05em;
  text-transform: uppercase;

  .sb-virtual-keyboard {
    width: 195px;

    &__input {
      justify-content: flex-start;
      align-items: center;
      height: 36px;
      outline: none;
      width: 100%;
      color: var(--basic-color);
      font-size: 13px;
      font-weight: 800;
      letter-spacing: -0.05em;
      border: 2px solid var(--body-color);
      border-radius: var(--radius-primary);
    }

    &__input,
    &__input::placeholder {
      border-color: #282728;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }

  &__entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &:first-child {
      margin-bottom: 20px;
      flex-wrap: wrap;

      .betslip-filter__title {
        margin: 0 5px 5px 0;
      }
    }
  }

  &-nav {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &__item {
      margin-left: 12px;
      font-size: 13px;
      font-weight: 700;

      &:first-child {
        margin-left: 0;
      }

      &.is-active {
        color: var(--basic-color);
        text-decoration: underline;
      }
    }
  }
}

/* === BETSLIP HALF-SIZE MODALS  === */
.betslip-cp,
.fast-bet-cp {
  @keyframes show-modal-half-size {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes show-modal-half-size-content {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* === BETSLIP TAXES ON HALF-SIZE MODAL  === */
  .betslip-taxes {
    .btn {
      height: 40px;
      width: 100%;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.025em;
    }

    &__list {
      display: flex;
      flex-flow: column;
      gap: 8px;
      margin-bottom: 16px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.02em;
    }

    &__title {
      display: block;
      color: var(--ds-neutral-40, #9595a2);

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__value {
      font-weight: 700;
    }
  }

  .sb-modal {
    &__share-link {
      display: block;
      margin: 28px 0;
      color: var(--basic-color);
      font-size: 18px;
      font-style: italic;
      font-weight: 800;
      line-height: 1;
      letter-spacing: -0.05em;
      word-break: break-all;
    }

    .ebetslip-formula,
    .betslip-taxes__rules {
      margin-bottom: 16px;
      color: var(--ds-neutral-40, #9595a2);
      font-size: 11px;
      font-weight: 600;
      letter-spacing: -0.025em;
      text-transform: initial;
    }

    &--taxes.is-visible {
      z-index: 201;
    }

    &--half-size {
      display: none;
      opacity: 0;
      z-index: 200;
      flex-flow: column;
      padding: 0;
      background-color: transparent !important;

      .sb-heading {
        margin-bottom: 16px;
        line-height: 1.2;

        &__action {
          color: #c1c1c1;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: -0.025em;
          text-transform: uppercase;
        }
      }

      .sb-modal__header {
        margin-top: auto;
      }

      .sb-modal__content {
        z-index: 2;
        margin: auto 0 0;
        transform: translateY(100%);
        padding: 10px;
        padding-left: calc(10px + var(--safe-area-left));
        padding-right: calc(10px + var(--safe-area-right));
        background-color: var(--ds-neutral-100, #0e0e10);
        border-radius: var(--betslip-radius) var(--betslip-radius) 0 0;
        animation-delay: 0ms !important;
        animation-duration: 0.45s !important;
      }

      .sb-modal__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(39 39 45 / 90%);
      }

      &.is-visible {
        display: flex;
        animation: show-modal-half-size 0.2s 0.1s ease-in-out forwards;

        .sb-modal__content {
          animation: show-modal-half-size-content 0.3s 0.4s ease-in-out forwards;
        }
      }
    }

    &--keyboard {
      .sb-modal__content {
        padding-bottom: 0;
      }

      .betslip-keyboard {
        --keyboard-bg: var(--ds-neutral-100, #0e0e10);
      }
    }

    &--filter,
    &--sharebet,
    &--ebetslip {
      .btn--bg-primary {
        height: 32px;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.025em;

        span {
          color: var(--basic-color);
        }
      }
    }

    &--ebetslip {
      .ebetslip-id {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
        font-size: 13px;
        letter-spacing: -0.05em;
        text-transform: uppercase;

        span {
          color: var(--basic-color);
          font-weight: 600;
        }
      }
    }

    &--sharebet {
      .btn {
        height: 32px;
      }

      .btn--bg-light-grey {
        margin: 0 auto 10px;
        padding: 0 15px;
        background: #818181;
        font-weight: 600;
      }

      .sb-modal__text {
        font-size: 14px;
        letter-spacing: -0.025em;
      }
    }

    &--filter {
      .sb-heading__action {
        white-space: nowrap;
      }

      .sb-modal__content {
        overflow: initial;
      }

      &.active-datepicker {
        .ic-datepicker-input,
        .betslip-filter__title,
        .betslip-filter-nav {
          display: none;
        }

        .ic-datepicker-container {
          padding: 0;

          &.ic-open {
            margin-bottom: 10px;
          }
        }

        .betslip-filter__entry {
          margin-bottom: 0;
        }

        .sb-virtual-keyboard {
          width: 100%;

          &__input {
            border: initial;
            height: auto;
          }
        }
      }
    }

    &--taxes {
      .sb-heading {
        min-height: 40px;

        &__title {
          display: block;
          width: 100%;
          margin: 0;
          font-size: 16px;
          font-style: normal;
          text-align: center;
          text-transform: initial;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

body[class*='iPhone'] {
  .betslip-cp .betslip {
    &::after {
      display: none;
    }
  }

  .betslip-cp .betslip-settings {
    padding-top: calc(65px + env(safe-area-inset-top));
    padding-bottom: calc(12px + env(safe-area-inset-bottom));
  }
}

/* === BETSLIP MODAL COUPON  === */
.sb-modal {
  &--coupon {
    .sb-modal__header {
      z-index: 1;
      position: relative;
      box-shadow: 0 0 7px 21px var(--body-color);
    }

    .sb-heading {
      margin-bottom: 0;
    }

    .sb-modal__content {
      overflow: hidden;
      margin-bottom: 0;
    }

    .sb-modal__action {
      position: sticky;
      bottom: 0;
      margin: 8px 0 0;
      background: var(--body-color);
      box-shadow: 0 0 10px 10px var(--body-color);
    }
  }

  .sb-heading .logo {
    height: 33px;
    width: 130px;
  }

  .coupon {
    overflow: scroll;
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 25px;
    transform: rotate(0deg);
    color: var(--white);
    border-radius: 3px;

    &-footer {
      position: sticky;
      bottom: 0;
      padding-top: 5px;
      background-color: var(--body-color);
      box-shadow: 0 0 5px 5px var(--body-color);

      .btn {
        width: 100%;
      }
    }

    .has-single .betslip-bet {
      padding: 0;
    }

    .has-combinations .betslip-bet {
      padding: 20px 0;
      border-bottom: 2px solid var(--body-color);
    }
  }

  .sb-icon-state {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid var(--basic-color);
    border-radius: 50%;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: '';
    }

    &-failure {
      border-color: #cd0000;
      background-color: #cd0000;

      &::after {
        background-image: url('/img/new-version/icons/black/failure-empty.svg');
      }
    }

    &-success {
      background-color: var(--basic-color);

      &::after {
        background-image: url('/img/new-version/icons/black/tick-empty.svg');
      }
    }
  }

  .betslip-summary {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  .sb-bet-status {
    color: var(--white);
    text-transform: uppercase;
  }

  .sb-bet-details {
    margin-top: 15px;
  }
}

//Bonus Configurator
.betslip-actions {
  .bonus-config-cp {
    display: grid;
    row-gap: 10px;
    width: 100%;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.06em;

    &:empty {
      display: none;
    }

    .betslip-terms__data {
      margin-top: 30px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }
  }

  .betslip-boost {
    &__title {
      margin-right: 5px;
      color: var(--boost-color);
    }

    &__odds {
      display: flex;
      padding: 0 5px;
      color: var(--white);

      span {
        margin-left: 5px;
        color: #636363;
      }
    }
  }

  .betslip-bonus-picker {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .betslip-checkbox.is-active {
      background-color: #93939f;
    }

    &__entry {
      display: flex;
      align-items: center;

      &.has-choice {
        margin-left: auto;
        font-size: 11px;
        text-transform: uppercase;
      }

      .betslip-checkbox {
        margin: 0 10px;
      }
    }

    &__option {
      transition: color 0.12s ease;

      &.is-active {
        color: var(--basic-color);
      }
    }
  }
}
