@import 'shared/mobile-web/lvbet/left-menu/left-menu';
@import 'shared/mobile-web/lvbet/right-menu/right-menu';

.side-menu__links .icon-cp,
.right-menu__links .icon-cp {
  width: 20px;
  height: 20px;
}

.right-menu__links .icon-logout,
.right-menu__links .icon-user {
  width: 16px;
  height: 16px;
}

.dropdown {
  display: block;
  position: relative;
  height: 41px;
  transition: all 0.3s ease;
  user-select: none;
  cursor: pointer;

  &.is-active {
    .dropdown__list {
      display: block;
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: var(--selected-bg, var(--bg-1));
    border-radius: var(--dropdown-radius, var(--radius-1));

    li {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      padding: 5px 0 5px 10px;
      font-size: 14px;
      font-weight: 600;
      color: var(--selected-color, var(--white));
    }

    .icon-cp {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 17px;

      path {
        fill: var(--arrow-fill);
      }
    }

    .icon-double-arrow {
      position: absolute;
      top: 50%;
      left: 10px;
      //transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }


  &__list {
    display: none;
    position: absolute;
    z-index: 20;
    overflow-y: auto;
    max-height: 220px;
    bottom: -2px;
    left: 0;
    transform: translateY(calc(100%));
    width: 100%;
    padding: 8px 0;
    background-color: var(--dropdown-bg, var(--bg-1));
    border-radius: var(--dropdown-radius, var(--radius-1));

    li {
      margin: 0;
    }

    li a {
      display: flex;
      align-items: center;
      height: 41px;
      padding: var(--space-2) 0 var(--space-2) var(--space-2);
      font-size: 14px;
      font-weight: 600;
      color: var(--dropdown-color, var(--white));
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--outline .dropdown__selected {
    background-color: var(--selected-bg, var(--body));
    border: var(--selected-border, var(--border-1));

    .icon-cp path {
      fill: var(--arrow-selected-fill, #313137);
    }
  }

  .flag-icon {
    width: 20px;
    height: 20px;
    margin-right: var(--space-2);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.dropdown--sidebar {
  width: 100%;
  height: 40px;

  &.dropdown--outline {
    .dropdown__selected {
      .icon-cp path {
        width: 13px;
        height: 7px;
        fill: #79798B;
      }
    }
  }

  .dropdown__selected {
    border-radius:  var(--dropdown-border-radius, var(--radius-1));
    background-color: var(--dropdown--sidebar-bg, var(--bg-primary));
    span {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.02em;
    }
  }

  .dropdown__selected-rate-switcher {
    span {
      padding-left: 24px;
    }
  }

  .flag-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .dropdown__list {
    border-radius: var(--dropdown-border-radius, var(--radius-1));
    a {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.02em;
      border-radius: 12px;
    }
  }
}

.side-menu .new-indicator {
  width: auto;
  margin-left: auto;
  padding: 3px 10px;
  color: var(--white);
  background-color: var(--red-3);
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
}

//TEMP
.side-menu::after {
  display: none;
}

.side-menu {
  &__inner-shadow::after {
    left: -5px;
  }
}

.side-menu__message {
  --btn-message-bg: var(--bg-brand);
  --btn-message-bg-hover: var(--brand-50);
  --btn-message-svg-bg1: var(--neutral-dark-2);
  --btn-message-svg-bg2: var(--neutral-dark-0);
  --btn-message-svg-bg-hover: var(--neutral-dark-3);
}
