@import 'sportsbook/mobile-web/shared/components/basic';

html {
  background-color: var(--body);
}

body.js-cookie-visible {
  --top-space-height: 40px;
}

body.has-header-bar {
  --top-space-height: 56px;
}

body.has-header-bar--geocomply {
  --top-space-height: 68px;
}

body.active-fastbet {
  --bottom-space-height: 75px;
}

body {
  padding-top: calc(95px + var(--top-space-height) + var(--safe-area-top));
  padding-bottom: calc(66px + var(--bottom-space-height));
  color: var(--white);
  background-color: var(--body);
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  outline: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &.home-page {
    padding-top: calc(125px + var(--top-space-height) + var(--safe-area-top));
  }

  &.breadcrumbs-active {
    padding-top: calc(165px + var(--top-space-height) + var(--safe-area-top));
  }

  &.payments-subpage {
    padding-top: calc(88px + var(--top-space-height) + var(--safe-area-top));
    padding-bottom: 0;

    @include breakpoint-min(768px) {
      padding-top: calc(101px + var(--top-space-height));
    }

    .main-nav {
      display: none;
    }
  }

  &.no-breadcrumb {
    padding-top: calc(75px + var(--top-space-height));
  }
}

* {
  outline: none;
  box-sizing: border-box;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: var(--white);
}

.is-hidden {
  display: none !important;
}

.is-highlighted {
  color: var(--basic-color);
}

.main-site-container {
  overflow: hidden;
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  @include mobile-side-gutter(14px);

  &.has-breadcrumb-headline {
    overflow: initial;
  }
}

.basic-headline {
  display: flex;

  .sb-icon {
    display: inline-block;
    height: 24px;
    width: 27px;
    flex-shrink: 0;
    margin-right: 10px;
    background-size: contain;
  }

  .btn {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
    border: 2px solid var(--white);
    border-radius: var(--radius-primary);
  }

  &__title {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;
    color: var(--white);
    font-size: 18px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__content {
    flex-shrink: 0;
  }

  &__buttons {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    display: flex;
    margin-left: auto;
  }
}

.toggle-inline {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
  flex-shrink: 0;
  margin-left: 14px;
  border-radius: 20px;
  background-color: #3e3e46;
  transition: background-color 200ms ease-in-out;

  &::after {
    position: absolute;
    top: 50%;
    transform: translate(3px, -50%);
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: var(--white);
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
    content: '';
  }

  &.is-active {
    background-color: var(--bg-accent);

    &::after {
      transform: translate(17px, -50%);
    }
  }

  &--big {
    width: 50px;
    height: 24px;
    margin: 0 15px 0 0;

    &::after {
      width: 20px;
      height: 20px;
    }

    &.is-active::after {
      transform: translate(28px, -50%);
    }
  }
}

.download-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13px 14px;
  background-color: var(--primary-color);

  &__col {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -0.05em;
    text-transform: uppercase;

    span {
      color: var(--basic-color);
    }
  }

  .btn {
    height: 32px;
    padding: 0 13px;
    background-color: var(--white);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-transform: initial;
    border-radius: var(--radius-primary);
  }
}

.header-bar {
  z-index: 13;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 58px;
  max-width: 1200px;
  padding: 12px;
  background: var(--white);
  @include radius-bottom(var(--radius-1));

  @include breakpoint-min(360px) {
    padding: 12px 16px;
  }

  @include breakpoint-min(1200px) {
    left: 50%;
    transform: translateX(-50%);
  }

  .btn {
    height: 34px;
    max-width: 150px;
    flex-grow: 1;
    margin: 0 8px;
    padding: 0 20px;
    font-size: 12px;
    font-weight: bold;
    border-radius: var(--radius-1);
    text-transform: initial;

    @include breakpoint-min(360px) {
      margin: 0 15px;
    }

    @include breakpoint-min(768px) {
      margin: 0 30px;
    }
  }

  .sb-icon {
    background-position: center;
    background-repeat: no-repeat;
  }

  &__icon {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    margin-right: 10px;
    background-color: var(--body);
    background-size: 25px;
    border-radius: var(--radius-1);
  }

  &__info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-self: stretch;
    margin-bottom: 3px;
    line-height: 1;
  }

  &__title {
    color: #000;
    font-size: 12px;
    font-weight: 800;
  }

  &__subtitle {
    color: #000;
    font-size: 10px;
    font-weight: 600;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin-left: auto;
  }

  .sb-icon-close {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
  }
}

.breadcrumb-headline {
  position: sticky;
  top: calc(75px + var(--top-space-height));
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: var(--body);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  box-shadow: 0 0 5px 11px rgb(17 15 17);

  &__back {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: var(--body) url('/img/new-version/icons/white/chevron-left.svg') no-repeat center/14px;
  }
}

.no-content {
  --empty-color: var(--white);
  --empty-fill: var(--neutral-light-3);
}

.choice-separator,
.sb-choice-separator {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  background-color: var(--body);
  color: var(--black);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.05em;
  border-radius: var(--radius-circle);
}

.csb-loader {
  z-index: 1001;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  animation: show-payments-loader 0.3s ease forwards;

  .sb-icon {
    opacity: 0;
    position: absolute;
    display: block;
    height: 50px;
    width: 50px;
    background-size: 100%;

    &.football {
      animation: loader-football 6s ease-in-out infinite;
    }

    &.volleyball {
      animation: loader-volleyball 6s ease-in-out infinite;
    }

    &.basketball {
      animation: loader-basketball 6s ease-in-out infinite;
    }
  }

  &-message {
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &-content {
    position: relative;
    width: 50px;
    height: 80px;

    &::before {
      position: absolute;
      top: 55px;
      left: 0;
      width: 50px;
      height: 3px;
      background: var(--basic-color-always);
      animation: loader-before 2s ease-in-out infinite;
      content: '';
    }

    &::after {
      position: absolute;
      top: 56px;
      left: -25px;
      width: 100px;
      height: 1px;
      border-bottom: 1px var(--grey-5) dashed;
      content: '';
    }
  }

  &.is-loaded {
    display: none;
  }
}

.bullet-loader {
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--bg-accent);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: animation-loader-1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: animation-loader-2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: animation-loader-2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: animation-loader-3 0.6s infinite;
    }
  }
}

.cdk-overlay-container {
  z-index: 200 !important;
  top: auto !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  height: auto !important;

  &.sb-snackbar-container {
    bottom: 0 !important;
    z-index: 200 !important;
  }

  snack-bar-container {
    padding: 14px !important;
  }

  .mat-snack-bar-container {
    background: rgb(0 0 0 / 95%);
    text-align: center;
  }
}

.cdk-overlay-container simple-snack-bar,
.offline-notification {
  display: block;
  width: 100%;
  padding: 10px;
  color: var(--white);
  background-color: var(--red-3);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  border-radius: var(--radius-primary);
}

.offline-notification {
  z-index: 200;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
}

.is-highlighted {
  color: var(--basic-color);
}

//fix landscape mode deadzone area
@media screen and (min-width: 320px) and (orientation: landscape) {
  body.betslip-opened,
  body.modal-open {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

body[class*='iPhone'] {
  padding-top: calc(149px + var(--top-space-height));
  padding-bottom: calc(66px + var(--safe-area-bottom));

  &.payments-new.is-logged {
    padding-top: calc(75px + var(--top-space-height) + var(--safe-area-top));
  }

  .cdk-overlay-container,
  .offline-notification {
    bottom: calc(0px + var(--safe-area-bottom)) !important;
  }
}

.ripple {
  overflow: hidden;
  position: relative;

  &.is-inside {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;

    .ripple__element {
      z-index: 1;
    }
  }

  &.circle {
    border-radius: var(--radius-circle);
  }

  &__element {
    position: absolute;
    transform: scale(0);
    background-color: rgb(255 255 255 / 70%);
    border-radius: 50%;

    &.is-active {
      animation: ripple 600ms linear;
    }
  }
}
