.odds {
  --odds-value-color: #b7b7c2;
}

.is-suspended {
  opacity: 0.2;
}

.app-container.has-odds-table {
  overflow: initial;
}

.odds-table {
  display: grid;
  row-gap: var(--space-5);
  margin-bottom: 30px;

  &__block {
    display: grid;
    row-gap: var(--space-4);
  }

  &__entry {
    display: block;
    padding: 10px;
    background-color: var(--odds-game-bg, #1b1b1f);
    overflow: hidden;
    border-radius: var(--radius-1);
  }
}

.esport .odds-table {
  &__entry {
    margin-bottom: var(--space-3);
  }
}

.odds-grid-outer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr var(--odds-data-width, 188px);

  @include breakpoint-min(768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.odds-grid-col {
  display: grid;
  column-gap: var(--odds-gap, 8px);

  &.single-game-odds {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  @include breakpoint-min(768px) {
    column-gap: var(--odds-gap, 10px);
  }
}

.game-entries {
  display: grid;

  &__group {
    display: grid;
    row-gap: var(--space-3);
    margin-top: var(--space-4);
  }

  .is-excluded ~ div {
    margin-top: 0;
  }
}

.single-game {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;

  &-data {
    display: flex;
    justify-content: space-between;
  }
}

.single-game-participants {
  overflow: hidden;
  display: grid;
  align-content: center;
  grid-gap: var(--teams-gap, 3px);
  width: 100%;
  padding-right: 10px;
  color: var(--team-color, var(--color-1));

  &__entry {
    overflow: hidden;
    position: relative;
    display: block;
    align-self: flex-end;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.has-outrights .single-game-participants__entry {
  white-space: initial;
  text-overflow: initial;
}

.single-game-score {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  flex-shrink: 0;
  padding-right: var(--space-2);

  &__content {
    overflow: auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-right: var(--space-2);

    &.initiative-first,
    &.initiative-second {
      padding-left: var(--space-3);
    }

    &::after,
    &::before {
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 36px;
      width: 5px;
      height: 5px;
      background-color: #dfff4f;
      border-radius: 50%;
      transition: opacity 0.3s ease;
      content: '';
    }

    &::after {
      bottom: 6px;
    }

    &.initiative-first::before {
      opacity: 1;
    }

    &.initiative-second::after {
      opacity: 1;
    }
  }

  &__stats {
    overflow: hidden;
    display: flex;
  }

  &__show-more {
    z-index: 2;
    flex-shrink: 0;
    position: relative;
    padding-right: 5px;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  &__col {
    grid-row: 1;
    margin-left: var(--score-col-gap, 8px);
    color: var(--score-color, var(--neutral-light-3));
    font-weight: 400;

    &:first-child {
      margin-left: 0;
    }

    &.is-primary {
      color: var(--score-color-main, var(--basic-color));
      font-weight: 700;
    }
  }

  &__results {
    display: grid;
    align-content: center;
    grid-gap: var(--teams-gap, 2px);
    height: 100%;
  }

  &__indicator {
    display: none;
  }

  &__value {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
  }
}

.shadow {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -10px;
  display: block;
  width: 100%;
  height: 115px;
  background: linear-gradient(90deg, #1b1b1f 0%, rgba(27, 27, 31, 0) 100%);
  pointer-events: none;
}

.single-game-bar {
  display: flex;
  margin-bottom: var(--space-3);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  &__col {
    display: flex;
    align-items: center;

    &--right {
      margin-left: auto;
      color: #595961;
      font-size: 11px;
      font-weight: 700;
    }
  }

  .icon-cp {
    display: flex;
    height: 14px;
    max-width: 18px;
    margin-left: var(--space-3);
  }

  .icon-vizu {
    width: 18px;
  }

  .icon-arrow-right {
    margin: 0 0 0 var(--space-1);
    fill: #595961;
    display: none;
  }

  .game-info-data {
    margin-right: var(--space-2);

    &:last-child {
      margin-right: 0;
    }
  }
}

.game-time {
  overflow: hidden;
  color: var(--game-time-color, #79798b);
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.025em;
  text-transform: initial;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__separator {
    color: #4a4a55;
    margin: 0 var(--space-1);
  }
}

.sb-esport .single-game-bar {
  display: flex;
  justify-content: space-between;
}

.odds-table.has-prematches-selected {
  padding-top: 10px;
}

.headline-sport {
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &__col {
    overflow: hidden;
    display: flex;
    align-items: center;

    &--action {
      flex-shrink: 0;
    }
  }

  &__name {
    overflow: hidden;
    display: flex;
    flex-flow: column;
  }

  &__sport {
    margin-bottom: -2px;
    color: var(--sport-color, var(--basic-color));
    font-size: 10px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    text-transform: uppercase;
  }

  &__league {
    overflow: hidden;
    padding-right: 10px;
    color: var(--league-color, #fff);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__region {
    z-index: 1;
    position: relative;
    height: 17px;
    width: 17px;
    margin-right: var(--space-2);
    background-size: cover;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 27px;
    background-color: var(--league-toggle-bg, var(--bg-1));
    border-radius: var(--radius-1);

    &.is-active .icon-cp {
      transform: rotateX(180deg);
    }
  }

  &__chevron {
    width: 11px;
    transform: initial;
    transition: transform 0.33s linear;

    path {
      fill: var(--white);
    }
  }

  &__fav {
    display: flex;
    width: 18px;
    margin-right: var(--space-4);
  }
}

.section-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-5);

  &__name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: var(--ls-2);
    line-height: 1;
  }

  &__counter {
    margin-left: 5px;
    color: var(--counter-color, var(--color-2));
  }

  &__filters {
    display: flex;
  }

  &__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 35px;
    margin-left: 10px;
    background-color: var(--filter-bg, transparent);
    border: var(--filter-border, var(--border-1));
    border-radius: var(--filter-radius, var(--radius-1));

    &.is-active {
      border-color: var(--filter-border-color-active, var(--basic-color));
    }
  }

  .icon-cp {
    display: flex;
    height: 15px;
  }

  .icon-tv {
    width: 15px;
  }

  .icon-twitch {
    width: 16px;
  }

  .icon-vizu {
    width: 18px;
  }
}

.subpage-games--live .section-headline__name {
  &::before {
    display: block;
    width: 9px;
    height: 9px;
    margin-right: var(--space-3);
    background-color: #d70000;
    border-radius: 50%;
    content: '';
  }
}

.group-headline {
  position: relative;
  padding-left: calc(18px + var(--space-2));
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.05em;

  &::first-letter {
    text-transform: uppercase;
  }

  &--promo {
    margin-bottom: var(--space-5);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 18px;
    margin-right: var(--space-2);
  }
}

.pb-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-3);
  background-color: var(--pb-trigger-bg, #1b1b1f);
  border-radius: var(--radius-1);
  border: 1px solid var(--grey-primary-dark-1);

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--space-2);
  }

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: var(--space-3);
  }

  &__btn {
    flex-shrink: 0;
    height: 39px;
    padding: 0 var(--space-4);
    background-color: var(--pb-trigger-btn-bg, var(--bg-1));
    color: var(--pb-trigger-btn-color, #fff);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.025em;
  }

  &__details {
    letter-spacing: -0.025em;
  }

  &__title {
    font-size: 13px;
    font-weight: 700;
  }

  &__subtitle {
    color: #747483;
    font-size: 11px;
    font-weight: 600;
  }
}

.markets-select {
  overflow: auto;
  display: flex;
  width: 100%;

  &__entry {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: var(--space-3);
    height: 30px;
    padding: 0 var(--space-3);
    background-color: var(--select-entry-bg, var(--neutral-dark-1));
    color: var(--neutral-light-3);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    white-space: nowrap;
    border: 1px solid var(--select-entry-border, var(--grey-primary-dark-1));
    border-radius: var(--select-entry-radius, var(--radius-2));

    &.is-active {
      color: var(--select-entry-color-active, var(--basic-color));
      background-color: var(--select-entry-bg-active, var(--basic-color-dark));
      border-color: var(--select-entry-bg-active, var(--basic-color-dark));
    }
  }
}
