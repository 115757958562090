@import 'sportsbook/mobile-web/shared/components/open-game';

//STORYBOOK OVERWRITES
bcfds-match-details-wrapper {
  bcfds-tabs-panel-item.horizontal.horizontal-m {
    background-color: transparent;
    border: var(--border-1);

    &.is-active {
      background-color: var(--bg-3);
      border-color: var(--bg-3);

      .ds-text {
        color: var(--white);
      }

      .ds-icon {
        --fill-1: var(--white);
      }
    }
  }

  .ds-market-entry .ds-icon-box .ds-icon-bookmark-filled path {
    --ds-brand-50: #fff;
  }

  bcfds-button-odds.horizontal {
    &.is-active {
      color: var(--ds-neutral-100);
      background-color: var(--basic-color);
    }
  }

  .ds-scorebox .ds-score__col .brand.c-50 {
    color: var(--basic-color);
  }
}
