.toggle-bonuses {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
  padding: 5px;
  background-color: var(--profile-toggle-bg, var(--bg-2));
  border-radius: var(--profile-toggle-radius, var(--radius));

  &__item {
    display: block;
    padding: var(--profile-toggle-item-padding, 6px 12px);
    background-color: transparent;
    border-radius: var(--profile-toggle-item-radius, var(--radius-2));
    color: var(--profile-toggle-item-color, var(--white));
    font-size: 14px;
    line-height: 17px;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:first-letter {
      text-transform: uppercase;
    }

    &.is-active {
      background-color: var(--profile-toggle-item-active-bg, var(--white));
      color: var(--profile-toggle-item-active-color, var(--bg-2));
    }
  }
}

.bonuses {
  p {
    margin: 0;
  }

  &__group {
    display: grid;
    grid-gap: var(--my-2);
    margin-bottom: var(--my-4);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__name {
      color: var(--headline-color, var(--white));
      letter-spacing: var(--ls-1);
    }

    .profile-headline {
      margin-bottom: 0;
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      min-height: 35px;
      padding: 0 var(--px-2);
      background-color: var(--action-bg, var(--bg-5));
      color: var(--action-color, var(--white));
      font-size: 13px;
      font-weight: 700;
      letter-spacing: var(--ls-1);
      text-align: center;
      border-radius: var(--edit-radius, var(--radius-2));
    }
  }

  &-entry {
    padding: var(--py-2);
    background-color: var(--entry-bg, var(--bg-2));
    border-radius: var(--entry-radius, var(--radius-2));

    &--active {
      background-color: var(--entry-bg-active, var(--bg-1));
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__details {
      display: flex;
    }

    &__img {
      width: 60px;
      height: 60px;
      background-size: cover;
      background-position: right;
      background-repeat: no-repeat;
      background-image: var(--default-bonus-path);
      border-radius: var(--radius-2);
    }

    &__data {
      margin-left: var(--mx-2);
    }

    &__title {
      margin-bottom: 5px;
      color: var(--title-color, var(--white));
      font-size: 14px;
      font-weight: 700;
      letter-spacing: var(--ls-2);
    }

    &__info {
      margin-bottom: 2px;
      color: var(--info-color, var(--color-4));
      font-size: 12px;
      font-weight: 600;
      letter-spacing: var(--ls-1);

      &:last-child {
        margin: 0;
      }
    }

    &__wrapp-action {
      overflow: hidden;
      display: flex;
      margin-left: 10px;
      border-radius: var(--arrows-radius, var(--radius-2));
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 35px;
      background-color: var(--entry-icon-bg, var(--bg-5));
      border-radius: var(--action-radius, var(--radius-2));

      &--move {
        width: 38px;
        height: 35px;
        border: initial;
        border-radius: initial;

        &:nth-child(2) {
          margin-left: 2px;
        }
      }

      &--up {
        transform: rotate(180deg);
      }
    }

    &__action .icon-cp {
      width: 15px;
      fill: var(--bin-fill, #fff);
    }

    &__action .icon-dropdown-arrow {
      width: 12px;
      path {
        fill: var(--chevron-fill, #fff);
      }
    }

    &__btn {
      height: 50px;
      margin-top: var(--my-3);
    }

    .btn {
      height: 50px;
    }
  }
}
