@import 'shared/common/lvbet/datepicker/datepicker';

bcf-datepicker {
  display: none;
  z-index: 14;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;

  &.is-active {
    display: block;
  }
}

.datepicker {
  --picker-body: var(--body);
  --title-color: var(--basic-color);
  --btn-choose-bg: var(--primary-color);
  --entry-border: 2px solid var(--primary-color);
  --entry-border-active: var(--basic-color);
  --entry-bg: var(--picker-body);
  --entry-active-bg: var(--basic-color);
  --entry-color: var(--white);
  --entry-color-active: var(--black);
  --icon-switch-fill: var(--basic-color);

  max-height: calc(100% - var(--open-game-gap, 270px));
  z-index: 2;
  position: absolute;
  transform: translateY(100%);
  display: flex;
  flex-flow: column;
  width: 100%;
  bottom: 0;
  padding: var(--space-4) var(--space-3);
  background-color: var(--bottom-sheet-bg, var(--body-bg));
  border-radius: var(--radius-m) var(--radius-m) 0 0;
  animation: bottom-sheet-show cubic-bezier(0, 0, 0.2, 1) 0.2s forwards;
  animation-delay: 0.15s;
  box-shadow: none;

  &-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(39, 39, 45, 0.9);
  }
}

.datepicker-grid-row {
  &:last-of-type {
    margin-bottom: 0px;
  }
}
