div.bottom-sheet bcf-statscore-widget .STATSCOREWidgetContainer .STATSCOREWidget--tabs__container {
  .STATSCOREWidget--statsH2H {
    .STATSCOREWidget--headerH2H {
      margin-bottom: 10px;
      border-radius: 0 0 var(--statscore-radius) var(--statscore-radius);
    }
  }

  .STATSCOREWidget--statsH2H__statistics {
    margin-bottom: 10px;
    background-color: var(--statscore-bg);
    border-radius: var(--statscore-radius);

    .STATSCOREWidget--partialHeader {
      @include partial-header;
      border-radius: var(--statscore-radius) var(--statscore-radius) 0 0;
    }

    .STATSCOREWidget--statsH2H__averages {
      padding: 0 15px 15px;
    }

    .STATSCOREWidget--statsH2H__statistics--bar {
      margin-top: 25px;

      &:first-child {
        margin-top: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .STATSCOREWidget--statsH2H__topScorers {
      .STATSCOREWidget--statsH2H__topScorersTeamName {
        padding: 10px 15px;
        line-height: initial;
      }

      .STATSCOREWidget--statsH2H__topScorersTableCell {
        padding: 10px 4px;
        border-bottom: 1px solid var(--statscore-outside-app-bg);
      }

      table tr {
        font-size: 11px;
      }

      table thead th {
        background-color: transparent;

        &.STATSCOREWidget--statsH2H__topScorersTableHeaderCell--playerName {
          text-align: left;
        }

        &.STATSCOREWidget--statsH2H__topScorersTableHeaderCell--no {
          padding-left: 15px;
        }

        &.STATSCOREWidget--statsH2H__topScorersTableHeaderCell--goalsScored {
          padding-right: 15px;
        }
      }

      table tbody td {
        &.STATSCOREWidget--statsH2H__topScorersTableCell--no {
          padding-left: 15px;
        }

        &.STATSCOREWidget--statsH2H__topScorersTableCell--goalsScored {
          padding-right: 15px;
        }
      }
    }

    .STATSCOREWidget--partialHorizontalBar__headerBox {
      font-size: 11px;
      line-height: initial;

      &:nth-child(2) {
        padding-bottom: 5px;
      }

      &:nth-child(1),
      &:nth-child(3) {
        font-weight: 700;
      }
    }
  }
}
